import React, { useState, useEffect, useContext } from "react";
import SprintOverview from "./SprintOverview";
import ActiveSprints from "../Pages/Overview/ActiveSprints";
import CompletedSprint from "./CompletedSprint";
import BreadcrumbComponent from "../Components/BreadcrumbComponent";
import { AddLocalStorageData, GetLocalStorageData, RemoveLocalStorageData, changePageTitle } from "../Helpers/Utilities";
import { useParams, useLocation, useNavigate } from "react-router-dom";


const Overview = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { sprintNames, sprint_Ids } = location.state || {};
  const [activeTab, setActiveTab] = useState(() => {
    // Get the active tab from sessionStorage if available, else default to "Overview"
    return GetLocalStorageData("activeTab") || "Overview";
  });
  // function useLocalStorage(key) {
  //   const [value, setValue] = useState(() => {
  //     return GetLocalStorageData("projectName") || '';
  //   });
  
  //   useEffect(() => {
  //     const handleStorageChange = () => {
  //       setValue(GetLocalStorageData("projectName") || '');
  //     };
  
  //     window.addEventListener('storage', handleStorageChange);
  
  //     // Cleanup the event listener on component unmount
  //     return () => {
  //       window.removeEventListener('storage', handleStorageChange);
  //     };
  //   }, [key]);
  
  //   return value;
  // }
  const pageParams = useParams();
  const projectId = pageParams.projectId;

  const NavigateToCreateActionItem = () => {
    //navigate(`/CreateActionItem/${projectId}`);
    let storedSprint = GetLocalStorageData('selectedSprint');
    if (storedSprint !== undefined && storedSprint !== null && storedSprint !== "Uwxt7s/r5zp6v/kfF4wOgw==" && storedSprint && activeTab === "Active Sprints") {
      storedSprint = JSON.parse(storedSprint);
      //console.log("sprintNames: storedSprint.label, sprint_Ids: storedSprint.value", storedSprint.label, storedSprint.value)
      navigate(`/AddActionItem/${projectId}`, {
        state: { sprintNames: storedSprint.label, sprint_Ids: storedSprint.value },
      });
    }
    else {
      RemoveLocalStorageData("selectedSprint");
      navigate(`/AddActionItem/${projectId}`);
     }
  };

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    changePageTitle(tabName);
    // Store the active tab in sessionStorage
    AddLocalStorageData("activeTab", tabName);
  };

  useEffect(() => {
    changePageTitle(activeTab);
  }, [activeTab]);

  //const projectName = useLocalStorage("projectName");
  const projectName = GetLocalStorageData("projectName");
  const localStorageId = GetLocalStorageData("key");
const [uniqueProjectName,setUniqueProjectName]=useState("");
const handleNameUpdate = (name) => {
  setUniqueProjectName(name);
};
  return (
    <>
      <div className="main-body-content overview">
        <title>Overview</title>
        
        <section className="breadcrumb-block">
        <BreadcrumbComponent projectName={uniqueProjectName?uniqueProjectName:projectName}  /></section>

        <div className="overview-main-block" style={{ position: 'sticky', top: '90px', zIndex: '99', paddingTop: '11px', backgroundColor: "#f1f4f6" }}>
          <div>
            <div className="overview-tabs">
              <div>
                <ul
                  className="nav nav-tabs justify-content-center"
                  id="myTab"
                  role="tablist"
                >
                  <TabButton
                    tabId="Overview"
                    activeTab={activeTab}
                    title="Overview"
                    onClick={handleTabClick}
                  />
                  <TabButton
                    tabId="Active Sprints"
                    activeTab={activeTab}
                    title="Active Sprints"
                    onClick={handleTabClick}
                  />
                  <TabButton
                    tabId="Completed Sprints"
                    activeTab={activeTab}
                    title="Completed Sprints"
                    onClick={handleTabClick}
                  />
                </ul>
              </div>
              <div>
                {localStorageId && (<button
                  className="create-btn"
                  style={{ marginRight: "15px" }}
                  onClick={NavigateToCreateActionItem}
                  title="Add Work Item"
                >
                  <span style={{ color: "white", fontWeight: '500' }}>Add Work Item</span>
                </button>
                )}

              </div>
            </div>
          </div>
        </div>

        <div className="tab-content" id="myTabContent">
          <TabContent className="custom-overview-height"  tabId="Overview" activeTab={activeTab}>
            {activeTab === "Overview" && <SprintOverview projectId={projectId} onNameChange={handleNameUpdate} />}
          </TabContent>
          <TabContent tabId="Active Sprints" activeTab={activeTab}>
            {activeTab === "Active Sprints" && <ActiveSprints projectId={projectId} />}
          </TabContent>
          <TabContent tabId="Completed Sprints" activeTab={activeTab}>
            {activeTab === "Completed Sprints" && <CompletedSprint projectId={projectId} />}
          </TabContent>
        </div>
      </div>
    </>
  );
};

const TabButton = ({ tabId, activeTab, title, onClick }) => (
  <li className="nav-item" role="presentation">
    <button
      className={`nav-link tab-title ${tabId === activeTab ? "active" : ""}`}
      id={`${tabId}-tab`}
      data-bs-toggle="tab"
      data-bs-target={`#${tabId}`}
      type="button"
      role="tab"
      aria-controls={tabId}
      aria-selected={tabId === activeTab}
      onClick={() => onClick(tabId)}
    >
      {title}
    </button>
  </li>
);

const TabContent = ({ tabId, activeTab, children }) => (
  <div
    className={`tab-pane fade ${tabId === activeTab ? "active show" : ""}`}
    id={tabId}
    role="tabpanel"
    aria-labelledby={`${tabId}-tab`}
  >
    {children}
  </div>
);

export default Overview;
