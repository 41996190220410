import React, { useContext, useEffect, useState } from "react";
import { SprintOverviewService } from "../WebApiServices/SprintOverview.service";
import { useLocation, useParams } from "react-router-dom";
import { AppContext, useUIContext } from "../Contexts/AppContextProvider";
import Card from "./Overview/Card";
import CreateSprint from "./Overview/CreateSprint";
import Select,{components} from "react-select";
import {
  AddLocalStorageData,
  GetLocalStorageData,
  GetLoggedInUserID,
  RemoveLocalStorageData,
} from "../Helpers/Utilities";
import { useNavigate } from "react-router-dom";

import {
  HTTPResponse,
  TASK_PRIO,
  ACTION_ITEM_TYPE,
  MonthNames,
  ACTION_ITEM_TYPE_TITLE,
  PriorityListTitle,
  ToastMessageType,
  ROLE_TYPE,
} from "../Helpers/Enums";
import { ToastMessages } from "../Helpers/HelperText";
import { SprintService } from "../WebApiServices/Sprint.service";
import { BacklogService } from "../WebApiServices/Backlog.service";
import { ProjectService } from "../WebApiServices/Project.service";
import DeleteModal from "../Components/DeleteModal";

const SprintOverview = ({ onNameChange }) => {
  const [projectName, setProjectName] = useState("")
  const { LogOut } = useUIContext();
  const navigate = useNavigate();
  const { ToggleLoader, ShowToastMessage } = useContext(AppContext);
  const pageParams = useParams();
  const projectId = pageParams.projectId;
  const [sprintsnotstarted, setSprintsnotstarted] = useState([]);
  const [backlogIssueCount, setBacklogIssueCount] = useState(0);
  const [sprintIssueCount, setSprintIssueCount] = useState(0);
  const [sprintActionItemCount, setSprintActionItemCount] = useState(0);
  const [completedSprintId, setCompletedSprintId] = useState(0);
  const [backlogActionItems, setBacklogActionItems] = useState([]);
  const [activeFilter, setActiveFilter] = useState("");
  const [backlogActionItemsPriorityId, setBacklogActionItemsPriorityId] =
    useState([]);
  const [notstartedsprint, setNotstartedsprint] = useState([]);
  const [
    backlogActionItemsActionItemType,

    setBacklogActionItemsActionItemType,
  ] = useState([]);
  const [sprintitemid, setsprintitemid] = useState(0);
  const [sprintData, setSprintData] = useState([]);
  const [list1, setList1] = useState([]);
  let totalmappedData = [];
  const [category, setcategory] = useState(null)
  const [loadData, setLoadData] = useState(false);//MS-to load data for pre select,as null occured
  const [showCreateSprintModal, setShowCreateSprintModal] = useState(false);
  const [showStartStartSprintModal, setShowStartSprintModal] = useState(false);
  const [showAddSprintModal, setAddSprintModal] = useState(false);
  const [sprintDragged,setSprintDragged]=useState({
    previousSprint:"Backlog",
    newSprint:"",
    key:""
  })
  const [createdByDrp,setCreatedByDrp]=useState(null)
  const [selectedEmployees,setSelectedEmployees]=useState([]);
  const [team, setTeam] = useState([])
  const [sprintName, setSprintName] = useState("");
  const [sprintInputName, SetSprintInputName] = useState("");
  const [loggedInUserID, SetLoggedUserId] = useState("");
  const [sprintStartDate, setSprintStartDate] = useState("");
  const [sprintEndDate, setSprintEndDate] = useState("");
  const [isSprintEditOrStart, setIsSprintEditOrStart] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [categoryLocation, setCategoryLocation] = useState(null)
  const location = useLocation();
  const { categoryValue } = location.state || {}; //MS-Preselecting value from Edit Project Page
  const [allIssues,setAllIssues]=useState(false);
  const [myIssues,setMyIssues]=useState(false);
  const [recentlyUpdated,setRecentlyUpdated]=useState(false)
  //// location.state && location.state.projectDetails
  //? location.state.projectDetails
  // : null;
  useEffect(() => {
    setCategoryLocation(categoryValue)
  }, [])

  useEffect(() => {
    if (projectName) {
      onNameChange(projectName);
    }
  }, [projectName]);
  /* <summary>
  date: 06-12-2023
  Name: MS
  description: Search bar to search by description and issue number.
  <summary>*/
  const [searcheExist, setSearchExists] = useState(true);
  const [filteredItems, setFilteredItems] = useState([]);
  const [searchedValue, setSearchedValue] = useState("")
  const [sprintItems, setSprintItems] = useState([]);
  const [listItems, setListItems] = useState([]);
  const [completedSprint, setCompletedSprint] = useState([])
  const [filterClicked, setFilterClicked] = useState(false);
  const [filterRecentEmpty, setfilterRecentEmpty] = useState(false);
  const [lastColumn, setLastColumn] = useState(0);
  /* <summary>
  date: 10-06-2024
  Name: Mustakim
  description: To remove retained data when clicked on Add another item in EditActionItem page.
  <summary>*/
  useEffect(() => {
    RemoveLocalStorageData("assigneeDrpDwn");
    RemoveLocalStorageData("typeDrpDwn");
    RemoveLocalStorageData("priorityDrpDwn");
    RemoveLocalStorageData("statusDrpDwn");
    RemoveLocalStorageData("selectedLinkedAI");
    RemoveLocalStorageData("sprintId");
  }, [])

  useEffect(() => {
    GetCategoriesList();
   
    //getLastColumnId();
  }, [projectId])


  // const handleSearchInputChange = (e) => {

  //   const searchedValue = e.target.value.toLowerCase();
  //   setSearchedValue(e.target.value);
  //   const sprintItems = totalmappedData
  //     .map((sprint) => {
  //       const filteredItems = sprint.mappedItems.filter((item) => {
  //         return (            
  //           item.key.toLowerCase().includes(searchedValue) ||
  //           item.title.toLowerCase().includes(searchedValue)
  //         );
  //       });

  //       return filteredItems.map((filteredItem) => ({
  //         ...filteredItem,
  //         sprintId: sprint.sprint_Id,
  //       }));
  //     })
  //     .flat();


  //     console.log("totalmappedData", totalmappedData);
  //     console.log("filteredItems", sprintItems);


  //   const completedSprint = completedSprintsSprints
  //     .filter((sprint) => sprint.actionItems.some((item) => item.title.toLowerCase().includes(searchedValue) ||
  //       item.key.toLowerCase().includes(searchedValue)))
  //     .map((sprint) => ({
  //       ...sprint,
  //       actionItems: sprint.actionItems.filter((item) => item.title.toLowerCase().includes(searchedValue) ||
  //         item.key.toLowerCase().includes(searchedValue))
  //     }));

  //   // setCompletedSprint(completedSprint);

  //   console.log("completedSprint", sprintItems);


  //   const backlogItems = list1.filter((item) => {
  //     return (
  //       item.key.toLowerCase().includes(searchedValue) ||
  //       item.title.toLowerCase().includes(searchedValue)
  //     );
  //   });




  //   setSprintItems(sprintItems);
  //   setListItems(backlogItems);
  //   if (searchedValue === "" && category != null) {
  //     HandleFilterClick("category")
  //     setCompletedSprint([]);

  //   }
  //   else if (searchedValue === "") {

  //     setCompletedSprint([]);

  //   }
  //   else {
  //     setCompletedSprint(completedSprint);

  //   }
  //   // Combine sprint and backlog items into a single filtered array
  //   const combinedFilteredItems = [...sprintItems, ...backlogItems, ...completedSprint.flatMap(sprint => sprint.actionItems)];

  //   console.log("combinedFilteredItems", combinedFilteredItems);

  //   setFilteredItems(combinedFilteredItems);

  //   if (combinedFilteredItems.length === 0) {
  //     setSearchExists(false);
  //   } else {
  //     setSearchExists(true);
  //   }
  // };

  const handleSearchInputChange = (e) => {
    const searchedValue = e.target.value.toLowerCase();
    setSearchedValue(searchedValue);

    const uniqueSprintIds = new Set();

    // Filter sprint items based on search value
    const sprintItems = totalmappedData
      .map((sprint) => {
        const filteredItems = sprint.mappedItems.filter((item) => {
          return (
            item.key.toLowerCase().includes(searchedValue) ||
            item.title.toLowerCase().includes(searchedValue)
          );
        });

        return filteredItems.map((filteredItem) => ({
          ...filteredItem,
          sprintId: sprint.sprint_Id,
        }));
      })
      .flat();

    // const completedSprint = completedSprintsSprints
    //   .map((sprint) => {
    //     // Set to track unique action item IDs within the same sprint
    //     const uniqueActionItemIds = new Set();

    //     // Filter actionItems based on search value and uniqueness
    //     const filteredActionItems = sprint.actionItems.filter((item) => {
    //       const matchesSearch =
    //         item.key !== null &&
    //         item.key !== undefined &&
    //         (item.title.toLowerCase().includes(searchedValue) ||
    //           item.key.toLowerCase().includes(searchedValue));

    //       // Check if the actionItemId is unique within this sprint
    //       if (matchesSearch && !uniqueActionItemIds.has(item.actionItemId)) {
    //         uniqueActionItemIds.add(item.actionItemId);
    //         return true;
    //       }
    //       return false;
    //     });

    //     // Only include the sprint if there are any filtered actionItems
    //     if (filteredActionItems.length > 0) {
    //       return {
    //         ...sprint,
    //         actionItems: filteredActionItems,
    //       };
    //     }

    //     return null;
    //   })
    //   .filter((sprint) => sprint !== null)
    //   .filter((sprint) => {
    //     if (!uniqueSprintIds.has(sprint.sprintId)) {
    //       uniqueSprintIds.add(sprint.sprintId);
    //       return true;
    //     }
    //     return false;
    //   });

    const completedSprint = completedSprintsSprints
  .map((sprint) => {
    // Set to track unique keys within the same sprint
    const uniqueKeys = new Set();

    // Filter actionItems based on search value and uniqueness of the key
    const filteredActionItems = sprint.actionItems.filter((item) => {
      const matchesSearch =
        item.key !== null &&
        item.key !== undefined &&
        (item.title.toLowerCase().includes(searchedValue) ||
          item.key.toLowerCase().includes(searchedValue));

      // Check if the key is unique within this sprint
      if (matchesSearch && !uniqueKeys.has(item.key)) {
        uniqueKeys.add(item.key);
        return true;
      }
      return false;
    });

    // Only include the sprint if there are any filtered actionItems
    if (filteredActionItems.length > 0) {
      return {
        ...sprint,
        actionItems: filteredActionItems,
      };
    }

    return null;
  })
  .filter((sprint) => sprint !== null)
  .filter((sprint) => {
    if (!uniqueSprintIds.has(sprint.sprintId)) {
      uniqueSprintIds.add(sprint.sprintId);
      return true;
    }
    return false;
  });

    // Filter backlog items based on search value
    const backlogItems = list1.filter((item) => {
      return (
        item.key !== null && item.key !== undefined && item.key.toLowerCase().includes(searchedValue) ||
        item.title !== null && item.title !== undefined && item.title.toLowerCase().includes(searchedValue)
      );
    });

    setSprintItems(sprintItems);
    setListItems(backlogItems);


    if (searchedValue === "" && category != null) {
      setSearchedValue("");
      HandleFilterClick("category");
      setCompletedSprint([]);
    } else if (searchedValue === "") {
      setSearchedValue("");
      setCompletedSprint([]);
    } else {
      // Ensure no duplicate actionItemIds within each sprint
      // const uniqueCompletedSprints = completedSprint.map((sprint) => {
      //   const uniqueActionItemIds = new Set();
      //   const uniqueActionItems = sprint.actionItems.filter((item) => {
      //     if (!uniqueActionItemIds.has(item.actionItemId)) {
      //       uniqueActionItemIds.add(item.actionItemId);
      //       return true;
      //     }
      //     return false;
      //   });

      //   return {
      //     ...sprint,
      //     actionItems: uniqueActionItems,
      //   };
      // });
      setCompletedSprint(completedSprint);
    }
    // Combine sprint, backlog, and completed items into a single filtered array
    const combinedFilteredItems = [
      ...sprintItems,
      ...backlogItems,
      ...completedSprint.flatMap(sprint => sprint.actionItems),
    ];

    setFilteredItems(combinedFilteredItems);

    if (combinedFilteredItems.length === 0) {
      setSearchExists(false);
    } else {
      setSearchExists(true);
    }
  };




  useEffect(() => {
    GetActionItems();
    const userId = GetLoggedInUserID();
    SetLoggedUserId(userId);
    getProjectAssignedEmployee();
  }, []);

  useEffect(() => {
    setList1(backlogActionItems);


  }, [backlogActionItems, sprintData]);

  // const handleDragStart = (e, item) => {

  //   if (!item.isDefault) {
  //     e.dataTransfer.setData("item", JSON.stringify(item));
  //     //e.dataTransfer.setData("originalIndex", totalmappedData.find(sprint => sprint.sprint_Id === sprintId).mappedItems.findIndex(mappedItem => mappedItem.actionItemId === item.actionItemId).toString());

  //   }

  // };

  const handleDragStart = (e, item) => {
    
    setSprintDragged((prev)=>({
      ...prev,
      previousSprint:"Backlog",
      key:item.key
    }))
    if (!item.isDefault) {
      e.dataTransfer.setData("item", JSON.stringify(item));
      //e.dataTransfer.setData("originalIndex", totalmappedData.find(sprint => sprint.sprint_Id === sprintId).mappedItems.findIndex(mappedItem => mappedItem.actionItemId === item.actionItemId).toString());
    } 
  };

  const handleDragOver = (e) => {

    e.preventDefault();
  };

  //method to activate the Create sprint modal

  const openCreateSprintModal = () => {
    setAddSprintModal(true)
    let newSprintName = "";
    // Get the last item from the array
    if (totalmappedData.length > 0) {
      const lastItem = totalmappedData[totalmappedData.length - 1];
      newSprintName = lastItem.sprintName;

      // Check if the last character of newSprintName is a number
      const lastCharacterIsNumber = !isNaN(parseInt(newSprintName.slice(-1), 10));

      if (lastCharacterIsNumber) {
        //increment by 1, and append it back to the newSprintName
        const lastNumber = parseInt(newSprintName.match(/\d+$/)[0], 10);
        newSprintName = newSprintName.replace(/\d+$/, lastNumber + 1);
      } else {
        // If the last character is not a number, append '1' to the newSprintName
        newSprintName = newSprintName + ' 1';
      }
      SetSprintInputName(newSprintName);
    }

    setIsSprintEditOrStart(false);
    setsprintitemid(0);
    setSprintStartDate(null);
    setSprintEndDate(null);
    setSprintName("Create Sprint");
    setShowCreateSprintModal(true);
  };


  const openEditSprintModal = (
    value,
    length,
    sprintInputname,
    startDate,
    endDate
  ) => {
    setSprintActionItemCount(length);
    setIsSprintEditOrStart(true);
    SetSprintInputName(sprintInputname);

    setsprintitemid(value);

    setSprintStartDate(startDate);
    setSprintEndDate(endDate);
    setShowStartSprintModal(true);
  };


  //SYJ- 28/02/2024 - On Quick Filter change
  const HandleFilterClick = (name) => {
    
    // if (name !== "category") {
    //   setcategory(null);
    // }
    setActiveFilter(name);

    const currentDate = new Date().toLocaleDateString();
    const loggedInUserID = GetLoggedInUserID();
    
    const sprintItems = totalmappedData
      .map((sprint) => {
        const filteredItems = sprint.mappedItems.filter((item) => {
          switch (name) {
            case "Admin":
              setFilterClicked(true);
              totalmappedData = [];
              return totalmappedData;
            case "myIssues":
              setFilterClicked(false);
              return item.assigneeId !== null && item.assigneeId !== undefined && item.assigneeId !== "" && item.assigneeId === loggedInUserID;
            case "recentlyUpdated":
              setFilterClicked(false);
              const editedOnDate = new Date(item.editedOn);
              // Compare the editedOnDate with the current date
              return (
                editedOnDate.toLocaleDateString() === currentDate
              );

            case "category":
              setFilterClicked(false);
             return item.projectCategoryId !== null && item.projectCategoryId === category;

            case "employees": 
            setFilterClicked(false);
            return item.assigneeId !== null &&  item.assigneeId !== undefined && item.assigneeId !== ""
            // && selectedEmployees.includes(item.assigneeId) ;
             && selectedEmployees.some(employee => employee.value === item.assigneeId);
        

             case "AssigneeAndCategory":
              setFilterClicked(false);
             return item.projectCategoryId !== null && item.projectCategoryId === category && item.assigneeId !== null &&  item.assigneeId !== undefined && item.assigneeId !== ""
             // && selectedEmployees.includes(item.assigneeId) ;
              && selectedEmployees.some(employee => employee.value === item.assigneeId);;
             // CreatedByAndAssigneeAndCategory          
             case "CreatedByAndAssigneeAndCategory":
              setFilterClicked(false);
             return item.projectCategoryId !== null && item.projectCategoryId === category && item.assigneeId !== null &&  item.assigneeId !== undefined && item.assigneeId !== ""
             // && selectedEmployees.includes(item.assigneeId) ;
              && selectedEmployees.some(employee => employee.value === item.assigneeId) && item.createdBy !=null && item.createdBy !=undefined && item.createdBy==createdByDrp ;
              
              case "CreatedByAndCategory":
                setFilterClicked(false);
               return item.projectCategoryId !== null && item.projectCategoryId === category && item.createdBy !== null &&  item.createdBy !== undefined && item.createdBy==createdByDrp
               // && selectedEmployees.includes(item.assigneeId) ;
                //&& selectedEmployees.some(employee => employee.value === item.assigneeId);;
             
                
                case "CreatedByAndAssignee":
                  setFilterClicked(false);
                 return item.createdBy !== null && item.createdBy === createdByDrp && item.assigneeId !== null &&  item.assigneeId !== undefined && item.assigneeId !== "" && selectedEmployees.some(employee => employee.value === item.assigneeId);
                 
                 case "CreatedBy":
                  setFilterClicked(false);
                 return item.createdBy !== null && item.createdBy === createdByDrp ;
            
                default:
              setFilterClicked(false);
              return true; // No filter or unknown filter, include all items
          }
        });

        return filteredItems.map((filteredItem) => ({
          ...filteredItem,
          sprintId: sprint.sprint_Id,
        }));
      })
      .flat();

    if (name === "recentlyUpdated" && filteredItems.length === 0) {
      setfilterRecentEmpty(true);
    }
    // else if(name="category"){

    // }
    else {
      setfilterRecentEmpty(true);
    }

    const backlogItems = list1.filter((item) => {
      switch (name) {
        case "Admin":
          setFilterClicked(true);
          totalmappedData = [];
          return totalmappedData;
        case "myIssues":
          setFilterClicked(false);
          return item.assigneeId !== null && item.assigneeId !== undefined && item.assigneeId !== "" && item.assigneeId === loggedInUserID;
        case "recentlyUpdated":
          setFilterClicked(false);
          const editedOnDate = new Date(item.editedOn);
          // Compare the editedOnDate with the current date
          return (
            editedOnDate.toLocaleDateString() === currentDate
          );
        case "category":
          setFilterClicked(false);
          return item.projectCategoryId !== null && item.projectCategoryId === category;
          case "employees": 
          setFilterClicked(false);
          return item.assigneeId !== null &&  item.assigneeId !== undefined && item.assigneeId !== "" 
          //&& selectedEmployees.includes(item.assigneeId) ;
          && selectedEmployees.some(employee => employee.value === item.assigneeId);
      

          case "AssigneeAndCategory":
            setFilterClicked(false);
           return item.projectCategoryId !== null && item.projectCategoryId === category && item.assigneeId !== null &&  item.assigneeId !== undefined && item.assigneeId !== ""
           // && selectedEmployees.includes(item.assigneeId) ;
            && selectedEmployees.some(employee => employee.value === item.assigneeId);;
        
            case "CreatedByAndAssigneeAndCategory":
              setFilterClicked(false);
             return item.projectCategoryId !== null && item.projectCategoryId === category && item.assigneeId !== null &&  item.assigneeId !== undefined && item.assigneeId !== ""
             // && selectedEmployees.includes(item.assigneeId) ;
              && selectedEmployees.some(employee => employee.value === item.assigneeId) && item.createdBy !=null && item.createdBy !=undefined && item.createdBy==createdByDrp ;
           

              case "CreatedByAndCategory":
                setFilterClicked(false);
               return item.projectCategoryId !== null && item.projectCategoryId === category && item.createdBy !== null &&  item.createdBy !== undefined && item.createdBy==createdByDrp
           
               case "CreatedByAndAssignee":
                setFilterClicked(false);
               return item.createdBy !== null && item.createdBy === createdByDrp && item.assigneeId !== null &&  item.assigneeId !== undefined && item.assigneeId !== "" && selectedEmployees.some(employee => employee.value === item.assigneeId);
               case "CreatedBy":
                setFilterClicked(false);
               return item.createdBy !== null && item.createdBy === createdByDrp ;
            default:
          setFilterClicked(false);
          return true; // No filter or unknown filter, include all items
      }
    });
    setSprintItems(sprintItems);
    setListItems(backlogItems);

    // Combine sprint and backlog items into a single filtered array
    const combinedFilteredItems = [...sprintItems, ...backlogItems];

    setFilteredItems(combinedFilteredItems);
  };


  const closeCreateSprintModal = async () => {
    await GetActionItems();
    //setFilteredItems([]); // Clear filtered data
    setShowCreateSprintModal(false);
  };

  const CancelCreateSprintModal = async () => {
    //await GetActionItems();
   // setFilteredItems([]); // Clear filtered data
    setShowCreateSprintModal(false);
  };
  //method to open Start sprint modal

  const openStartSprintModal = (
    value,
    length,
    sprintInputname,
    startDate,
    endDate
  ) => {
    setSprintActionItemCount(length);

    setIsSprintEditOrStart(false);
    SetSprintInputName(sprintInputname);

    setsprintitemid(value);

    setSprintName("Start Sprint");

    setSprintStartDate(startDate);
    setSprintEndDate(endDate);
    setShowStartSprintModal(true);
  };

  //method to close Start Sprint modal

  const closeStartSprintModal = async () => {
    await GetActionItems();
    //setFilteredItems([]); // Clear filtered data
    setShowStartSprintModal(false);
  };

  const CancelStartSprintModal = async () => {
    // await GetActionItems();
    //setFilteredItems([]); // Clear filtered data
    setShowStartSprintModal(false);
  };
  //function to format the data coming fron data base to the desired DMY format

  // function formatDate(originalDateStr) {
  //   const originalDate = new Date(originalDateStr);

  //   const month = MonthNames[originalDate.getMonth()];

  //   let dateMDY = `${originalDate

  //     .getDate()

  //     .toString()

  //     .padStart(2, "0")} ${month} ${originalDate.getFullYear()}`;

  //   return dateMDY;
  // }

  //method to handle the drop function of drag and drop

  // function formatDate(originalDateStr) {
  //   const originalDate = new Date(originalDateStr);

  //   const month = MonthNames[originalDate.getMonth()];

  //   let dateMDY = `${originalDate

  //     .getDate()

  //     .toString()

  //     .padStart(2, "0")} ${month} ${originalDate.getFullYear()}`;

  //   return dateMDY;
  // }
  const formatDate = (dateString) => {


    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const monthName = MonthNames[date.getMonth()]; // Get month name from array
    const year = date.getFullYear();
    return `${day}/${monthName}/${year}`;
  };


  //method to handle the drop function of drag and drop
  const [showDeleteModal, setShowDeleteModal] = useState(false);//Delete component,used to show confirm modal
  const [dropEvent, setDropEvent] = useState(null);
  const [targetSprintId, setTargetSprintId] = useState(null);
  
  // const handleDrop = (e, sprintId) => {
  //   e.preventDefault();
  //   console.log('Raw dataTransfer item:', e.dataTransfer?.getData("item"));

  //   setDropEvent(e); // Store the event for later use
  //   setTargetSprintId(sprintId); // Store the target sprint ID
  //   setShowDeleteModal(true); // Show the confirmation modal
  // };
  const [parsedItem, setParsedItem] = useState(null); // State to store the parsed item

const handleDrop = (e, sprintId,sprintName) => {
  
  e.preventDefault();
  if(sprintId !=targetSprintId){

  
setSprintDragged((prev)=>({
  ...prev,newSprint:sprintName
}))
  const itemData = e.dataTransfer?.getData("item");

  const item = JSON.parse(itemData || '{}');
  // if(item){
  //   setSprintDragged((prev)=>({
  //     ...prev,newSprint:item.sprintName
  //   }))
  // }

  if (Object.keys(item).length === 0) {
    console.error('Failed to parse item or item is empty.');
    return; // Handle the error or return early
  }

  setParsedItem(item); // Store the parsed item in state
  setTargetSprintId(sprintId); // Store the target sprint ID
  setShowDeleteModal(true); // Show the confirmation modal
}};

  const confirmDrop = async () => {
  
    if (parsedItem && targetSprintId !== null) {
      const e = dropEvent;
  
      //const item = JSON.parse(e.dataTransfer?.getData("item") || '{}');
      const item = parsedItem;

     
  
      try {
        if (item.sprintId >= 0) {
  
          if (item.sprintId != targetSprintId) {
            const resp = await SprintOverviewService.AddingActionItems({
              projectId: projectId,
              sprintId: Number(targetSprintId),
              actionItemId: item.actionItemId,
              userId: loggedInUserID,
            });
  
            if (resp.status === HTTPResponse.OK) {
              // The API call was successful
              await GetActionItems();
              const itemIndex = list1.findIndex(
                (listItem) => listItem.actionItemId === item.actionItemId
              );
  
              if (itemIndex !== -1) {
                // Remove the item from list1
  
                const updatedList1 = [...list1];
  
                updatedList1.splice(itemIndex, 1);
  
                setList1(updatedList1);
  
                // Add the item to sprintData
  
                const matchingSprint = totalmappedData.find(
                  (sprint) => sprint.sprint_Id === targetSprintId
                );
  
                if (matchingSprint) {
                  // Assuming you have an item you want to add to the 'mappedItems' array
  
                  const newItem = item;
  
                  // Add the newItem to the 'mappedItems' array
  
                  matchingSprint.mappedItems.push(newItem);
                } else {
                  // Sprint with the targetSprintId was not found
                }
              }
            }
            if (resp.status === HTTPResponse.Unauthorized) {
              LogOut();
            } else {
              // Handle other status codes or error conditions as needed
            }
          }
          else {
            // Reordering within the same sprint
            //const targetSprintId = e.target.getAttribute('data-sprint-id'); // Get the target sprint ID
            const targetSprintId = e.target.getAttribute('data-sprint-id'); // Get the target sprint ID
  
            const matchingSprint = totalmappedData.find((sprint) => sprint.sprint_Id === targetSprintId);
  
            if (matchingSprint) {
              const item = JSON.parse(e.dataTransfer.getData("item"));
              const originalIndex = matchingSprint.mappedItems.findIndex(mappedItem => mappedItem.actionItemId === item.actionItemId);
              const droppedIndex = matchingSprint.mappedItems.findIndex(mappedItem => mappedItem.actionItemId === item.actionItemId);
  
              // Move the card within the same sprint
              if (droppedIndex !== -1 && originalIndex !== -1) {
                // Remove the item from its original position
                const movedItem = matchingSprint.mappedItems.splice(originalIndex, 1)[0];
                // Insert the item at the dropped position
                matchingSprint.mappedItems.splice(droppedIndex, 0, movedItem);
                // You may need to update state or perform any necessary updates here
              }
            }
          }
        }
  
      }  catch (e) {
        ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
      } finally {
        // Clean up after the drop is handled
        setShowDeleteModal(false);
        setDropEvent(null);
        setTargetSprintId(null);
      }
    }
  };
  const cancelDrop = () => {
    // Close the modal without performing any action
    setShowDeleteModal(false);
    setDropEvent(null);
    setTargetSprintId(null);
  };
  // const handleDrop = async (e, targetSprintId) => {

  //   e.preventDefault();
  //   //const item = JSON.parse(e.dataTransfer?.getData("item"));
  //   const item = JSON.parse(e.dataTransfer?.getData("item") || '{}');


  //   try {
  //     if (item.sprintId >= 0) {

  //       if (item.sprintId != targetSprintId) {
  //         const resp = await SprintOverviewService.AddingActionItems({
  //           projectId: projectId,
  //           sprintId: Number(targetSprintId),
  //           actionItemId: item.actionItemId,
  //           userId: loggedInUserID,
  //         });

  //         if (resp.status === HTTPResponse.OK) {
  //           // The API call was successful
  //           await GetActionItems();
  //           const itemIndex = list1.findIndex(
  //             (listItem) => listItem.actionItemId === item.actionItemId
  //           );

  //           if (itemIndex !== -1) {
  //             // Remove the item from list1

  //             const updatedList1 = [...list1];

  //             updatedList1.splice(itemIndex, 1);

  //             setList1(updatedList1);

  //             // Add the item to sprintData

  //             const matchingSprint = totalmappedData.find(
  //               (sprint) => sprint.sprint_Id === targetSprintId
  //             );

  //             if (matchingSprint) {
  //               // Assuming you have an item you want to add to the 'mappedItems' array

  //               const newItem = item;

  //               // Add the newItem to the 'mappedItems' array

  //               matchingSprint.mappedItems.push(newItem);
  //             } else {
  //               // Sprint with the targetSprintId was not found
  //             }
  //           }
  //         }
  //         if (resp.status === HTTPResponse.Unauthorized) {
  //           LogOut();
  //         } else {
  //           // Handle other status codes or error conditions as needed
  //         }
  //       }
  //       else {
  //         // Reordering within the same sprint
  //         //const targetSprintId = e.target.getAttribute('data-sprint-id'); // Get the target sprint ID
  //         const targetSprintId = e.target.getAttribute('data-sprint-id'); // Get the target sprint ID

  //         const matchingSprint = totalmappedData.find((sprint) => sprint.sprint_Id === targetSprintId);

  //         if (matchingSprint) {
  //           const item = JSON.parse(e.dataTransfer.getData("item"));
  //           const originalIndex = matchingSprint.mappedItems.findIndex(mappedItem => mappedItem.actionItemId === item.actionItemId);
  //           const droppedIndex = matchingSprint.mappedItems.findIndex(mappedItem => mappedItem.actionItemId === item.actionItemId);

  //           // Move the card within the same sprint
  //           if (droppedIndex !== -1 && originalIndex !== -1) {
  //             // Remove the item from its original position
  //             const movedItem = matchingSprint.mappedItems.splice(originalIndex, 1)[0];
  //             // Insert the item at the dropped position
  //             matchingSprint.mappedItems.splice(droppedIndex, 0, movedItem);
  //             // You may need to update state or perform any necessary updates here
  //           }
  //         }
  //       }
  //     }

  //   } catch (e) {
  //     ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
  //   }
  // };
  const [completedSprintsSprints, setCompletedSprintsSprints] = useState([]);

  const count = GetLocalStorageData("ActionItemCount");
  const GetActionItems = async () => {
    try {
      ToggleLoader(true);

      const [actionItemsResponse, notStartedSprintsResponse, completedSprints] =
        await Promise.all([
          SprintOverviewService.GetActionItems(pageParams.projectId, false),
          SprintOverviewService.GetNotStartedSprints(pageParams.projectId),
          SprintOverviewService.GetCompletedSprints(pageParams.projectId),
        ]);

      if (
        actionItemsResponse.status === HTTPResponse.OK &&
        notStartedSprintsResponse.status === HTTPResponse.OK &&
        completedSprints.status === HTTPResponse.OK
      ) {

        setCompletedSprintsSprints(completedSprints.data);
        if (actionItemsResponse && actionItemsResponse.data.length > 0) {
          const projectName = actionItemsResponse.data[0].projectName
          //AddLocalStorageData("projectName",projectName);
          setProjectName(projectName);
          //Mustakim: Manually dispatch the storage event to update project name in sprintoverview page, as breadcrumb local storage was used.
          AddLocalStorageData("projectName", projectName);
          //window.dispatchEvent(new Event('storage')); 

        }

        // let count =actionItemsResponse.data.length + completedSprints.data[0].actionItems.length;
        // // +completedSprints.actionItems.length();
        // setActionItemCount(count);
        let count = actionItemsResponse.data.length;

        if (completedSprints.data.length > 0) {
          completedSprints.data.forEach((sprint) => {
            if (sprint.actionItems && sprint.actionItems.length > 0) {
              count += sprint.actionItems.length;
            }
          });
        }
        count = count + 1;
        AddLocalStorageData("ActionItemCount", count);

        const backlogActionItems = actionItemsResponse.data.filter(
          (item) => item.sprintId == 0
        );

        const getNotStartedSprintsDataArray =
          notStartedSprintsResponse.data.filter((item) => item.sprintID != 0);

        getNotStartedSprintsDataArray.forEach((item) => {
          if (item.status == false) {
            if (!item.mappedItems) {
              item.mappedItems = [];
            }

            const matchingResponseItems = actionItemsResponse.data.filter(
              (responseItem) => responseItem.sprintId === item.sprint_Id
            );

            item.mappedItems.push(...matchingResponseItems);
          }
        });
        setCompletedSprintId(completedSprints.data);
        setSprintsnotstarted(getNotStartedSprintsDataArray);

        setBacklogIssueCount(backlogActionItems.length);

        setBacklogActionItemsActionItemType(
          backlogActionItems.map((item) => item.actionItemTypeID)
        );

        setBacklogActionItemsPriorityId(
          backlogActionItems.map((item) => item.priorityId)
        );

        setBacklogActionItems(backlogActionItems);

        setSprintData(actionItemsResponse.data);

        setSprintIssueCount(
          actionItemsResponse.data.filter((item) => item.sprintId != 0).length
        );
        setNotstartedsprint(
          actionItemsResponse.data.filter((item) => item.status == 0)
        );
      }
      if (
        actionItemsResponse.status === HTTPResponse.Unauthorized ||
        notStartedSprintsResponse.status === HTTPResponse.Unauthorized ||
        completedSprints.status === HTTPResponse.Unauthorized
      ) {
        LogOut();
      }
      ToggleLoader(false);
    } catch (error) {
      ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
    }
  };
  const updatedSprints = totalmappedData.map((sprint) => {
    const matchingCompletedSprint = completedSprintsSprints.find(
      (item) => item.sprintId === sprint.sprint_Id
    );
    if (matchingCompletedSprint) {
      return {
        ...sprint,
        completiondate: matchingCompletedSprint.completionDate,
      };
    } else {
      return sprint; // If no matching sprint is found, keep the sprint as is
    }
  });
  // Group objects by sprintId

  const groupedData = sprintData.reduce((result, item) => {
    const sprintId = item.sprintId;

    if (item.status === true) {
      if (!result[sprintId]) {
        result[sprintId] = [];
      }

      result[sprintId].push(item);
    }

    return result;
  }, {});

  // Map the grouped data as needed
  //let mappedItemsTest = [];

  const mappedData = Object.keys(groupedData).map((sprintId) => {
    const sprintData = groupedData[sprintId];

    const mappedItems = sprintData.map((item) => ({
      actionItemStatusId: item.actionItemStatusId,
      sprintId: item.sprintId,

      key: item.key,

      title: item.title,

      sprintName: item.sprintName,

      priorityId: item.priorityId,

      actionItemTypeID: item.actionItemTypeID,

      designEstimates: item.designEstimates,

      devEstimates: item.devEstimates,

      qaEstimates: item.qaEstimates,

      assigneeFirstName: item.assigneeFirstName,

      assigneeLastName: item.assigneeLastName,

      actionItemId: item.actionItemId,

      color: item.color,

      teamId: item.teamId,

      editedOn: item.editedOn,

      assigneeId: item.assigneeId,
      projectCategoryId: item.projectCategoryId,
      lastActionItemStatus: item.lastActionItemStatus,
      createdBy: item.createdBy

    }));
    const sprint_Id = sprintData[0].sprintId;

    const sprintName = sprintData[0].sprintName;

    const startDate = sprintData[0].startDate;

    const endDate = sprintData[0].endDate;

    const releaseName = sprintData[0].releaseName;

    const itemCount = sprintData.length;

    const status = sprintData[0].status;

    const actionItemId = sprintData[0].actionItemId;

    //mappedItemsTest = mappedItems;
    return {
      sprint_Id: sprint_Id,

      releaseName: releaseName,

      sprintName: sprintName,

      mappedItems: mappedItems,

      startDate: startDate,

      endDate: endDate,

      itemCount: itemCount,

      actionItemId: actionItemId,

      status: status,
    };
  });


  //useEffect(() => {
  //  setFilteredItems(mappedItemsTest);
  //}, []);

  // Function to calculate the total estimated time for a sprint, considering action item type
  const calculateTotalEstimatedTime = (sprint) => {
    let totalEstimatedTime = 0;
    sprint.mappedItems.forEach((item) => {
      totalEstimatedTime +=
        item.designEstimates + item.devEstimates + item.qaEstimates;
    });
    return totalEstimatedTime.toFixed(2).replace(/\.?0+$/, "");
    //.replace(/\.?0+$/, "")}
  };
  const createIssue = (sprintName, sprint_Id) => {
    navigate(`/AddActionItem/${projectId}`, {
      state: { sprintNames: sprintName, sprint_Ids: sprint_Id },
    });

    const notstartedSprint = {
      value: sprint_Id,
      label: sprintName,
      id: sprint_Id,
    };
    // const newList = [...notstartedSprint];

    AddLocalStorageData('selectedSprint', JSON.stringify(notstartedSprint));

  };

  totalmappedData = mappedData.concat(sprintsnotstarted);
  // HandleFilterClick("category")


  /* <summary>
  date: 09-02-2024
  Name: MS
  description: Clear search input 
  <summary>*/
  //   const handleClearInput=()=>{
  //  setSearchedValue("")
  //   }
  const handleClearInput = () => {
    setSearchedValue("");
    setSprintItems(totalmappedData.flatMap(sprint => sprint.mappedItems.map(filteredItem => ({
      ...filteredItem,
      sprintId: sprint.sprint_Id
    }))));
    setListItems(list1);
    setFilteredItems([...totalmappedData.flatMap(sprint => sprint.mappedItems), ...list1]);
    //setCompletedSprint(completedSprintsSprints);
    setCompletedSprint([]);
    if (category != null) {
      HandleFilterClick("category")

    }
    setSearchExists(true);
  };
  /* <summary>
date: 15-01-2024
Name: MS
description: To get Id of last coulmn to add strike off line to key and title in each page
<summary>*/

  const getLastColumnId = async () => {
    //ToggleLoader(true)
    const response = await SprintService.GetLastColumnForProjectActionItem(projectId)

    setLastColumn(response.data)

  }
  const [cardIndexes, setCardIndexes] = useState([]);
  const handleDragStartt = (e, item) => {
    
    setSprintDragged((prev)=>({
      ...prev,previousSprint:item.sprintName,
      key:item.key
    }))
    e.dataTransfer.setData('item', JSON.stringify(item));
  };

  const handleDragOverr = (e) => {
    e.preventDefault();
  };
  const [updateOrder, setUpdateOrder] = useState({
    orderIndex: 0, actionItemId: 0, sprintId: 0
  });

  const UpdateActionItemOrderIndex = async () => {

    const response = await BacklogService.UpdateActionItemOrderIndex(updateOrder);
    if (response.status == HTTPResponse.OK) {
      await GetActionItems();
    }

  }
  useEffect(() => {
    if (updateOrder.orderIndex === 0 && updateOrder.actionItemId === 0 && updateOrder.sprintId === 0) {
      return;
    }
    UpdateActionItemOrderIndex();
  }, [updateOrder])
  const handleDropp = (e, targetSprintId, droppedIndex) => {

    e.preventDefault();
    //const item = JSON.parse(e.dataTransfer.getData('item'));
    const item = JSON.parse(e.dataTransfer?.getData("item") || '{}');
    if (item.actionItemId) {
      const updatedCardIndexes = [...cardIndexes];
      const currentIndex = updatedCardIndexes.indexOf(item.actionItemId);

      if (currentIndex !== -1) {
        updatedCardIndexes.splice(currentIndex, 1);
      }

      updatedCardIndexes.splice(droppedIndex, 0, item.actionItemId);
      setCardIndexes(updatedCardIndexes);

      setUpdateOrder((prev) => ({
        ...prev, orderIndex: droppedIndex + 1, actionItemId: item.actionItemId, sprintId: item.sprintId
      }))
      // Send the updatedCardIndexes to the backend to update the orderIndex of each card
      // You can make an API call here to update the orderIndex
    }

  };
  const GetCategoriesList = async () => {
    try {

      //  ToggleLoader(true);


      let categoryListData = await ProjectService.GetReleases(projectId);

      const categList = categoryListData?.data?.projectCategories.map((t) => ({
        value: t.projectCategoryId,
        label: t.categoryName,
        //imagePath: t.imagePath,
      }));
      //const category=categoryListData?.data.projectCategories;

      setCategoryList(categList)
      // ToggleLoader(false);
    }


    catch (error) {
      ToggleLoader(false);
      ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
    }
  }
  const HandleDropdownChange = (e) => {
    if (e === null) {
      setcategory(null);
    }
    else {
      setCategoryLocation(null)
      setcategory(e ? e.value : null);
      //HandleFilterClick("category")
    }
  }
  const HandleCreatedByDropdownChange = (e) => {
    if (e === null) {
      setCreatedByDrp(null);
    }
    else {
     
      setCreatedByDrp(e ? e.value : null);
      
    }
  }
  useEffect(() => {

    if (categoryLocation) {
      setcategory(categoryLocation)
      //HandleFilterClick("category")
    }
  }, [categoryLocation])


  useEffect(() => {

    if (selectedEmployees.length===0
      && totalmappedData.length > 0 
      && list1.length > 0 
      //&& selectedEmployees.length>0
      && selectedEmployees.length===0
      && createdByDrp !=null
      ) {
      HandleFilterClick("CreatedBy")
    }
   else if (selectedEmployees.length>0
      && totalmappedData.length > 0 
      && list1.length > 0 
      && category == null
      && createdByDrp !=null
      ) {
      HandleFilterClick("CreatedByAndAssignee")
    }
   else  if (category != null 
      && totalmappedData.length > 0 
      && list1.length > 0 
      //&& selectedEmployees.length>0
      && createdByDrp !=null
      ) {
      HandleFilterClick("CreatedByAndCategory")
    }
   else if (category != null 
      && totalmappedData.length > 0 
      && list1.length > 0 
      && selectedEmployees.length>0
      && createdByDrp !=null
      ) {
      HandleFilterClick("CreatedByAndAssigneeAndCategory")
    }
    
  else  if (category != null && totalmappedData.length > 0 && list1.length > 0 && selectedEmployees.length>0) {

      HandleFilterClick("AssigneeAndCategory")
    }
   else if (selectedEmployees.length>0) {
      HandleFilterClick("employees")
    }

    else if (category != null && totalmappedData.length > 0 && list1.length > 0) {
      HandleFilterClick("category")
    }
    else if(myIssues){
      setAllIssues(false);
      setRecentlyUpdated(false);
      HandleFilterClick(loggedInUserID === ROLE_TYPE.ADMIN ? "Admin" : "myIssues")
    }
    else if(allIssues){
      setMyIssues(false);
      setRecentlyUpdated(false);
      HandleFilterClick("");
    }
    else if(recentlyUpdated){
      setMyIssues(false);
      setAllIssues(false);
      HandleFilterClick("recentlyUpdated");
    }
    
    else if (categoryLocation == null && categoryValue === null) {

      HandleFilterClick("")
    }
    
    else if (category == null) {

      HandleFilterClick("")
    }

  }, [category, list1,selectedEmployees,createdByDrp])
  //AddLocalStorageData projectName
  const styles = {
    control: (base) => ({
      ...base,
    
      color: "#483567!Important",
      height: '20px',
      
      zIndex: "9",
      backgroundColor: '#fff',
     
    }),
  };
  const stylesIsMulti = {
    control: (base) => ({
      ...base,
      color: "#483567!Important",
      height: '20px',
     
      zIndex: "9",
      backgroundColor: '#fff',
      //width: '200px', // Set fixed width
    }),
    valueContainer: (base) => ({
      ...base,
      display: 'flex',
      flexWrap: 'nowrap', // Prevent wrapping of selected options
      overflowX: 'auto', // Enable horizontal scrolling
    }),
    multiValue: (base) => ({
      ...base,
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis', // Show ellipsis if the text is too long
      maxWidth: '100%', // Ensure it fits within the container
    }),
    multiValueLabel: (base, { data }) => ({
      ...base,
      whiteSpace: 'nowrap', // Prevent text wrapping within each selected option
      overflow: 'hidden',
      textOverflow: 'ellipsis', // Show ellipsis if the text is too long
      maxWidth: '100%',
      title:data.title
    }),
  };

//   const customStyles = {
//     groupHeading: (provided) => ({
//         ...provided,
//         fontWeight: 'bolder',
//         fontSize: '14px', // Set your desired font size
//         color: 'black',  // Set the font color to black 
//         fontFamily: 'Lato-Regular',
//     }),
//     option: (provided, state) => ({
//         ...provided,
//       //  ...(state.isSelected ? { marginLeft: '15px', fontSize: '12px', fontFamily: 'Lato-Regular' } : { marginLeft: '15px', fontFamily: 'Lato-Regular', fontSize: '12px' }), // Apply margin-left only if the option is not selected
//     }),
// };

// const customStyles = {
//   groupHeading: (provided) => ({
//       ...provided,
//       fontWeight: 'bolder',
//       fontSize: '14px',
//       color: 'black',
//       fontFamily: 'Lato-Regular',
//   }),
//   option: (provided, state) => ({
//       ...provided,
//   }),
//   valueContainer: (provided) => ({
//       ...provided,
//       display: 'flex',
//       flexWrap: 'nowrap', // Prevent wrapping of selected options
//       overflowX: 'auto', // Enable horizontal scrolling
//       overflowY: 'hidden', // Hide vertical overflow
//   }),
//   multiValue: (provided) => ({
//       ...provided,
//       display: 'flex',
//       alignItems: 'center',
//       margin: '2px', // Add some spacing between multi-value items
//       whiteSpace: 'nowrap', // Prevent text wrapping within each selected option
//   }),
//   multiValueLabel: (provided) => ({
//       ...provided,
//       whiteSpace: 'nowrap', // Prevent text wrapping
//       overflow: 'hidden',
//       textOverflow: 'ellipsis', // Show ellipsis if the text is too long
//       maxWidth: '200px', // Set a fixed width for the label
//   }),
//   multiValueRemove: (provided) => ({
//       ...provided,
//       cursor: 'pointer', // Ensure remove button has a pointer cursor
//   }),
// };
 /* <summary>
  date:08-08-2024
  Name: Mustakim Shaikh
  description: to get employees assigned to project and use it to filter tasks assigned
  <summary>*/
  const getProjectAssignedEmployee = async () => {
    try {
      const resp = await ProjectService.GetAssignedEmployees(projectId, false);

      if (resp.status === HTTPResponse.OK) {
        let employeeIdAndName = resp.data.map((employee) => ({
          employeeId: employee.employeeId,
          fullName: employee.fullName,
        }));
        const filered = employeeIdAndName
        //.filter(emp => emp.employeeId !== GetLoggedInUserID())
        setTeam(filered)
      }
      if (resp.status === HTTPResponse.Unauthorized) {
        LogOut();
      }
    } catch {
      ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
    }
  };
  const HandleEmployeeDropdownChange = (selectedOptions, id, drp) => {
    if (drp === "employeeId") {
      setSelectedEmployees(selectedOptions);
    } else {
      //setSelectedProject(selectedOptions);
    }
  };
const defaultDropdownStyles = {
  control: (base, state) => ({
    ...base,
    backgroundColor: state.isDisabled ? "hsl(0,0%,90%)" : "white",
  }),
 
  placeholder: (defaultStyles, state) => {
    return {
      ...defaultStyles,
      color: "black",
      marginLeft: "6px",
    };
  },
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      whiteSpace: "pre",
      ":hover": {
        backgroundColor: "white",
      },
      color: "black",
      backgroundColor: "white",
      height: "30px",
    };
  },
 
  valueContainer: (provided, state) => ({
    ...provided,
    padding: "0 6px",
  }),
 
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "32px",
  }),
 
  dropdownIndicator: (base) => ({
    ...base,
    color: "#212529", // Custom colour
  }),
};

const CheckboxOption = (props) => (
  <components.Option {...props}>
    <input type="checkbox" checked={props.isSelected} onChange={() => null} />{" "}
    {props.label}
  </components.Option>
);
  return (
    <>
   
      <title>Overview</title>

      {/* Header Section */}

      <main
        className=" overview"
        style={{ minHeight: "67vh" }}
      >
        {showDeleteModal && (

<DeleteModal 
showDeleteModal={showDeleteModal}
onYesClick={confirmDrop}
onNoClick={cancelDrop}
textToDisplay={"Move Work Item"}
sprintConfirmModal={true}
sprintDetails={sprintDragged}
/>
        )}
        <section className="overview-main-block">
          <div className="">
            <div className="row">
              <div>
                <div className="" id="myTabContent">
                  <div
                    className="tab-pane fade active show"
                    id="overview"
                    role="tabpanel"
                    aria-labelledby="overview-tab"
                  >
                    {/* Content for the Overview tab */}

                    <div className="">
                      <div className="">

                        <div className="list list2">

                          <div
                            className="searchbar sticky-item custom-filter-sizing"
                            style={{
                              display: "flex",
                              padding: "0px 0px 10px 0px",
                              flexWrap: 'wrap'
                            }}
                          >

                            <div className="quickfilter label" style={{ alignContent: "center" }}>
                              <label>Quick filters:</label>
                            </div>
                            <div className="qfliter" style={{ alignContent: "center" }}>
                              <div className={`quickfilter all-issues ${activeFilter === "" ? 'active' : ''}`} style={{ marginLeft: "5px", marginTop: "5px" }}>
                                <button
                                  onClick={() =>{
                                    
                                    setCreatedByDrp(null);
                                    setMyIssues(false);
                                    setRecentlyUpdated(false);
                                    setAllIssues(true);setcategory(null); HandleFilterClick("");setSelectedEmployees([])} }
                                >
                                  <img
                                    src="Assets/images/all-issues-icon.png"
                                    style={{ width: "18px", height: '21px', marginRight: "5px" }}
                                  />
                                  All Issues
                                </button>
                              </div>
                              <div className={`quickfilter my-issues ${activeFilter === "Admin" || activeFilter === "myIssues" ? 'active' : ''}`} style={{ marginLeft: "5px", marginTop: "5px" }}>
                                <button
                                  onClick={() =>{
                                    setCreatedByDrp(null);
                                    setAllIssues(false);
                                    setRecentlyUpdated(false);
                                    setMyIssues(true);setcategory(null); setSelectedEmployees([]);   HandleFilterClick(loggedInUserID === ROLE_TYPE.ADMIN ? "Admin" : "myIssues")} }
                                >
                                  <img
                                    src="Assets/images/my-issues-icon (2).png"
                                    style={{ width: "18px", height: '21px', marginRight: "5px" }}
                                  />
                                  Only My Issues
                                </button>
                              </div>

                              <div className={`quickfilter my-issues ${activeFilter === "recentlyUpdated" ? 'active' : ''}`} style={{ marginLeft: "5px", marginTop: "5px" }}>
                                <button
                                  onClick={() =>{
                                    setCreatedByDrp(null);
                                    setMyIssues(false);
                                    setAllIssues(false);
                                    setRecentlyUpdated(true);setcategory(null); setSelectedEmployees([]); HandleFilterClick("recentlyUpdated")}}
                                >
                                  <img
                                    src="Assets/images/recently-updated.png"
                                    style={{ width: "18px", height: "21px", marginRight: "5px" }}
                                  />
                                  Recently Updated
                                </button>

                              </div>
                            </div>

                            <div
                              style={{ textAlign: "left", marginTop: "10px" }}
                            >
                              {" "}
                              {!searcheExist && "No result found"}
                            </div>

                            {/* <div style={{ width: "150px", backgroundColor: "white", marginLeft: "auto", marginTop: "5px" }}>
                             
                             <Select
                               inputId="CreatedByDrpDwn"
                               placeholder="Created by"
                             value={createdByDrp}
                              options={team.map((emp) => ({
                               value: emp.employeeId,
                               label: emp.fullName
                               ,
                             }))}
                              
                               onChange={(selectedOptions) => {
                                 //setSelectedEmployees(selectedOptions);
                                 setCreatedByDrp(selectedOptions.value)
                               }}
                               //isMulti  
                               menuPortalTarget={document.body} // Render the menu in the body to avoid overflow issues
                               menuPosition="fixed" // Use fixed positioning for the menu
                              
                              // styles={stylesIsMulti}
                               isClearable={selectedEmployees.length>0}
                 
                             />
                           </div> */}
                            <div style={{ width: "150px", backgroundColor: "white", marginLeft: "16px", marginTop: "5px" }}>
                             
                              <Select
                               inputId="CreatedByDrpDwn"
                               placeholder="Created by"
                              value={createdByDrp ? team.find(c => c.value === createdByDrp) : null}
                                options={team.map((emp) => ({
                                  value: emp.employeeId,
                                  label: emp.fullName
                                  ,
                                }))}
                                onChange={(e) =>
                                  HandleCreatedByDropdownChange(e)
                                 
                                }
                                menuPortalTarget={document.body} // Render the menu in the body to avoid overflow issues
                                menuPosition="fixed" // Use fixed positioning for the menu
                               
                                styles={styles}
                                isClearable={createdByDrp !== undefined && (!createdByDrp === null || createdByDrp > 0 || createdByDrp === 0)}
                              
                              />
                              
                            </div>

                            <div style={{ width: "150px", backgroundColor: "white", marginLeft: "16px", marginTop: "5px" }}>
                             
                              <Select
                                inputId="EmployeeDrpDwn"
                                placeholder="Assignee"
                              value={selectedEmployees}
                               options={team.map((emp) => ({
                                value: emp.employeeId,
                                label: emp.fullName
                                ,
                              }))}
                              components={{ Option: CheckboxOption }} // Use custom Option component
                              hideSelectedOptions={false}
                                onChange={(selectedOptions) => {
                                  setSelectedEmployees(selectedOptions);
                               
                                }}
                                isMulti  
                                menuPortalTarget={document.body} // Render the menu in the body to avoid overflow issues
                                menuPosition="fixed" // Use fixed positioning for the menu
                               
                                styles={stylesIsMulti}
                                isClearable={selectedEmployees.length>0}
                  
                              />
                              {/* <ErrorField
                            show={error.categoryId}
                            message={AddEditActionItemErrorMsg.Category}
                          /> */}

{/* <Select
                  inputId="team"
                  placeholder="Please Select"
                  options={team.map((emp) => ({
                    value: emp.employeeId,
                    label: emp.fullName
                    ,
                  }))}
                  value={selectedEmp ? selectedEmp : null}
                  onChange={(e) => {

                    setSelectedEmp(e); HandleFilterClick(e, "FilterEmp")
                  }}

                  isClearable={selectedEmp !== undefined || (!selectedEmp === null && selectedEmp?.value > 0)}
                /> */}
                            </div>
                            <div style={{ width: "150px", backgroundColor: "white", marginLeft: "16px", marginTop: "5px" }}>
                              {/* <label>
                            Category <span className="mandatory-field">*</span>
                          </label> */}
                              <Select
                                inputId=" CategoryDrpDwn"
                                placeholder="Category"
                                //value={actionItemDetails?.categoryDrpDwn?.label !=""?actionItemDetails.categoryDrpDwn:null}
                                //  value={categoryValue!=null? categoryList.find(c=>c.value===categoryValue): category !=null?categoryList.find(c=>c.value===category):  null}
                                value={category ? categoryList.find(c => c.value === category) : null}
                                options={categoryList}
                                onChange={(e) =>
                                  HandleDropdownChange(e)
                                }
                                menuPortalTarget={document.body} // Render the menu in the body to avoid overflow issues
                                menuPosition="fixed" // Use fixed positioning for the menu
                                // styles={{
                                //    menuPortal: (base) => ({ ...base, zIndex: 9999 }), // Ensure the menu is on top of other elements
                                // }}
                                styles={styles}
                                isClearable={category !== undefined && (!category === null || category > 0 || category === 0)}
                              //isClearable
                              />
                              {/* <ErrorField
                            show={error.categoryId}
                            message={AddEditActionItemErrorMsg.Category}
                          /> */}
                            </div>


                            <div
                              className="searchIssue"
                              style={{  backgroundColor: "white", marginLeft: "auto", marginTop: "5px" }}
                            >
                              {/* <label>
                                Search <span className="isRequiredField"></span>
                              </label> */}
                              <div
                                className="searchBar"
                                style={{ justifyContent: "right", alignSelf: "flex-end" }}
                              >
                                <input
                                  className="searchQueryInput-class"
                                  id="searchQueryInput"
                                  type="text"
                                  name="searchQueryInput"
                                  value={searchedValue}
                                  placeholder="Search issue"
                                  onChange={handleSearchInputChange}
                                />
                                <button
                                  id="searchQuerySubmit"
                                  type="submit"
                                  name="searchQuerySubmit"
                                  onClick={handleClearInput}
                                >
                                  {searchedValue && (<img style={{ width: '20px' }}
                                    src="Assets/images/removeIcon.png"
                                  />)}
                                  {!searchedValue && (<img
                                    src="Assets/images/Icon feather-search.png"
                                    style={{ width: "20px", cursor: "pointer" }}
                                  />)}
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="scrollable-container">
                            {totalmappedData.map((sprint) => (
                              <div key={sprint.sprint_Id}>
                                <div
                                  onDragOver={(e) => handleDragOver(e)}
                                  onDrop={(e) =>
                                    handleDrop(e, sprint.sprint_Id,sprint.sprintName)
                                  }
                                  onDragStart={(e)=>setTargetSprintId(sprint.sprint_Id)}
                                  className="accordion curson-change"
                                  id={sprint.sprint_Id}
                                >
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      style={{ display: "flex" }}
                                    >
                                      <button
                                        className="accordion-button"
                                        type="button"
                                        style={{
                                          width: "30px", backgroundColor: '#ebe6f5'
                                        }}
                                        data-bs-toggle="collapse"
                                        data-bs-target={`#panelsStayOpen-collapse-${sprint.sprint_Id}`}
                                        aria-expanded="true"
                                        aria-controls={`panelsStayOpen-collapse-${sprint.sprint_Id}`}
                                      ></button>
                                      <button className="accordian-devstyle">
                                        <div
                                          className="row"
                                          style={{ width: "100%" }}
                                        >
                                          <div
                                            className="col-12"
                                            style={{ paddingRight: 0 }}
                                          >
                                            <div className="sprint-header-bar">
                                              <div className="sprint-detail-initial">
                                                <div className="sprint name" style={{ marginLeft: "7px" }}>
                                                  {sprint.sprintName}
                                                </div>

                                                <div
                                                  key={sprint.sprintName}
                                                  className="sprint issue-count"
                                                >
                                                  {sprint.status
                                                    ? sprint.itemCount +
                                                    " " +
                                                    (sprint.itemCount === 1
                                                      ? "issue"
                                                      : "issues")
                                                    : sprint.mappedItems
                                                      .length +
                                                    " " +
                                                    (sprint.mappedItems
                                                      .length === 1
                                                      ? "issue"
                                                      : "issues")}
                                                </div>
                                                {sprint.status == true && (
                                                  <div className="sprint-release" style={{
                                                    height: "1.9em",
                                                    display: "flex",
                                                    alignItems: "center",
                                                  }} >
                                                    {sprint.releaseName}
                                                  </div>
                                                )}

                                                {sprint.status === true && (
                                                  <div
                                                    className={`sprint ${(new Date(formatDate(sprint.startDate)) <= new Date() &&
                                                      new Date() <= new Date(formatDate(sprint.endDate))) ||
                                                      (new Date(formatDate(sprint.startDate)).toDateString() ===
                                                        new Date().toDateString() ||
                                                        new Date(formatDate(sprint.startDate)) > new Date()) || new Date(formatDate(sprint.endDate)).toDateString() ===
                                                      new Date().toDateString()
                                                      ? "sprint-status"
                                                      : "inactive-status"
                                                      }`}
                                                    style={{
                                                      height: "1.9em",
                                                      display: "flex",
                                                      alignItems: "center",
                                                      color:
                                                        ((new Date(formatDate(sprint.startDate)) <= new Date() &&
                                                          new Date() <= new Date(formatDate(sprint.endDate))) ||
                                                          new Date(formatDate(sprint.startDate)).toDateString() ===
                                                          new Date().toDateString() ||
                                                          new Date(formatDate(sprint.startDate)) > new Date()) || new Date(formatDate(sprint.endDate)).toDateString() ===
                                                          new Date().toDateString()
                                                          ? "green"
                                                          : "",
                                                    }}
                                                  >
                                                    {((new Date(formatDate(sprint.startDate)) <= new Date() &&
                                                      new Date() <= new Date(formatDate(sprint.endDate))) ||
                                                      new Date(formatDate(sprint.startDate)).toDateString() ===
                                                      new Date().toDateString() ||
                                                      new Date(formatDate(sprint.startDate)) > new Date()) || new Date(formatDate(sprint.endDate)).toDateString() ===
                                                      new Date().toDateString()
                                                      ? "Active"
                                                      : ""}
                                                  </div>
                                                )}
                                              </div>

                                              <div className="sprint-detail-end" style={{ marginRight: "14px" }}>
                                                <div className="sprint Time">
                                                  <p
                                                    style={{
                                                      color: "white", marginTop: "2px",
                                                    }}
                                                  >
                                                    {calculateTotalEstimatedTime(
                                                      sprint
                                                    )}
                                                    h
                                                  </p>
                                                </div>

                                                <div
                                                  className="pencil-icon"
                                                  style={{ marginRight: "5px", color: "rgb(56, 6, 87)", marginRight: "10px" }}
                                                  onClick={() =>
                                                    openEditSprintModal(
                                                      sprint.sprint_Id,
                                                      sprint.mappedItems
                                                        .length,
                                                      sprint.sprintName,
                                                      sprint.startDate,
                                                      sprint.endDate
                                                    )
                                                  }>
                                                  <i className="fa fa-2x fa-pencil-square-o" aria-hidden="true" />
                                                </div>

                                                {sprint.status == false && (
                                                  <div className="start-sprint"
                                                    style={{ border: "2px solid #aba9a9", marginRight: "10px" }}>
                                                    <button
                                                      className="start-sprint"
                                                      onClick={() =>
                                                        openStartSprintModal(
                                                          sprint.sprint_Id,

                                                          sprint.mappedItems
                                                            .length,

                                                          sprint.sprintName,
                                                          sprint.startDate,
                                                          sprint.endDate
                                                        )
                                                      } // Add this style to remove the border
                                                    >
                                                      Start Sprint
                                                    </button>
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          </div>

                                          {sprint.status == true && (
                                            <div className="col-12">
                                              {
                                                <div className="sprint-period">
                                                  {formatDate(sprint.startDate)}{" "}
                                                  - {formatDate(sprint.endDate)}
                                                </div>
                                              }
                                            </div>
                                          )}
                                        </div>
                                      </button>
                                    </h2>
                                    <div
                                      id={`panelsStayOpen-collapse-${sprint.sprint_Id}`}
                                      className="accordion-collapse sprint collapse show"
                                      aria-labelledby={`panelsStayOpen-heading-${sprint.sprint_Id}`}
                                    >
                                      <div className="accordion-body">
                                        {filterClicked ? [] : filterRecentEmpty && filteredItems.length === 0 ? [] : searcheExist &&
                                          (filteredItems.length > 0
                                            ? sprintItems
                                            : sprint.mappedItems
                                          ).map((item, index) => {
                                            if (
                                              item.sprintId === sprint.sprint_Id
                                            ) {
                                              return (
                                                <div
                                                  key={index}
                                                  draggable
                                                  onDragStart={(e) => handleDragStartt(e, item)}
                                                  onDragOver={handleDragOverr}
                                                  onDrop={(e) => handleDropp(e, sprint.sprint_Id, index)}
                                                  className="drag-drop"
                                                >
                                                  <Card
                                                    draggable // Add this attribute to make the Card draggable
                                                    lastColumn={item.lastActionItemStatus}
                                                    key={item.key}
                                                    item={item}
                                                    isDisabled={false}
                                                    tasktypepath={
                                                      ACTION_ITEM_TYPE[
                                                      item.actionItemTypeID
                                                      ]
                                                    }
                                                    projectId={
                                                      pageParams.projectId
                                                    }
                                                    prioritypath={
                                                      TASK_PRIO[item.priorityId]
                                                    }
                                                    prioritytitle={
                                                      PriorityListTitle[
                                                      item.priorityId
                                                      ]
                                                    }
                                                    tasktitle={
                                                      ACTION_ITEM_TYPE_TITLE[
                                                      item.actionItemTypeID
                                                      ]
                                                    }
                                                    assigneeFirstName={
                                                      item.assigneeFirstName
                                                    }
                                                    assigneeLastName={
                                                      item.assigneeLastName
                                                    }
                                                    actionItemStatusId={
                                                      item.actionItemStatusId
                                                    }
                                                    onDragStart={handleDragStart}
                                                    bgcolor={item.color}
                                                  /></div>
                                              );
                                            } else {
                                              return null;
                                            }
                                          })}
                                        {sprint.status == false && (
                                          <button
                                            className="start-sprint"
                                            style={{
                                              marginTop: "2px",
                                              marginLeft: "5px",
                                              textDecoration: "none",
                                              border: "2px solid #5e5e5e"
                                            }}
                                            onClick={() =>
                                              createIssue(
                                                sprint.sprintName,
                                                sprint.sprint_Id
                                              )
                                            }
                                          >
                                            <i
                                              class="fas fa-plus"
                                              style={{ color: "blue" }}
                                            ></i>{" "}
                                            Add work item
                                          </button>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}

                            {/* SYJ - Commenting this as in overview completed sprints were also Showing on Serach   */}
                            <div style={{ marginTop: '30px' }}>

                              {searcheExist &&
                                (filterClicked ? [] : filterRecentEmpty && filteredItems.length === 0 ? [] : filteredItems.length > 0
                                  ? completedSprint
                                  : []
                                )
                                  .map(
                                    (sprint) =>
                                      sprint.actionItems.length > 0 && (
                                        <div key={sprint.sprintId}>

                                          <div
                                            className="accordion"
                                            id={sprint.sprintId}
                                          >
                                            <div className="accordion-item">

                                              <div
                                                className="accordion-header"
                                                style={{ display: "flex" }}
                                              >

                                                <button
                                                  className="accordion-button"
                                                  type="button"
                                                  style={{ width: "30px" }}
                                                  data-bs-toggle="collapse"
                                                  data-bs-target={`#panelsStayOpen-collapse-${sprint.sprintId}`}
                                                  aria-expanded="true"
                                                  aria-controls={`panelsStayOpen-collapse-${sprint.sprintId}`}
                                                ></button>
                                                <button className="accordian-devstyle">
                                                  <div
                                                    className="row"
                                                    style={{ width: "100%" }}
                                                  >
                                                    <div
                                                      className="col-12"
                                                      style={{ paddingRight: 0 }}
                                                    >
                                                      <div className="sprint-header-bar">
                                                        <div className="sprint-detail-initial">
                                                          <div className="sprint name">
                                                            {sprint.sprintName}

                                                          </div>

                                                          <div
                                                            key={sprint.sprintName}
                                                            className="sprint issue-count"
                                                          >
                                                            {sprint.totalTasks ===
                                                              1 ? (
                                                              <div>
                                                                {sprint.totalTasks}{" "}
                                                                issue
                                                              </div>
                                                            ) : (
                                                              <div>
                                                                {sprint.totalTasks}{" "}
                                                                issues
                                                              </div>

                                                            )}
                                                          </div>
                                                          <div className="sprint-release sprint">
                                                            {sprint.releaseName}
                                                          </div>
                                                        </div>
                                                        <div className="sprint completed-sprint">
                                                          {"Completion Date : "}
                                                          {formatDate(
                                                            sprint.completionDate
                                                          )}
                                                        </div>
                                                        {/* <div className="sprint-detail-end"></div> //Comment this when uncommneting */}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </button>

                                              </div>

                                              <div
                                                id={`panelsStayOpen-collapse-${sprint.sprintId}`}
                                                className="accordion-collapse sprint collapse show"
                                                aria-labelledby={`panelsStayOpen-heading-${sprint.sprintId}`}
                                              >
                                                <div className="sprint-period">
                                                  {formatDate(sprint.startDate)}{" "}
                                                  - {formatDate(sprint.endDate)}
                                                </div>
                                                <div className="accordion-body">
                                                  {sprint.actionItems.map((item) =>
                                                    sprint.sprintId ===
                                                      item.sprintId ? (
                                                      <Card
                                                        lastColumn={item.lastActionItemStatus}
                                                        key={item.key}
                                                        item={item}
                                                        isDisabled={true}

                                                        tasktypepath={
                                                          ACTION_ITEM_TYPE[item.actionItemTypeId]
                                                        }
                                                        projectId={
                                                          pageParams.projectId
                                                        }
                                                        prioritypath={
                                                          TASK_PRIO[item.priorityId]
                                                        }
                                                        tasktitle={
                                                          ACTION_ITEM_TYPE_TITLE[
                                                          item.actionItemTypeID
                                                          ]
                                                        }
                                                        assigneeFirstName={
                                                          item.assigneeFirstName
                                                        }
                                                        assigneeLastName={
                                                          item.assigneeLastName
                                                        }
                                                        actionItemStatusId={item.actionItemStatusId}
                                                        onDragStart=""
                                                        bgcolor={item.color}
                                                      />
                                                    ) : null
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )
                                  )}
                            </div>


                            <div className="backlogs">
                              <div
                                className="row"
                               // onDragStart={(e) => handleDragStartt(e, item)}
                                onDragOver={(e) => handleDragOver(e)}
                              >
                                <div className="col-12" style={{ paddingRight: 0 }}>
                                  <div className="sprint-header-bar" style={{ marginLeft: "7px" }}>
                                    <div className="sprint-detail-initial">
                                      <div className="backlog name">Backlog</div>

                                      <div className="sprint issue-count">
                                        {list1.length}{" "}
                                        {list1.length !== 1 ? "issues" : "issue"}
                                      </div>
                                    </div>

                                    <div className="sprint-detail-end">
                                      <div>
                                        <div>
                                          <button
                                            onClick={openCreateSprintModal}
                                            className="create-sprint"
                                            style={{ marginRight: "28px" }}
                                          >
                                            Add Sprint
                                          </button>

                                          {/* <CreateSprint
                                            isOpen={showCreateSprintModal}
                                            closeModal={closeCreateSprintModal}
                                            cancelClick={CancelCreateSprintModal}
                                            projectId={projectId}
                                            name={"Create Sprint"}
                                            sprintInputName={sprintInputName}
                                            sprintID={sprintitemid}
                                            sprintActionItemCount={
                                              sprintActionItemCount
                                            }
                                            userID={loggedInUserID}
                                            isSprintEdit={isSprintEditOrStart}
                                          /> */}
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div>
                                    <div className="accordion-body1 backlog">
                                      {searcheExist &&
                                        (filterClicked ? [] : filterRecentEmpty && filteredItems.length === 0 ? [] : filteredItems.length > 0
                                          ? listItems
                                          : list1
                                        ).map((item, index) => (
                                          <Card
                                            lastColumn={null}
                                            key={filteredItems ? index : item.id}
                                            item={item}
                                            isDisabled={false}
                                            tasktypepath={
                                              ACTION_ITEM_TYPE[item.actionItemTypeID]
                                            }
                                            projectId={pageParams.projectId}
                                            prioritypath={TASK_PRIO[item.priorityId]}
                                            prioritytitle={
                                              PriorityListTitle[item.priorityId]
                                            }
                                            tasktitle={
                                              ACTION_ITEM_TYPE_TITLE[
                                              item.actionItemTypeID
                                              ]
                                            }
                                            assigneeFirstName={item.assigneeFirstName}
                                            assigneeLastName={item.assigneeLastName}
                                            onDragStart={handleDragStart}
                                            bgcolor={item.color}
                                          />
                                        ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          id="panelsStayOpen-collapseOne"
                          className="accordion-collapse backlog collapse show"
                        ></div>
                      </div>
                      <div className="">
                      </div>



                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      {showCreateSprintModal && (<CreateSprint
        isOpen={showCreateSprintModal}
        closeModal={closeCreateSprintModal}
        cancelClick={CancelCreateSprintModal}
        projectId={projectId}
        name={"Create Sprint"}
        sprintInputName={sprintInputName}
        sprintID={sprintitemid}
        sprintActionItemCount={
          sprintActionItemCount
        }
        userID={loggedInUserID}
        isSprintEdit={isSprintEditOrStart}
      />)}



      {showStartStartSprintModal && (
        <CreateSprint
          key={sprintitemid} // Unique key based on item properties
          isOpen={showStartStartSprintModal}
          closeModal={closeStartSprintModal}
          cancelClick={CancelStartSprintModal}
          name={"Start Sprint"}
          sprintInputName={sprintInputName}
          projectId={projectId}
          sprintID={sprintitemid}
          userID={GetLoggedInUserID()}
          sprintActionItemCount={sprintActionItemCount}
          sprintStartDate={sprintStartDate}
          sprintEndDate={sprintEndDate}
          isSprintEdit={isSprintEditOrStart}
        />
      )}
    </>
  );
};
export default SprintOverview;
