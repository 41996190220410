import React, { useEffect, useContext, useState } from "react";
import { ProjectService } from "../WebApiServices/Project.service";
import Select, { components } from "react-select";
import ErrorField from "../Components/ErrorField";
import {
  GetLocalStorageData,
  isStringEmpty,
  GetLoggedInUserID,
  RemoveLocalStorageData,
  AddLocalStorageData,
  changePageTitle,
} from "../Helpers/Utilities";
import {
  CommonErrorMessages,
  ProjectErrorMessage,
  ToastMessages,
} from "../Helpers/HelperText";
import { ColorPicker } from "primereact/colorpicker";
import DatePicker from "react-datepicker";
import { useLocation, useNavigate } from "react-router-dom";
import BreadcrumbComponent from "../Components/BreadcrumbComponent";
import { AppContext, useUIContext } from "../Contexts/AppContextProvider";
import {
  HTTPResponse,
  MaxCharacterCount,
  ROLE_TYPE,
  ToastMessageType,
} from "../Helpers/Enums";
import { SprintOverviewService } from "../WebApiServices/SprintOverview.service";
import CheckboxDropdown from "../Components/CheckBoxDropdown";
import AssignEmployeeModal from "../Components/AssignEmployeeModal";
import DeleteModal from "../Components/DeleteModal";
import LogOutModal from "../Components/LogOutModal";
import ErrorPage from "./ErrorPage";
import { Link } from 'react-router-dom';

const AddProject = () => {
  /* <summary>
date: 04-11-2023
Name: MS
description: This component represents the 'Add Project' page and 'edit Project' details,
<summary>*/
  const { LogOut } = useUIContext();
  const { ToggleLoader } = useContext(AppContext);
  const [leadName, setLeadName] = useState([]);
  const [selectedLead, setSelectedLead] = useState();
  const location = useLocation();
  const [existingProjects, setExistingProjects] = useState("");
  const [existingkey, setExistingkey] = useState("");

  //Delete Modal
  const [showDeleteModalPopUp, setShowDeleteModal] = useState(false);
  const [deleteType, setDeleteType] = useState("");
  const [swimlaneIndex, setSwimlaneIndex] = useState("");
  const [swimlaneActionItemID, setSwimlaneActionItemID] = useState("");
  const [swimlaneProjectID, setSwimlaneProjectID] = useState("");

  const [releaseItemIndex, setReleaseItemIndex] = useState("");
  const [categorItemIndex, setCategoryItemIndex] = useState("");

  const [isEmployeeLoading, setEmployeeLoading] = useState(false);
  const [categoryId, setCategoryId] = useState(null);//MS to send id of deleting category row

  const [projectIdWhileAdd, SetProjectIdWhileAdd] = useState();
  const [addStyle, setStyle] = useState(true);

  // const AdProjectItems = [];

  useEffect(() => {
    const existingProjectsFromState =
      location.state && location.state.existingProjects;

    if (Array.isArray(existingProjectsFromState)) {
      setExistingProjects(existingProjectsFromState);
    }

    const existingKeyFromState = location.state && location.state.Key;

    if (Array.isArray(existingKeyFromState)) {
      setExistingkey(existingKeyFromState);
    }
  }, []);


  let projectData = GetLocalStorageData("ProjectAddDetails");

  let projectDetails =
    projectData != undefined && projectData != null
      ? projectData
      : location.state && location.state.projectDetails
        ? location.state.projectDetails
        : null;
  const navigate = useNavigate();

  let projectId =
    projectDetails != null && projectDetails?.id > 0
      ? projectDetails?.id
      : projectDetails?.id ?? 0;
  const [items, setItems] = useState([
    { name: "To Do", color: "#FFDC68", isAdded: true, error: false, fontColor: "#AAF7B2" },
    { name: "In Progress", color: "#E9EB7C", isAdded: true, error: false, fontColor: "#AAF7B2" },
    { name: "Testing", color: "#A1E6E3", isAdded: true, error: false, fontColor: "#AAF7B2" },
    { name: "Done", color: "#AAF7B2", isAdded: true, error: false, fontColor: "#AAF7B2" },
  ]);

  const [releasesItems, setReleasesItems] = useState([
    {
      name: "",
      startDate: null,
      endDate: null,
      sprints: "",
      status: 2,
      releaseId: 0,
      releaseStatusId: 1,
      projectId: 0,
    },
  ]);
  const [newReleaseItem, setNewReleaseItem] = useState({
    name: "",
    startDate: null,
    endDate: null,
    sprints: "",
    status: false,
  });

  const [phaseItems, setPhaseItems] = useState([
    {
      name: "",
      estimates: 0,
      releaseId: "",
      releasesIdAndName: [],
      releases: [],
      projectId: "",
      projectPhaseId: null,
    },
  ]);

  const [newPhaseItem, setNewPhaseItem] = useState({
    name: "",
    estimates: 0,
    releases: "",
  });

  const [categoryItem, setCategoryItem] = useState([
    {
      projectCategoryId: null,
      categoryName: "",
      defaultAssignee: 0,
      linkedWorkedItem: 0,

    },
  ]);

  const [newCategoryItem, setNewCategoryItem] = useState([
    {
      categoryName: "",
      defaultAssignee: 0,
      linkedWorkedItem: 0,

    },
  ]);

  const [draggedItem, setDraggedItem] = useState(null);
  const [newItemName, setNewItemName] = useState("");

  const { ShowToastMessage } = useContext(AppContext);

  const [totalActionItemStatusCount, setTotalActionItemStatusCount] =
    useState(0);
  const [project, setProject] = useState({
    projectName: "",
    clientName: "",
    leadId: null,
    key: "",
    createdBy: "",
    editedBy: "",
  });

  const [error, setError] = useState({
    projectName: false,
    projectNamExists: false,
    projectKeyExists: false,

    clientName: false,
    leadId: false,
    key: false,
    newItem: false,
    names: false,
    nameErrors: false && [],

    startDateErrors: false && [],
    endDateErrors: false && [],
    phaseNames: false,
    phaseNameErrors: false && [],

    categoryNameError: false && [],

    phaseEstimates: false,
    phaseEstimatesError: false && [],

    phaseSameNames: false,
    releaseSameNames: false,

    //category:false
  });

  const [openModal, setOpenModal] = useState(false);
  const handleOpenEmployeeAssignModal = () => {
    setOpenModal(true);
  };
  const handleCloseEmployeeAssignModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    changePageTitle(projectId > 0 ? "Edit Project" : "Add Project");
    GetActionItemsStatusNames();
  }, []);
  const [load, setLoad] = useState(true);

  useEffect(() => {
    getReleasesAndCategoryData();
    getAllLeadName();
    getDefaultAssignee();
  }, []);

  const getAllLeadName = async () => {
    try {
      const response = await ProjectService.GetLeadNames();
      
      if (response.status === HTTPResponse.OK) {
        let projectData = GetLocalStorageData("ProjectAddDetails");
        if (projectDetails != null) {
          let projectDetails =
            projectData != undefined && projectData != null
              ? projectData
              : location.state && location.state.projectDetails
                ? location.state.projectDetails
                : null;
          const leadData = response.data.value;
          const selectedLeadOption = leadData.find(
            (lead) => lead.id === projectDetails.leadID
          );
          const leadSelected = selectedLeadOption !== undefined ? selectedLeadOption.id : 0;
          const updatedLeadData = leadData.map(lead => {
            if (leadSelected !== 0 && lead.id === leadSelected) {
              return { ...lead, isChecked: true };
            } else {
              return { ...lead, isChecked: false }; // Uncheck other leads
            }
          });
          setLeadName(updatedLeadData);
          if (selectedLeadOption) {
            setSelectedLead({
              value: selectedLeadOption.id,
              label: selectedLeadOption.name,
            });
          }
        }
        else {
          const leadData = response.data.value;
          setLeadName(leadData);
        }
      } else {
        ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
      }
    } catch (e) {
      ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
    }
  }


  useEffect(() => {
    if (projectDetails != null) {
      setProject({
        projectName: projectDetails.name,
        clientName: projectDetails.clientName,
        leadId:
          projectDetails.projectLead > 0
            ? projectDetails.projectLead
            : projectDetails.leadId,
        key: projectDetails.key,
        id:
          projectDetails.id > 0 ? projectDetails.id : projectDetails.projectId,
      });
    }
  }, []);

  /* <summary>
date: 04-11-2023
Name: MS
description:Get swimlanes for specific project using projectId
<summary>*/

  const GetActionItemsStatusNames = async () => {
    try {
      const response = await ProjectService.GetActionItemStatusNames(projectId);

      if (response.status === HTTPResponse.OK) {
        const responseData = response.data;
        if (responseData.value.length === 0) {
          setItems(items);
        } else {
          setTotalActionItemStatusCount(responseData.value.length);
          const actionItemStatusData = responseData.value;

          const updatedItems = actionItemStatusData
            .map((status) => ({
              name: status.actionItemStatusName.trim(),
              actionItemStatusId: status.actionItemStatusId,
              color: status.colour,
              isAdded: false,
              orderIndex: status.orderIndex,
              error: false,
              fontColor: status.fontColor
            }))
            .sort((a, b) => a.orderIndex - b.orderIndex); // Sort by order index

          setItems(updatedItems);
        }
      } else {
        ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
      }
    } catch (e) {
      ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
    }
  };

  /* <summary>
  date: 10-11-2023
  Name: MS
  description:Get releases details for specific project using projectId
  <summary>*/

  const getReleasesAndCategoryData = async () => {
    try {
      let resp = await ProjectService.GetReleases(projectId);
      if (resp.status === HTTPResponse.OK) {
        const responseData = resp.data.releases;
        const responseDataCategory = resp.data.projectCategories
        const updatedItems = responseData.map((status) => ({
          name: status.name,
          startDate: status.startDate,
          endDate: status.endDate,
          sprints: status.sprints,
          status: status.releaseStatusId === 1 ? true : false,
          releaseId: status.releaseId,
        }));
        const updatedItemsForCategory = responseDataCategory.map((status) => ({
          // name: status.name,
          // startDate: status.startDate,
          // endDate: status.endDate,
          // sprints: status.sprints,
          // status: status.releaseStatusId === 1 ? true : false,
          // releaseId: status.releaseId,
          projectCategoryId: status.projectCategoryId,
          categoryName: status.categoryName,
          assigneeId: status.assigneeId,
          linkedWorkItem: status.linkedWorkedItems
        }));
        setCategoryItem(updatedItemsForCategory)
        setReleasesItems(updatedItems);

      } else {
        ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
      }
    } catch (e) {
      ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
    }
  };

  // This function is triggered when an item starts to be dragged.
  const handleDragStart = (index) => {
    setDraggedItem(index);
  };

  // This function is triggered when a dragged item is dragged over another item.
  const handleDragOver = (index) => {
    if (draggedItem === null || index === 0 || index === items.length - 1)
      return;

    const newItems = [...items];
    const itemToMove = newItems[draggedItem];
    newItems.splice(draggedItem, 1);
    newItems.splice(index, 0, itemToMove);
    setItems(newItems);
    setDraggedItem(index);
  };

  const handleDragEnd = () => {
    setDraggedItem(null);
    // Update the order/index of items in the state
    const newItems = items.map((item, index) => ({
      ...item,
      orderIndex: index,
    }));

    setItems(newItems);
  };

  /* <summary>
  date: 04-11-2023
  Name: Mustakim
  description:Changes the color of action Item status 
  <summary>*/
  const handleColorChange = (newColor, index) => {
    if (!newColor.startsWith("#")) {
      newColor = `#${newColor}`;
    }
    const newItems = [...items];
    newItems[index].color = newColor.toUpperCase();
    setItems(newItems);
  };
  /* <summary>
  date: 16-04-2024
  Name: Mustakim
  description: to chose font color in and display in active sprints column header.
  <summary>*/
  const handleFontColorChange = (newColor, index) => {
    if (!newColor.startsWith("#")) {
      newColor = `#${newColor}`;
    }
    // const newItems = items.map(item => ({
    //   ...item,
    //   fontColor: newColor.toUpperCase()
    // }));
    // setItems(newItems);
    setItems((prevItems) =>
      prevItems.map((item, i) =>
        i === index
          ? { ...item, fontColor: newColor.toUpperCase() }
          : item
      )
    );

  }

  const handleNewInputFields = () => {
    const newItems = [
      ...items.slice(0, items.length - 2),
      {
        name: newItemName,
        color: "#684e85",
        isAdded: true,
        error: false,
        orderIndex: items.length,
        fontColor: "#684e85"
      },
      ...items.slice(items.length - 2),
    ];

    setItems(newItems);
  };

  const handleNewReleasesInputs = () => {
    setReleasesItems((prevItems) => [
      ...prevItems,
      {
        name: newReleaseItem.name,
        startDate: null,
        endDate: null,
        sprints: newReleaseItem.sprints,
        status: newReleaseItem.status,
      },
    ]);

    setError((prev) => ({
      ...prev,
      nameErrors: false,
      startDateErrors: false,
      endDateErrors: false,
    }));
  };

  const handleNewPhaseInputs = () => {
    setPhaseItems((prevItem) => [
      ...prevItem,
      {
        name: newPhaseItem.name,
        estimates: newPhaseItem.estimates,
      },
    ]);
    setError((prev) => ({
      ...prev,
      phaseNames: false,
      phaseNameErrors: false,

      phaseEstimates: false,
      phaseEstimatesError: false,
    }));
  };

  const handleNewCategoryInputs = () => {
    setCategoryItem((prevItems) => [
      ...prevItems,
      {
        category: "",
        defaultAssignee: 0,
        linkedWorkedItem: 0,

      },
    ]);

    setError((prev) => ({
      ...prev,
      categoryNameError: false,
      //startDateErrors: false,
      //endDateErrors: false,
    }));
  };
  /* <summary>
  date: 04-11-2023
  Name: MS
  description:Validates input fields in the page
  <summary>*/
  const ValidateForm = () => {
    let result = true;

    const { projectName } = project;
    const { clientName } = project;
    const { leadId } = project;
    const { key } = project;

    const nameErrors = releasesItems.map((item, index) => ({
      index: index,
      name: item.name,
      error: isStringEmpty(item.name),
    }));
    const anyNameEmpty = nameErrors.some((item) => item.error);
    const phaseNameErrors = phaseItems.map((item, index) => ({
      index: index,
      name: item.name,
      error: isStringEmpty(item.name),
    }));
    const anyPhaseNameEmpty = phaseNameErrors.some((item) => item.error);
    const phaseEstimatesError = phaseItems.map((item, index) => ({
      index: index,
      name: item.estimates,
      error:
        item.estimates == 0 || isStringEmpty(String(item.estimates))
          ? true
          : false,
    }));

    const anyPhaseEstimateEmpty = phaseEstimatesError.some(
      (item) => item.error
    );

    const categoryNameErrors = categoryItem.map((item, index) => ({
      index: index,
      name: item.categoryName,
      error: isStringEmpty(item.categoryName),
    }));
    const anyCategoryNameEmpty = categoryNameErrors.some((item) => item.error);



    let swimlaneData = [...items];
    if (swimlaneData.length > 0) {
      swimlaneData.forEach((x) => {
        if (isStringEmpty(x.name.toString())) {
          x.error = true;
          result = false;
        }
        return x;
      });
    }

    //SYJ - fix for multiple error msg shown
    if (
      items.some(
        (item) =>
          item.isAdded &&
          items.filter(
            (i) =>
              !isStringEmpty(i.name) &&
              i.name.trim().toLocaleLowerCase() ===
              item.name.trim().toLocaleLowerCase()
          ).length > 1
      )
    ) {
      setError((prev) => ({
        ...prev,
        newItem: true, // Set duplicateName error
      }));
      result = false;
    } else {
      setError((prev) => ({
        ...prev,
        newItem: false, // Set duplicateName error
      }));
    }

    if (
      phaseItems.some(
        (item) =>
          phaseItems.filter(
            (i) =>
              !isStringEmpty(i.name) &&
              i.name.trim().toLocaleLowerCase() ===
              item.name.trim().toLocaleLowerCase()
          ).length > 1
      )
    ) {
      setError((prev) => ({
        ...prev,
        phaseSameNames: true, // Set duplicatePhaseName error
      }));
      result = false;
    } else {
      setError((prev) => ({
        ...prev,
        phaseSameNames: false, // Set duplicatePhaseName error
      }));
    }

    if (
      releasesItems.some(
        (item) =>
          releasesItems.filter(
            (i) =>
              !isStringEmpty(i.name) &&
              i.name.trim().toLocaleLowerCase() ===
              item.name.trim().toLocaleLowerCase()
          ).length > 1
      )
    ) {
      setError((prev) => ({
        ...prev,
        releaseSameNames: true, // Set duplicatePhaseName error
      }));
      result = false;
    } else {
      setError((prev) => ({
        ...prev,
        releaseSameNames: false, // Set duplicatePhaseName error
      }));
    }

    // const categoryError = categoryItem.map((item, index) => ({
    //   index: index,
    //   name: item.category,
    //   error: isStringEmpty(item.category),
    // }));
    // const anyCategoryNameEmpty = categoryError.some((item) => item.error);

    // if (
    //   categoryItem.some(
    //     (item) =>
    //     categoryItem.filter(
    //         (i) =>
    //           !isStringEmpty(i.name) &&
    //           i.name.trim().toLocaleLowerCase() ===
    //           item.name.trim().toLocaleLowerCase()
    //       ).length > 1
    //   )
    // ) {
    //   setError((prev) => ({
    //     ...prev,
    //     category: true, // Set duplicatePhaseName error
    //   }));
    //   result = false;
    // } else {
    //   setError((prev) => ({
    //     ...prev,
    //     category: false, // Set duplicatePhaseName error
    //   }));
    // }
    //SYJ - Duplication Changes Done Depending on Id and Value check

    const isProjectNameDuplicate =
      Array.isArray(existingProjects) &&
      existingProjects.some(
        (value) =>
          typeof value === "object" &&
          value.name &&
          project &&
          project.projectName &&
          value.name.toLowerCase().trim() ===
          project.projectName.toLowerCase().trim() &&
          value.id !== project.id
      );

    const isProjectKeyDuplicate =
      Array.isArray(existingkey) &&
      existingkey.some(
        (value) =>
          typeof value === "object" &&
          value.name &&
          project &&
          project.key &&
          value.name.toLowerCase().trim() ===
          project.key.toLowerCase().trim() &&
          value.id !== project.id
      );

    setError((prev) => ({
      ...prev,
      projectName: isStringEmpty(projectName),
      projectNamExists: !isProjectNameDuplicate ? false : true,
      projectKeyExists: !isProjectKeyDuplicate ? false : true,
      clientName: isStringEmpty(clientName),
      key: isStringEmpty(key),
      leadId: leadId === "" || leadId === 0 || leadId === null,
      names: anyNameEmpty,
      nameErrors: nameErrors,
      phaseNames: anyPhaseNameEmpty,
      phaseNameErrors: phaseNameErrors,
      categoryNameError: categoryNameErrors,
      //category:anyCategoryNameEmpty,
      phaseEstimates: anyPhaseEstimateEmpty,
      phaseEstimatesError: phaseEstimatesError,
    }));
    if (
      isStringEmpty(projectName) ||
      isStringEmpty(clientName) ||
      isStringEmpty(key) ||
      leadId === "" ||
      leadId === 0 ||
      leadId === null ||
      anyNameEmpty ||
      anyCategoryNameEmpty ||
      anyPhaseNameEmpty ||
      anyPhaseEstimateEmpty ||
      isProjectNameDuplicate ||
      isProjectKeyDuplicate
    ) {
      result = false;
    }

    ToggleLoader(false);
    return result;
  };

  const handleInputClientChange = (e) => {
    const { name, value } = e.target;

    setProject({
      ...project,
      [name]: value,
    });
    setError((prev) => ({
      ...prev,
      [name]: false,
    }));
  };
  const [key, setKey] = useState("");
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    const newKey = value.substring(0, 6).toUpperCase();

    if (name === "projectName") {
      // Update projectName and key only if key input is not disabled
      if (!projectDetails) {
        setKey(newKey);
        setProject({
          ...project,
          [name]: value,
          key: newKey,
        });
      } else {
        // Only update projectName if key input is disabled
        setProject({
          ...project,
          [name]: value,
        });
      }
      setError((prev) => ({
        ...prev,
        [name]: false,
        key: false,
      }));
    } else {
      setKey(newKey);
      setProject({
        ...project,
        [name]: value,
        key: newKey,
      });
      setError((prev) => ({
        ...prev,
        [name]: false,
      }));
    }
  };


  /* <summary>
  date: 27-11-2023
  Name: MS
  description: to accept the updated dropdown data from assign employee modal to add project page .
  <summary>*/
  const [updatedEmployeeData, setUpdatedEmployeeData] = useState([]);

  const handleEmployeeUpdate = (updatedData) => {
    setUpdatedEmployeeData(updatedData);
  };

  /* <summary>
  date: 04-11-2023
  Name: MS
  description:Save function to add or edit project details and to save action item status
  SYJ - 
  <summary>*/
  // const handleSave = async () => {
  //   try {

  //     if (ValidateForm()) {
  //       ToggleLoader(true); // Show loader
  //       if (projectDetails != null) {
  //         const details = {
  //           name:
  //             project == null || project == undefined
  //               ? null
  //               : project.projectName,
  //           clientName:
  //             project == null || project == undefined
  //               ? null
  //               : project.clientName,

  //           leadId: selectedLead?.value || null,
  //           key: project == null || project == undefined ? null : project.key,
  //           id: project == null || project == undefined ? null : project.id,
  //           editedBy: GetLocalStorageData("userId"),
  //           EmployeeList: [],
  //         };

  //         const resp = await ProjectService.UpdateProjectDetails(details);
  //         let showToast = true;
  //         if (items.length !== 0) {
  //           const newItems = items.filter((item) => item.isAdded === true);

  //           const newDataToSend = items.map(
  //             ({ name, color, actionItemStatusId, isAdded }, index) => ({
  //               name,
  //               color,
  //               actionItemStatusId,
  //               projectId: projectDetails ? projectDetails.id : null,
  //               isAdded,
  //               orderIndex: index,
  //               createdBy: GetLocalStorageData("userId"),
  //               editedBy: GetLocalStorageData("userId"),
  //             })
  //           );

  //           let getProjData = await ProjectService.GetAllProjects();
  //           const currentProject = getProjData.data.find(
  //             (project) => project.id === resp.data
  //           );

  //           AddLocalStorageData("ProjectAddDetails", currentProject);


  //           const updateActionItemStatusResp =
  //             await ProjectService.UpdateActionItemStatusItems(newDataToSend);
  //           showToast =
  //             showToast &&
  //             updateActionItemStatusResp.status === HTTPResponse.OK;
  //           GetActionItemsStatusNames();
  //         }

  //         let dat = phaseItems;
  //         if (releasesItems.length !== 0) {
  //           const newReleasesDataArray = releasesItems.map((release) => ({
  //             name: release.name,
  //             startDate: release.startDate,
  //             endDate: release.endDate,
  //             createdBy: GetLocalStorageData("userId"),
  //             releaseId: release.releaseId,
  //             releaseStatusId: release.releaseStatusId,
  //             projectId: projectId,
  //           }));
  //           const updateReleasesResp = await ProjectService.UpdateReleasesItems(
  //             newReleasesDataArray
  //           );
  //           showToast =
  //             showToast && updateReleasesResp.status === HTTPResponse.OK;
  //         }
  //         if (phaseItems.length > 0) {
  //           const projectPhases = phaseItems.map((phase) => ({
  //             name: phase.name,
  //             estimate: phase.estimates,
  //             projectId: projectId,
  //             releaseId: phase.releases,
  //             projectPhaseId: phase.projectPhaseId,
  //             createdBy: GetLocalStorageData("userId"),
  //           }));
  //           const addPhasesResp =
  //             await ProjectService.AddPhasesForSpecificProject(projectPhases);
  //           showToast = showToast && addPhasesResp.status === HTTPResponse.OK;
  //         }
  //         if (showToast) {
  //           ShowToastMessage(ProjectErrorMessage.ProjectUpdate);
  //         } else {
  //           ShowToastMessage(ToastMessages.IssueOccured, ToastMessageType.Fail);
  //         }

  //         await ProjectService.AssignEmployeeToTheProject(updatedEmployeeData);
  //         RemoveLocalStorageData("newlySelectedEmployee");
  //         // setEmployeeLoading(true);
  //         getAllLeadName();
  //       } else {
  //         
  //         const newProject = {
  //           name: project.projectName,
  //           clientName: project.clientName,
  //           leadId: selectedLead ? selectedLead.value : "",
  //           key: project.key,
  //           createdBy: GetLocalStorageData("userId"),
  //         };
  //         
  //         const newReleasesDataArray = releasesItems.map((release) => ({
  //           name: release.name,
  //           startDate: release.startDate,
  //           endDate: release.endDate,
  //           createdBy: GetLocalStorageData("userId"),
  //           releaseId: release.releaseId,
  //         }));
  //         const newDataToSend = items.map(
  //           ({ name, color, actionItemStatusId, isAdded }, index) => ({
  //             name,
  //             color,
  //             actionItemStatusId,
  //             isAdded,
  //             orderIndex: index,
  //             createdBy: GetLocalStorageData("userId"),
  //           })
  //         );
  //         const projectPhases = phaseItems.map((phase) => ({
  //           name: phase.name,
  //           estimate: phase.estimates,
  //           releaseId: [],
  //           createdBy: GetLocalStorageData("userId"),
  //         }));
  //         const employeesAssigned = updatedEmployeeData.map((employee) => ({
  //           ...employee,
  //           createdBy: GetLocalStorageData("userId"),
  //         }));
  //         
  //         const response = await ProjectService.AddProjectAndActionItemStatus({
  //           project: newProject,
  //           actionItemStatusItems: newDataToSend,
  //           releases: newReleasesDataArray,
  //           assignEmployeeModels: employeesAssigned,
  //           phases: projectPhases,
  //         });
  //         projectId = response.data;

  //         SetProjectIdWhileAdd(response.data);
  //         AddLocalStorageData("ProjectId", response.data);

  //         let getProjData = await ProjectService.GetAllProjects();
  //         const currentProject = getProjData.data.find(
  //           (project) => project.id === response.data
  //         );

  //         AddLocalStorageData("ProjectAddDetails", currentProject);

  //         if (response.status === HTTPResponse.OK) {
  //           navigate("/EditProject", {
  //             state: {
  //               projectDetails: getProjData,

  //             },
  //           });
  //           ShowToastMessage(ProjectErrorMessage.ProjectAdd);
  //         } else {
  //           ShowToastMessage(ToastMessages.IssueOccured, ToastMessageType.Fail);
  //         }
  //       }
  //       getReleasesAndCategoryData();
  //       GetReleaseForProjectAndSpecificPhases();
  //       GetActionItemsStatusNames();
  //       ToggleLoader(false);
  //       // setEmployeeLoading(true);
  //       getAllLeadName();
  //     }
  //   } catch (error) {
  //     ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
  //   } finally {
  //     ToggleLoader(false); // Hide loader
  //   }
  // };

  const handleSave = async () => {
    try {
      if (ValidateForm()) {
        ToggleLoader(true); // Show loader

        const userId = GetLocalStorageData("userId");
        const editedBy = userId;

        let projectDataToSend = null;

        if (projectDetails != null) {
          // Update operation
          projectDataToSend = {
            project: {
              name: project?.projectName || null,
              clientName: project?.clientName || null,
              leadId: selectedLead?.value || null,
              key: project?.key || null,
              projectId: project?.id || null,
              editedBy: editedBy,
              EmployeeList: [],
            }
          };

          let showToast = true;
          if (items.length !== 0) {
            const newItems = items.filter((item) => item.isAdded === true);

            const newDataToSend = items.map(
              ({ name, color, actionItemStatusId, isAdded, fontColor }, index) => ({
                name,
                color,
                actionItemStatusId,
                projectId: projectDetails ? projectDetails.id : null,
                isAdded,
                fontColor,
                orderIndex: index,
                createdBy: GetLocalStorageData("userId"),
                editedBy: GetLocalStorageData("userId"),
              })
            );

            const updateActionItemStatusResp =
              await ProjectService.UpdateActionItemStatusItems(newDataToSend);
            showToast =
              showToast &&
              updateActionItemStatusResp.status === HTTPResponse.OK;
            GetActionItemsStatusNames();
          }

          let dat = phaseItems;
          if (releasesItems.length !== 0) {
            const newReleasesDataArray = releasesItems.map((release) => ({
              name: release.name,
              startDate: release.startDate,
              endDate: release.endDate,
              createdBy: GetLocalStorageData("userId"),
              releaseId: release.releaseId,
              releaseStatusId: release.releaseStatusId,
              projectId: projectId,
            }));

            const updateReleasesResp = await ProjectService.UpdateReleasesItems(
              newReleasesDataArray
            );
            showToast =
              showToast && updateReleasesResp.status === HTTPResponse.OK;
          }
          ////
          if (categoryItem.length !== 0) {
            const newCategoryDataArray = categoryItem.map((category) => ({
              projectId: projectId,
              projectCategoryId: category.projectCategoryId,
              categoryName: category.categoryName,
              assigneeId: category.assigneeId,
              isDeleted: false,
              //releaseId: [],
              EditedBy: userId,
            }));
           
            const updateCategoryResp = await ProjectService.UpdateProjectCategories(
              newCategoryDataArray
            );
            showToast =
              showToast && updateCategoryResp.status === HTTPResponse.OK;
          }


          if (phaseItems.length > 0) {
            const projectPhases = phaseItems.map((phase) => ({
              name: phase.name,
              estimate: phase.estimates,
              projectId: projectId,
              releaseId: phase.releases,
              projectPhaseId: phase.projectPhaseId,
              createdBy: GetLocalStorageData("userId"),
            }));
            const addPhasesResp =
              await ProjectService.AddPhasesForSpecificProject(projectPhases);
            showToast = showToast && addPhasesResp.status === HTTPResponse.OK;
          }
          if (showToast) {
            ShowToastMessage(ProjectErrorMessage.ProjectUpdate);
          } else {
            ShowToastMessage(ToastMessages.IssueOccured, ToastMessageType.Fail);
          }

          await ProjectService.AssignEmployeeToTheProject(updatedEmployeeData);

          RemoveLocalStorageData("newlySelectedEmployee");
          getAllLeadName();
        } else {
          // Add operation
          projectDataToSend = {
            project: {
              name: project?.projectName,
              clientName: project?.clientName,
              leadId: selectedLead ? selectedLead.value : "",
              key: project?.key,
              createdBy: userId,
            },
            actionItemStatusItems: items.map(({ name, color, actionItemStatusId, isAdded, fontColor }, index) => ({
              name,
              color,
              actionItemStatusId,
              isAdded,
              orderIndex: index,
              createdBy: userId,
              fontColor

            })),
            releases: releasesItems.map((release) => ({
              name: release.name,
              startDate: release.startDate,
              endDate: release.endDate,
              createdBy: userId,
              releaseId: release.releaseId,
            })),
            assignEmployeeModels: updatedEmployeeData.map((employee) => ({
              ...employee,
              createdBy: userId,
            })),
            phases: phaseItems.map((phase) => ({
              name: phase.name,
              estimate: phase.estimates,
              releaseId: [],
              createdBy: userId,
            })),
            category: categoryItem.map((cat) => ({
              categoryName: cat.categoryName,
              assigneeId: cat.defaultAssignee,
              //releaseId: [],
              createdBy: userId,
            })),
          };
        }

        const response = await ProjectService.AddProjectAndActionItemStatus(projectDataToSend);

        let getProjData = await ProjectService.GetAllProjects();
        const currentProject = getProjData.data.find(
          (project) => project.id === response.data
        );


        AddLocalStorageData("ProjectAddDetails", currentProject);

        if (projectDetails != null && response.status === HTTPResponse.OK) {
          ShowToastMessage(ProjectErrorMessage.ProjectUpdate);
        } else if (projectDetails == null && response.status === HTTPResponse.OK) {
          SetProjectIdWhileAdd(response.data);
          AddLocalStorageData("ProjectId", response.data);
          navigate("/EditProject", {
            state: {
              projectDetails: response.data,
            },
          });
          ShowToastMessage(ProjectErrorMessage.ProjectAdd);
        } else {
          ShowToastMessage(ToastMessages.IssueOccured, ToastMessageType.Fail);
        }

        getReleasesAndCategoryData();
        GetReleaseForProjectAndSpecificPhases();
        GetActionItemsStatusNames();
        ToggleLoader(false);
        getAllLeadName();
      }
    } catch (error) {
      ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
    } finally {
      ToggleLoader(false); // Hide loader
    }
  };


  const HandleDropdownChange = (e, id, drp) => {
    let test = e;

    if (id === "defaultAssignee") {
      const value = e.value;

      // setCategoryItem(prevState =>
      //   prevState.map(item =>
      //     item.projectCategoryId === drp.projectCategoryId
      //       ? { ...item, defaultAssignee: value }
      //       : item
      //   )
      // );
      const updatedItems = [...categoryItem];
      updatedItems[drp].assigneeId = value;
      setCategoryItem(updatedItems);
    }
    else {
      // setProject((prev) => ({
      //   ...prev,
      //   [id]: e,
      //   [drp]: drp == "leadId" ? e.value : Number(e.value),
      // }));
      // setError((prev) => ({
      //   ...prev,
      //   leadId: false,
      // }));
      if (e !== null) {
        setProject((prev) => ({
          ...prev,
          [id]: e,
          [drp]: drp == "leadId" ? e.value : Number(e.value),
        }));
        setError((prev) => ({
          ...prev,
          leadId: false,
        }));
      }
      else {
        setSelectedLead({}); setProject((prev) => ({
          ...prev,
          [id]: 0,
          [drp]: drp == "leadId" ? 0 : 0,
        }));
      }
    }

  };

  const handleExitAddProject = () => {
    ToggleLoader(true);
    navigate("/ManageProjects");
    ToggleLoader(false);
  };
  const handleItemNameChange = (event, index) => {
    const updatedItems = [...items];
    updatedItems[index].name = event.target.value;
    updatedItems[index].error = event.target.value.length > 0 ? false : true;
    setError((prev) => ({
      ...prev,
      newItem: false,
    }));
    setItems(updatedItems);
  };

  const handleReleaseNameChange = (event, index) => {
    const updatedItems = [...releasesItems];
    updatedItems[index].name = event.target.value;

    setReleasesItems(updatedItems);

    setError((prev) => ({
      ...prev,
      nameErrors: updatedItems.map((item) => ({
        error: isStringEmpty(item.name),
      })),
      releaseSameNames: false,
    }));
  };

  const handleStartDateChange = (date, index) => {
    const updatedItems = [...releasesItems];
    // const formattedDate = date ? date.toISOString() : null;
    //const formattedDate = new Date(date).toISOString();
    const incomingDate = new Date(date);
    const utcDate = new Date(incomingDate.getTime() - incomingDate.getTimezoneOffset() * 60000);
    const formattedDate = utcDate.toISOString();
    updatedItems[index].startDate = formattedDate;
    setReleasesItems(updatedItems);
    setError((prev) => ({
      ...prev,
      startDateErrors: updatedItems.map((item, i) => ({
        error: i == index && isStringEmpty(item.startDate),
      })),
    }));
  };

  const handleEndDateChange = (date, index) => {
    const updatedItems = [...releasesItems];
    // const formattedDate = date ? date.toISOString() : null;
    const incomingDate = new Date(date);
    const utcDate = new Date(incomingDate.getTime() - incomingDate.getTimezoneOffset() * 60000);
    const formattedDate = utcDate.toISOString();
    updatedItems[index].endDate = formattedDate;
    setReleasesItems(updatedItems);

    setError((prev) => ({
      ...prev,
      endDateErrors: updatedItems.map((item, i) => ({
        error: i == index && isStringEmpty(item.endDate),
      })),
    }));
  };

  ///phase table
  useEffect(() => {
    fetchReleasedata();
  }, [releasesItems]);
  const [storeSprintReleaseData, setStoreSprintReleaseData] = useState([]);
  const [filterOptions, setFilterOptions] = useState([])

  const [projectPhaseId, setProjectPhaseId] = useState([])


  const fetchReleasedata = async () => {
    try {
      const releaseData = await SprintOverviewService.GetSprintReleaseData(
        projectId
      );

      const filteredReleaseData = releaseData.data.filter(option => option.projectPhaseId === 0);


      setStoreSprintReleaseData(filteredReleaseData);
      if (releaseData.status === HTTPResponse.Unauthorized) {
        LogOut();
      }
    } catch (error) {
      ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
    }
  };

  // const handleFilterOptionsChange = (selectedOptions) => {

  //   // Update filterOptions
  //   setFilterOptions(selectedOptions);

  //   // Filter out the entries in storeSprintReleaseData that match the releaseIds in selectedOptions
  //   const updatedStoreSprintReleaseData = storeSprintReleaseData.filter(
  //     (option) => !selectedOptions.some((selected) => selected.releaseId === option.releaseId)
  //   );

  //   // Append incoming data to storeSprintReleaseData if it's not already present
  //   selectedOptions.forEach((selectedOption) => {
  //     if (!storeSprintReleaseData.some((option) => option.releaseId === selectedOption.releaseId)) {
  //       updatedStoreSprintReleaseData.push(selectedOption);
  //     }
  //   });
  //   const sortedReleaseOptions = updatedStoreSprintReleaseData.sort((a, b) => a.releaseId - b.releaseId);
  //   // Update storeSprintReleaseData
  //   setStoreSprintReleaseData(sortedReleaseOptions);
  // };

  useEffect(() => {
  }, [storeSprintReleaseData])
  useEffect(() => {
    GetReleaseForProjectAndSpecificPhases();
  }, []);

  const GetReleaseForProjectAndSpecificPhases = async () => {
    try {
      let resp = await ProjectService.GetReleaseForProjectAndSpecificPhase(
        projectId
      );
      if (resp.status === HTTPResponse.OK) {
        const responseData = resp.data;
        const projectPhaseIdArray = responseData.map((status) => ({
          name: status.phaseName,
          releaseId: status.releaseId,

          projectPhaseId: status.projectPhaseId,
        }));
        setProjectPhaseId([projectPhaseIdArray])
        const updatedItems = responseData.map((status) => ({
          name: status.phaseName,

          estimates: status.estimate,
          releaseId: status.releaseId,
          releasesIdAndName: status.releasesIdAndName,
          releases: status.releases,
          projectPhaseId: status.projectPhaseId,
        }));

        setPhaseItems(updatedItems);
      } else {
      }
    } catch (e) {
      ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
    }
  };

  // const onSelectChange = (dd, index) => {
  //   const releaseIds = dd.map((release) => release.releaseId);

  //   const updatedItems = [...phaseItems];
  //   updatedItems[index].releases = releaseIds;
  //   setPhaseItems(updatedItems);
  // };

  const onSelectChange = (selected, index) => {
    const updatedItems = [...phaseItems];
    const updatedSelection = selected
      ? selected.map((option) => ({
        releaseId: option.value,
        name: option.label,
      }))
      : [];

    // Determine removed options
    const removedOptions = updatedItems[index].releasesIdAndName !== undefined && updatedItems[index].releasesIdAndName.filter(
      (option) => !updatedSelection.some((selectedOption) => selectedOption.releaseId === option.releaseId)
    );

    // Filter out selected options from storeSprintReleaseData
    const newStoreSprintReleaseData = removedOptions ? storeSprintReleaseData
      .filter((option) => !updatedSelection.some((selectedOption) => selectedOption.releaseId === option.releaseId))
      .concat(removedOptions) : storeSprintReleaseData
      .filter((option) => !updatedSelection.some((selectedOption) => selectedOption.releaseId === option.releaseId));

    updatedItems[index].releasesIdAndName = updatedSelection;
    updatedItems[index].releases = updatedSelection.map((release) => release.releaseId);

    setPhaseItems(updatedItems);
    setStoreSprintReleaseData(newStoreSprintReleaseData);
  };

  const handlePhaseNameChange = (e, index) => {
    const updatedItems = [...phaseItems];
    updatedItems[index].name = e.target.value;

    setPhaseItems(updatedItems);
    setError((prev) => ({
      ...prev,
      phaseNameErrors: updatedItems.map((item) => ({
        error: isStringEmpty(item.name),
      })),
      phaseSameNames: false,
    }));
  };
  const handleEstimateChange = (e, index) => {
    const updatedItems = [...phaseItems];
    const newValue = e.target.value.substring(0, 5).toUpperCase();
    updatedItems[index].estimates = newValue;
    setPhaseItems(updatedItems);

    setError((prev) => ({
      ...prev,
      phaseEstimatesError: updatedItems.map((item) => ({
        error: isStringEmpty(item.estimates.toString()),
      })),
    }));
  };

  //Delete Modal

  /* <summary>
  date: 04-11-2023
  Name: MS
  description:Deleting action Item Status 
  <summary>*/
  const handleDeleteItem = (index, actionItemStatusId, projectId) => {
    setDeleteType(1);
    setSwimlaneIndex(index);
    setSwimlaneActionItemID(actionItemStatusId);
    setSwimlaneProjectID(projectId);
    setShowDeleteModal(true);
  };

  /* <summary>
  date: 04-12-2023
  Name: SYJ
  description: Delete modal handlers
  <summary>*/
  const OnDeleteModalYesClick = async () => {
    if (deleteType == 1) {
      const newItems = [...items];
      newItems.splice(swimlaneIndex, 1);
      setItems(newItems);
      ProjectService.DeleteActionItemStatus(
        swimlaneActionItemID,
        swimlaneProjectID
      );
    } else if (deleteType == 2) {
      const releaseItems = [...releasesItems];
      releaseItems.splice(releaseItemIndex, 1);
      setReleasesItems(releaseItems);
    } else if (deleteType == 3) {
      const phaseItemsList = [...phaseItems];
      phaseItemsList.splice(releaseItemIndex, 1);
      setPhaseItems(phaseItemsList);
    }
    else if (deleteType == "categoryDelete") {
      const categoryItemsList = [...categoryItem];
      categoryItemsList.splice(categorItemIndex, 1);
      setCategoryItem(categoryItemsList);

      if (categoryId != undefined) {
        const newCategoryDataArray = [
          {
            projectCategoryId: categoryId,
            isDeleted: true,
            editedBy: GetLocalStorageData("userId"),
          }
        ];
        const updateCategoryResp = await ProjectService.UpdateProjectCategories(newCategoryDataArray);
        if (updateCategoryResp.status != HTTPResponse.OK) {
          ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
        }
      }

    }
    setShowDeleteModal(false);
  };

  const OnCloseDeleteModal = () => {
    setSwimlaneIndex("");
    setSwimlaneActionItemID("");
    setSwimlaneProjectID("");
    setShowDeleteModal(false);
  };

  const handleDeleteReleaseItem = (index) => {
    setReleaseItemIndex(index);
    setDeleteType(2);
    setShowDeleteModal(true);
  };

  const handleDeletePhaseItem = (index) => {
    setReleaseItemIndex(index);
    setDeleteType(3);
    setShowDeleteModal(true);
  };
  /* <summary>
  date: 09-07-2024
  Name: MS
  description: Delete data from category table
  <summary>*/
  const handleDeleteCategory = (index, id) => {
    //setReleaseItemIndex(index);
    setCategoryItemIndex(index)
    setCategoryId(id)
    setDeleteType("categoryDelete");
    setShowDeleteModal(true);
  };
  const projectName = GetLocalStorageData("projectName");
  const roleId = Number(GetLocalStorageData("roleID"));

  /* <summary>
  date: 08-07-2024
  Name: MS
  description: 
  <summary>*/
  const [assigneeList, setAssigneeList] = useState([]);
  const getDefaultAssignee = async () => {
    let assignedRes = await ProjectService.GetAssignedEmployees(projectId, false);
    if (
      assignedRes.status === HTTPResponse.OK) {

      const employees = assignedRes?.data?.map((t) => ({
        value: t.employeeId,
        roleId: t.roleId,
        label: t.fullName,
        imagePath: t.imagePath,
      }));
      setAssigneeList(employees);
    }
  }

  const handleCategoryNameChange = (event, index) => {
    const updatedItems = [...categoryItem];
    updatedItems[index].categoryName = event.target.value;

    setCategoryItem(updatedItems);
    setError((prev) => ({
      ...prev,
      categoryNameError: updatedItems.map((item) => ({
        error: isStringEmpty(item.categoryName),
      })),
      //releaseSameNames: false,
    }));
    // setError((prev) => ({
    //   ...prev,
    //   category: updatedItems.map((item) => ({
    //     error: isStringEmpty(item.category),
    //   })),
    //   //releaseSameNames: false,
    // }));
  };
  // const handleDeleteCategory=async()=>{
  //   // const newCategoryDataArray = categoryItem.map((category) => ({
  //   //   projectCategoryId:category.projectCategoryId,
  //   //   //categoryName: category.categoryName,
  //   //   //assigneeId: category.assigneeId,
  //   //   isDeleted:true,
  //   //   //releaseId: [],
  //   //   EditedBy: GetLocalStorageData("userId"),
  //   // }));

  //   // const updateCategoryResp = await ProjectService.UpdateProjectCategories(
  //   //   newCategoryDataArray
  //   // );
  //   setDeleteType("categoryDelete");
  //   //setSwimlaneIndex(index);
  //   //setSwimlaneActionItemID(actionItemStatusId);
  //   //setSwimlaneProjectID(projectId);
  //   setShowDeleteModal(true);
  // }
  const styles = {
    control: (base) => ({
      ...base,
      borderBottom: "2px solid #483567",
      innerWidth: 15,
      innerHeight: 15,
      // backgroundColor: "#ebe6f5",
      borderColor: "#483567",
      color: "#483567!Important",
      border: "none",
      zIndex: "9",
      backgroundColor: '#fff',
      borderRadius: "0"
    }),
  };



  const CheckboxOption = (props) => (
    <components.Option {...props}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
          style={{ marginRight: "10px" }}
        />
        <label>{props.label}</label>
      </div>
    </components.Option>
  );
  const navigateToOverViewPage=(id)=>{
    
    //navigate( `/Overview/${projectId}`),{state:{categoryValue:name}}
    navigate(`/Overview/${projectId}`, { state: { categoryValue: id } });

  }
  return (
    <>
      {roleId === ROLE_TYPE.ADMIN || roleId === ROLE_TYPE.LEAD ?
        (
          <>
            {openModal && (
              <AssignEmployeeModal
                closeModal={handleCloseEmployeeAssignModal}
                projectIds={projectId}
                allEmployees={leadName}
                onEmployeeUpdate={handleEmployeeUpdate}
              />
            )}

            {showDeleteModalPopUp && (
              <DeleteModal
                showDeleteModal={showDeleteModalPopUp}
                onYesClick={OnDeleteModalYesClick}
                onNoClick={OnCloseDeleteModal}
                textToDisplay={"Are you sure you want to delete?"}
              />
            )}
            <title>{projectId > 0 ? "Edit Project" : "Add Project"}</title>

            <section className="breadcrumb-block">
              <BreadcrumbComponent projectName={projectName} />
              <div className="container">
                <div className="nav-breadcrumb"></div>
              </div>
            </section>
            <section className="title-block" style={{ backgroundColor: "#f5f5f5" }}>
              <div className="container-fluid">
                <div className="title-f-box">
                  <div className="title-banner">
                    <h3 className="page-title">
                      {/* {projectId > 0 ? "Edit Project" : "Add Project"} */}
                    </h3>
                  </div>
                  <div className="save-cancel-divv">
                    <button
                      className="save-project-btn"
                      onClick={handleOpenEmployeeAssignModal}
                      style={{ border: "2px solid #332150" }}
                    >
                      Assign Employee
                    </button>
                    <button
                      className="save-project-btn save-resp-project-btn custom-width"
                      onClick={handleSave}
                      style={{ border: "2px solid #332150" }}
                    >
                      Save
                    </button>
                    <button
                      className="cancel-project-btn cancel-resp-project-btn custom-width"
                      onClick={handleExitAddProject}
                    >
                      {" "}
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </section>

            <main id="add-project" className="main-body-content-project">
              <section className="add-project">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12 col-md-12 col-lg-5">
                      <label htmlFor="">
                        Project: <span className="isRequiredField">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Project"
                        name="projectName"
                        maxLength={MaxCharacterCount.projectName}
                        value={project.projectName}
                        onChange={handleInputChange}
                      />
                      <ErrorField
                        show={error.projectName}
                        message={CommonErrorMessages.NewProject}
                      />
                      <ErrorField
                        show={error.projectNamExists}
                        message={CommonErrorMessages.DuplicateName}
                      />
                    </div>
                    <div className="col-12 col-md-12 col-lg-7">
                      <div className="row">
                        <div className="col-lg-5">
                          <label htmlFor="">
                            Client Name: <span className="isRequiredField">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="clientName"
                            placeholder="Client Name"
                            maxLength={MaxCharacterCount.projectClientName}
                            value={project.clientName}
                            onChange={handleInputClientChange}
                          />
                          <ErrorField
                            show={error.clientName}
                            message={CommonErrorMessages.ClientName}
                          />
                        </div>
                        <div className="col-lg-4">
                          <label htmlFor="">
                            Project Lead: <span className="isRequiredField">*</span>
                          </label>
                          <Select
                            inputId="leadDropDown"
                            placeholder="Please Select"
                            value={selectedLead}
                            options={leadName.map((lead) => ({
                              value: lead.id,
                              label: lead.name,
                            }))}
                            onChange={(e) => {
                              setSelectedLead(e);
                              HandleDropdownChange(e, "leadDropDown", "leadId");
                            }}
                            isClearable={!selectedLead === null || selectedLead?.value > 0}
                          />
                          <ErrorField
                            show={error.leadId}
                            message={CommonErrorMessages.SelectLead}
                          />
                        </div>
                        <div className="col-lg-3">
                          <label htmlFor="">
                            Key: <span className="isRequiredField">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="key"
                            placeholder="Key"
                            value={project.key}
                            onChange={handleInputChange}
                            disabled={projectDetails}
                          />
                          <ErrorField
                            show={error.key}
                            message={CommonErrorMessages.Key}
                          />
                          <ErrorField
                            show={error.projectKeyExists}
                            message={CommonErrorMessages.DuplicateKey}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {roleId == ROLE_TYPE.ADMIN && (
                  <div className="container-fluid">
                    <div
                      className="innerBox"
                      style={{
                        overflowX: "auto",
                        borderRadius: "0px",
                        padding: "2px 21px 10px",
                      }}
                    >
                      <a
                        style={{
                          backgroundColor: "#ebe6f5",
                          fontFamily: "Lato-Bold",
                          color: "#684e85",
                          height: "10px",
                        }}
                      >
                        {" "}
                        Phases
                      </a>

                      <div className="boxContenn" style={{ marginTop: "8px" }}>
                        <table style={{ borderCollapse: "collapse", width: "100%" }}>
                          <thead
                            style={{
                              border: "1px solid #000",
                              textAlign: "center",
                              backgroundColor: "#684e85",
                              color: "white",
                              fontFamily: "Lato-Medium",
                            }}
                          >
                            <tr>
                              <th style={{ padding: "5px", width: "200px" }}>
                                Phase Name
                              </th>
                              <th style={{ padding: "5px", width: "200px" }}>
                                Estimates
                              </th>
                              <th
                                style={{
                                  padding: "5px",
                                  width: "800px",
                                }}
                              >
                                Releases{" "}
                              </th>
                              <th
                                style={{
                                  padding: "5px",
                                  width: "100px",
                                  textAlign: "left",
                                }}
                              ></th>
                            </tr>
                          </thead>

                          <tbody>
                            {phaseItems &&
                              phaseItems.map((item, index) => (
                                <tr key={index} style={{ alignItems: "center" }}>
                                  <td
                                    style={{
                                      borderBottom: "1px solid #000",
                                      textAlign: "center",
                                      position: "relative",
                                      height: "68px", alignContent: 'start'
                                    }}
                                  >
                                    {" "}
                                    <input
                                      style={{
                                        // marginTop: "32px",
                                        border: "1px solid #6c757d",
                                        padding: "0px 6px",
                                        width: "140px",
                                        height: "35px",
                                        top: "50%",
                                        borderRadius: " 0.25rem",
                                        // transform: "translateY(-50%)",
                                      }}
                                      type="text"
                                      value={item.name}
                                      name="phaseName"
                                      onChange={(e) => handlePhaseNameChange(e, index)}
                                      maxLength={MaxCharacterCount.PhaseAndRelease}
                                    />
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        width: "200px",
                                        marginBottom: '10px'
                                      }}
                                    >
                                      {error.phaseNameErrors && (
                                        <div
                                          style={{
                                            position: "absolute",
                                            bottom: "0",
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            marginBottom: "-2px",
                                            marginTop: "4px",
                                          }}
                                        >
                                          <ErrorField
                                            show={error.phaseNameErrors[index]?.error}
                                            message={CommonErrorMessages.PhaseName}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </td>
                                  <td
                                    style={{
                                      borderBottom: "1px solid #000",
                                      textAlign: "center",
                                      position: "relative",
                                      height: "68px", alignContent: 'start'
                                    }}
                                  >
                                    {" "}
                                    <div style={{ display: 'flex', alignItems: 'center' }}><input
                                      style={{
                                        border: "1px solid #6c757d",
                                        padding: "0px 6px",
                                        width: "106px",
                                        height: "35px",
                                        textAlign: 'center',
                                        top: "50%",
                                        borderRadius: " 0.25rem", marginRight: '5px'
                                      }}
                                      value={item.estimates}
                                      name="estimate"
                                      onInput={(e) => {
                                        e.target.value = e.target.value.replace(
                                          /[^0-9.]/g,
                                          ""
                                        );
                                      }}
                                      onChange={(e) => handleEstimateChange(e, index)}
                                    /><span>hours</span></div>{" "}
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        marginBottom: "0px",
                                        marginBottom: '10px'

                                      }}
                                    >
                                      {" "}
                                      {error.phaseEstimatesError && (
                                        <div
                                          style={{
                                            position: "absolute",
                                            bottom: "0",
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            marginBottom: "-2px",
                                          }}
                                        >
                                          <ErrorField
                                            show={
                                              error.phaseEstimatesError[index]?.error
                                            }
                                            message={CommonErrorMessages.PhaseEstimate}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </td>

                                  <td
                                    style={{
                                      borderBottom: "1px solid #000",
                                      textAlign: "center", alignContent: 'start', justifyContent: "center"
                                    }}
                                  >
                                    <div style={{ margin: "0px" }}>
                                      {/* <CheckboxDropdown
                                        optionsFilter={setFilterOptions}
                                        onChanges={handleFilterOptionsChange}
                                        isFromEmployee={false}
                                        AddStyle={addStyle}
                                        scroll={true}
                                        releaseChecked={item.releases}
                                        releasesSelected={item.releasesIdAndName}
                                        selectEmployee={false}
                                        options={storeSprintReleaseData}
                                        loading={() => setLoad(!load)}

                                        onChange={(dd) => {
                                          onSelectChange(dd, index);
                                        }}
                                        selectedReleases={getAllSelectedReleases()}
                                      /> */}

                                      <Select
                                        options={storeSprintReleaseData.map((option) => ({
                                          value: option.releaseId,
                                          label: option.name,
                                          isDisabled: option.isChecked,
                                        }))}
                                        onChange={(dd) => onSelectChange(dd, index)}
                                        isMulti
                                        value={item.releasesIdAndName !== undefined && item.releasesIdAndName.map((option) => ({
                                          value: option.releaseId,
                                          label: option.name,
                                          isDisabled: option.isChecked,
                                        }))}
                                        isSearchable
                                        isClearable
                                        placeholder="Please Select"
                                        components={{ Option: CheckboxOption }}
                                        hideSelectedOptions={false}
                                        closeMenuOnSelect={false}
                                        menuPortalTarget={document.body}
                                        menuPosition="fixed"
                                        styles={{
                                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        }}
                                      />
                                    </div>
                                  </td>
                                  {(item.projectPhaseId === 0 ||
                                    item.projectPhaseId === undefined) &&
                                    isStringEmpty(item.name) &&
                                    isStringEmpty(item.estimates) &&
                                    (item.releases == undefined ||
                                      item.releases.length === 0) ? (
                                    <td
                                      style={{
                                        borderBottom: "1px solid #000",
                                        padding: "5px",
                                        textAlign: "right",
                                        paddingRight: "15px",
                                      }}
                                    >
                                      <button
                                        onClick={() => handleDeletePhaseItem(index)}
                                        title="Delete"
                                      >
                                        <img
                                          src="Assets/images/deleteIcon.png"
                                          style={{ width: "24px", height: "26px" }}
                                        />
                                      </button>
                                    </td>
                                  ) : (
                                    <td
                                      style={{
                                        borderBottom: "1px solid #000",
                                        padding: "5px",
                                        textAlign: "right",
                                        paddingRight: "15px",
                                      }}
                                    ></td>
                                  )}
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                      <ErrorField
                        show={error.phaseSameNames}
                        message={CommonErrorMessages.DuplicatePhaseName}
                      />
                      <div className="showNewInputField">
                        <button
                          className="showNewInputField"
                          onClick={handleNewPhaseInputs}
                        >
                          <img
                            src="Assets/images/AddIcons.png"
                            style={{
                              width: "20px",
                              height: "20px",
                              marginRight: "4px",
                            }}
                          />{" "}
                          Add New Phase
                        </button>
                      </div>
                    </div>
                  </div>)}

                {(roleId == ROLE_TYPE.ADMIN || roleId == ROLE_TYPE.LEAD) && (
                  <div className="container-fluid">
                    <div
                      className="innerBox"
                      style={{
                        borderRadius: "0px",
                        padding: "2px 21px 10px",
                      }}
                    >
                      <a
                        style={{
                          backgroundColor: " #ebe6f5",
                          fontFamily: "Lato-Bold",
                          color: "#684e85",
                          height: "10px",
                        }}
                      >
                        {" "}
                        Releases
                      </a>

                      <div className="boxContenn" style={{ marginTop: "8px" }}>
                        <table style={{ borderCollapse: "collapse", width: "100%" }}>
                          <thead
                            style={{
                              border: "1px solid #000",
                              textAlign: "center",
                              backgroundColor: "#684e85",
                              color: "white",
                              fontFamily: "Lato-Medium",
                            }}
                          >
                            <tr>
                              <th style={{ padding: "5px", width: "200px" }}>
                                Release Name
                              </th>
                              <th style={{ padding: "5px", width: "200px" }}>
                                Start Date
                              </th>
                              <th style={{ padding: "5px", width: "200px" }}>
                                End Date{" "}
                              </th>
                              <th style={{ padding: "5px", width: "260px" }}>
                                Sprints{" "}
                              </th>
                              <th style={{ padding: "5px", width: "200px" }}>
                                Status{" "}
                              </th>
                              <th style={{ padding: "5px", width: "20px" }}></th>
                            </tr>
                          </thead>

                          <tbody>
                            {releasesItems &&
                              releasesItems.map((item, index) => (
                                <tr key={index}>
                                  <td
                                    style={{
                                      borderBottom: "1px solid #000",
                                      textAlign: "center",
                                      position: "relative",
                                      height: "68px",
                                    }}
                                  >
                                    <input
                                      style={{
                                        border: "1px solid #6c757d",
                                        padding: "0px 6px",
                                        width: "140px",
                                        height: "35px",
                                        top: "50%",
                                        borderRadius: " 0.25rem",
                                      }}
                                      type="text"
                                      value={item.name}
                                      onChange={(e) =>
                                        handleReleaseNameChange(e, index)
                                      }
                                      maxLength={MaxCharacterCount.PhaseAndRelease}
                                    />
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      {error.nameErrors && (
                                        <div
                                          style={{
                                            position: "absolute",
                                            bottom: "0",
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            marginBottom: "-2px",
                                          }}
                                        >
                                          <ErrorField
                                            show={error.nameErrors[index]?.error}
                                            message={CommonErrorMessages.ReleaseName}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </td>
                                  <td
                                    style={{
                                      borderBottom: "1px solid #000",
                                      textAlign: "-webkit-center",
                                      height: "68px",
                                    }}
                                  >
                                    <div
                                      className="datePickeR"
                                      style={{
                                        border: "1px solid #6c757d",
                                        width: "145px",
                                        borderRadius: "0.25rem",
                                      }}
                                    >
                                      <label>

                                        <DatePicker
                                          id="startDate"
                                          style={{
                                            border: "1px solid #6c757d",
                                            padding: "0px 6px",
                                            width: "140px",
                                            height: "30px",
                                            top: "50%",
                                          }}
                                          minDate={new Date()}
                                          maxDate={
                                            item.endDate ? new Date(item.endDate) : null
                                          }
                                          selected={
                                            item.startDate
                                              ? new Date(item.startDate)
                                              : item.startDate
                                          }
                                          onChange={(date) =>
                                            handleStartDateChange(date, index)
                                          }
                                          onKeyDown={(e) => {
                                            e.preventDefault();
                                          }}
                                          placeholderText="dd-mm-yyyy"
                                          dateFormat="dd-MM-yyyy"
                                          showIcon={true}
                                          autoComplete="oFF"
                                          popperClassName="ag-custom-component-popup"
                                          scrollableYearDropdown={true}
                                          showYearDropdown
                                          showMonthDropdown
                                          todayButton="Today"
                                        />

                                      </label>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        position: "relative",
                                      }}
                                    >
                                      {" "}
                                      {error.startDateErrors && (
                                        <div
                                          style={{
                                            position: "absolute",
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            marginBottom: "-2px",
                                          }}
                                        >
                                          <ErrorField
                                            show={error.startDateErrors[index]?.error}
                                            message={CommonErrorMessages.StartDate}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </td>
                                  {/* sprint add */}
                                  <td
                                    style={{
                                      borderBottom: "1px solid #000",
                                      textAlign: "-webkit-center",
                                      height: "68px",
                                    }}
                                  >
                                    <div
                                      className="datePickeR"
                                      style={{
                                        border: "1px solid #6c757d",
                                        width: "145px",
                                        borderRadius: "0.25rem",
                                      }}
                                    >
                                      <label>
                                        <DatePicker
                                          id="endDate"
                                          style={{
                                            border: "1px solid #6c757d",
                                            padding: "0px 6px",
                                            width: "140px",
                                            height: "30px",
                                            top: "50%",
                                          }}
                                          minDate={new Date(item.startDate) || new Date()}
                                          selected={
                                            item.endDate
                                              ? new Date(item.endDate)
                                              : item.endDate
                                          }
                                          onChange={(date) =>
                                            handleEndDateChange(date, index)
                                          }
                                          placeholderText="dd-mm-yyyy"
                                          dateFormat="dd-MM-yyyy"
                                          showIcon={true}
                                          autoComplete="oFF"
                                          popperClassName="ag-custom-component-popup"
                                          scrollableYearDropdown={true}
                                          showYearDropdown
                                          showMonthDropdown
                                          todayButton="Today"
                                        />
                                      </label>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        position: "relative",
                                      }}
                                    >
                                      {error.endDateErrors && (
                                        <div
                                          style={{
                                            position: "absolute",
                                            display: "flex",
                                            justifyContent: "center",
                                            marginBottom: "-2px",
                                          }}
                                        >
                                          <ErrorField
                                            show={error.endDateErrors[index]?.error}
                                            message={CommonErrorMessages.EndDate}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </td>
                                  <td
                                    style={{
                                      borderBottom: "1px solid #000",
                                      textAlign: "center",
                                      position: "relative",
                                    }}
                                  >
                                    {Array.isArray(releasesItems[index].sprints) && (
                                      <ul className="sprint-list">
                                        {releasesItems[index].sprints.map(
                                          (sprint, sprintIndex) => (
                                            <li key={sprintIndex}>{sprint.name}</li>
                                          )
                                        )}
                                      </ul>
                                    )}
                                  </td>

                                  <td
                                    style={{
                                      borderBottom: "1px solid #000",
                                      textAlign: "center",
                                      position: "relative",
                                    }}
                                  >
                                    <ReleaseStatusCell
                                      projectId={projectId}
                                      key={item.releaseId}
                                      active={item.status}
                                      releaseId={item.releaseId}
                                      loading={() => null}
                                    ></ReleaseStatusCell>
                                  </td>

                                  {(item.releaseId === 0 ||
                                    item.releaseId === undefined) &&
                                    isStringEmpty(item.name) &&
                                    isStringEmpty(item.startDate) &&
                                    isStringEmpty(item.endDate) ? (
                                    <td
                                      style={{
                                        borderBottom: "1px solid #000",
                                        padding: "5px",
                                        textAlign: "right",
                                        paddingRight: "15px",
                                      }}
                                    >
                                      <button
                                        onClick={() => handleDeleteReleaseItem(index)}
                                        title="Delete"
                                      >
                                        <img
                                          src="Assets/images/deleteIcon.png"
                                          style={{ width: "24px", height: "26px" }}
                                        />
                                      </button>
                                    </td>
                                  ) : (
                                    <td
                                      style={{
                                        borderBottom: "1px solid #000",
                                        padding: "5px",
                                        textAlign: "right",
                                        paddingRight: "15px",
                                      }}
                                    ></td>
                                  )}
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>

                      <ErrorField
                        show={error.releaseSameNames}
                        message={CommonErrorMessages.DuplicateReleaseName}
                      />
                      <div className="showNewInputField">
                        <button
                          className="showNewInputField"
                          onClick={handleNewReleasesInputs}
                        >
                          <img
                            src="Assets/images/AddIcons.png"
                            style={{
                              width: "20px",
                              height: "20px",
                              marginRight: "4px",
                            }}
                          />{" "}
                          Add New Release
                        </button>
                      </div>
                    </div>
                  </div>)}

                {/* Phases table */}

                {/* {projectDetails && ( */}
                <div className="container-fluid">
                  <div
                    className="innerBox"
                    style={{
                      borderRadius: "0px",
                      padding: "2px 21px 10px",
                    }}
                  >
                    <a
                      style={{
                        backgroundColor: " #ebe6f5",
                        fontFamily: "Lato-Bold",
                        color: "#684e85",
                        height: "10px",
                      }}
                    >
                      {" "}
                      Swimlanes
                    </a>

                    <div className="boxContenn" style={{ marginTop: "8px" }}>
                      <table style={{ borderCollapse: "collapse", width: "100%" }}>
                        <thead
                          style={{
                            border: "1px solid #000",
                            padding: "5px",
                            textAlign: "center",
                            backgroundColor: "#684e85",
                            color: "white",
                            fontFamily: "Lato-Medium",
                          }}
                        >
                          <tr>
                            <th style={{ padding: "5px" }}>Swimlanes</th>
                            <th style={{ padding: "5px", textAlign: 'center' }}>Block Colors</th>
                            <th style={{ padding: "5px", textAlign: 'center' }}>Block text Color</th>
                            <th style={{ padding: "5px" }}> </th>
                          </tr>
                        </thead>

                        <tbody>
                          {items &&
                            items.map((item, index) => (
                              <tr
                                key={index}
                                draggable={index !== 0 && index !== items.length - 1}
                                onDragStart={() => handleDragStart(index)}
                                onDragOver={() => handleDragOver(index)}
                                onDragEnd={handleDragEnd}
                                className="drag-drop"
                              >
                                <td
                                  style={{
                                    borderBottom: "1px solid #000",
                                    textAlign: "center",
                                    cursor: "move",
                                  }}
                                >
                                  {item.isAdded ? (
                                    <input
                                      style={{ textAlign: "center" }}
                                      type="text"
                                      value={item.name}
                                      maxLength={MaxCharacterCount.swimlaneName}
                                      onChange={(e) => handleItemNameChange(e, index)}
                                    />
                                  ) : (
                                    <input
                                      style={{ textAlign: "center" }}
                                      type="text"
                                      value={item.name}
                                      maxLength={MaxCharacterCount.swimlaneName}
                                      onChange={(e) => handleItemNameChange(e, index)}
                                    />
                                  )}
                                  <br />
                                  <div
                                    style={{
                                      justifyContent: "center",
                                      display: "flex",
                                    }}
                                  >
                                    <ErrorField
                                      show={item.error}
                                      message={ProjectErrorMessage.NameEmpty}
                                    />
                                  </div>
                                </td>
                                <td
                                  style={{
                                    textAlign: 'center',
                                    borderBottom: "1px solid #000",
                                    padding: "5px",
                                    cursor: "pointer",
                                  }}
                                >
                                  <div
                                    style={{
                                      gap: "1rem",
                                      alignItems: "center",
                                      textAlign: "center",
                                      display: 'inline-block',
                                      marginRight: '5px'
                                    }}
                                    title="select color"
                                  >
                                    <ColorPicker
                                      value={item.color}
                                      onChange={(e) =>
                                        handleColorChange(e.value, index)
                                      }
                                    /></div>
                                  {/* <div style={{ display: 'inline-block' }}>
                              {index === 0 && (<ColorPicker
                                value={item.fontColor}
                                onChange={(e) =>
                                  handleFontColorChange(e.value, index)
                                }
                              />)}
                            </div> */}
                                </td>
                                <td
                                  style={{
                                    textAlign: 'center',
                                    borderBottom: "1px solid #000",
                                    padding: "5px",
                                    cursor: "pointer",
                                  }}
                                >
                                  <div
                                    style={{
                                      gap: "1rem",
                                      alignItems: "center",
                                      textAlign: "center",
                                      display: 'inline-block',
                                      justifyContent: "center",
                                      marginRight: '5px'
                                    }}
                                    title="Select text color"
                                  >

                                    <ColorPicker
                                      value={item.fontColor}
                                      onChange={(e) =>
                                        handleFontColorChange(e.value, index)
                                      }
                                    />

                                  </div>
                                </td>
                                <td
                                  style={{
                                    borderBottom: "1px solid #000",
                                    padding: "5px",
                                    textAlign: "right",
                                    paddingRight: "15px",
                                  }}
                                >
                                  {index > 0 && index < items.length - 1 && item.name !== "In Progress" && item.name !== "Testing" && (
                                    <button
                                      onClick={() =>
                                        handleDeleteItem(
                                          index,
                                          item.actionItemStatusId,
                                          projectId
                                        )
                                      }
                                      title="Delete"
                                    >
                                      <i
                                        className="fa fa-2x fa-trash"
                                        aria-hidden="true"
                                        style={{ color: "#e6072c" }}
                                        title="Delete"
                                      />
                                    </button>
                                  )}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>

                    <ErrorField
                      show={error.newItem}
                      message={CommonErrorMessages.DuplicateSwminName}
                    />

                    <div className="showNewInputField">
                      <button
                        className="showNewInputField"
                        onClick={handleNewInputFields}
                      >
                        <img
                          src="Assets/images/AddIcons.png"
                          style={{
                            width: "20px",
                            height: "20px",
                            marginRight: "4px",
                          }}
                        />{" "}
                        Add New Swimlane
                      </button>
                    </div>
                  </div>
                </div>








                <div className="container-fluid">
                  <div
                    className="innerBox"
                    style={{
                      borderRadius: "0px",
                      padding: "2px 21px 10px",
                    }}
                  >
                    <a
                      style={{
                        backgroundColor: " #ebe6f5",
                        fontFamily: "Lato-Bold",
                        color: "#684e85",
                        height: "10px",
                      }}
                    >
                      {" "}
                      Categories
                    </a>

                    <div className="boxContenn" style={{ marginTop: "8px" }}>
                      <table style={{ borderCollapse: "collapse", width: "100%" }}>
                        <thead
                          style={{
                            border: "1px solid #000",
                            padding: "5px",
                            textAlign: "center",
                            backgroundColor: "#684e85",
                            color: "white",
                            fontFamily: "Lato-Medium",
                          }}
                        >
                          <tr>
                            <th style={{ padding: "5px" }}>Category</th>
                            <th style={{ padding: "5px", textAlign: 'center' }}>Default Assignee</th>
                            <th style={{ padding: "5px", textAlign: 'center' }}>Linked Worked Items</th>
                            <th style={{ padding: "5px" }}> </th>
                          </tr>
                        </thead>

                        <tbody>
                          {categoryItem &&
                            categoryItem.map((item, index) => (
                              <tr
                                key={index}
                                //draggable={index !== 0 && index !== items.length - 1}
                                // onDragStart={() => handleDragStart(index)}
                                //onDragOver={() => handleDragOver(index)}
                                // onDragEnd={handleDragEnd}
                                className="drag-drop"
                              >
                                <td
                                  style={{
                                    borderBottom: "1px solid #000",
                                    textAlign: "center",
                                    //cursor: "move",
                                  }}
                                >
                                  {/* {item.isAdded ? (
                                    <input
                                      style={{ textAlign: "center" }}
                                      type="text"
                                      value={item.name}
                                      maxLength={MaxCharacterCount.swimlaneName}
                                      onChange={(e) => handleItemNameChange(e, index)}
                                    />
                                  ) : 
                                  ( */}
                                  <input className="category-width"
                                    style={{ textAlign: "center" }}
                                    type="text"
                                    value={item.categoryName}
                                    maxLength={MaxCharacterCount.Category}
                                    onChange={(e) => handleCategoryNameChange(e, index)}
                                  />
                                  {/* // )
                                  // } */}
                                  <br />
                                  {/* <div
                                    style={{
                                      justifyContent: "center",
                                      display: "flex",
                                    }}
                                  >
                                    <ErrorField
                                      show={error.categoryNameError}
                                      message={ProjectErrorMessage.NameEmpty}
                                    />
                                  </div> */}
                                  {error.categoryNameError && (
                                    <div
                                      style={{
                                        // position: "absolute",
                                        bottom: "0",
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "center",
                                        // marginBottom: "-2px",
                                      }}
                                    >
                                      <ErrorField
                                        show={error.categoryNameError[index]?.error}
                                        message={ProjectErrorMessage.CategoryName}
                                      />
                                    </div>
                                  )}
                                </td>
                                <td
                                  style={{
                                    borderBottom: "1px solid #000",
                                    textAlign: "center",

                                  }}
                                >
                                  <div
                                  // style={{
                                  //   gap: "1rem",
                                  //   alignItems: "center",
                                  //   textAlign: "center",
                                  //   display: 'inline-block',
                                  //   marginRight: '5px'
                                  // }}
                                  // title="select color"
                                  >

                                    <Select
                                      inputId="assignee"
                                      //styles={styles}
                                      placeholder="Please Select"
                                      value={assigneeList.find(a => a.value === item.assigneeId)}
                                      options={assigneeList}
                                      onChange={(e) =>
                                        HandleDropdownChange(e, "defaultAssignee", index)
                                      }
                                      formatOptionLabel={(p) => (
                                        <div>
                                          {p.imagePath && (
                                            <img
                                              width={"20px"}
                                              height={"20px"}
                                              src={p?.imagePath}
                                              alt=""
                                              style={{ marginRight: "3px" }}
                                            />
                                          )}
                                          <span>{p.label}</span>
                                        </div>
                                      )}
                                      menuPortalTarget={document.body} // Render the menu in the body to avoid overflow issues
                                      menuPosition="fixed" // Use fixed positioning for the menu
                                      styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }), // Ensure the menu is on top of other elements
                                      }}
                                    />
                                  </div>

                                </td>
                                <td
                                  style={{
                                    textAlign: 'center',
                                    borderBottom: "1px solid #000",
                                    padding: "5px",
                                    //  cursor: "pointer",
                                  }}
                                >
                                  {/* <div
                                  style={{
                                    gap: "1rem",
                                    alignItems: "center",
                                    textAlign: "center",
                                    display: 'inline-block',
                                    justifyContent: "center",
                                    marginRight: '5px'
                                  }}
                                  title="Select text color"
                                  >

                                  
                                    {item.linkedWorkItem ? item.linkedWorkItem : 0}

                                  </div> */}
                                                                  <div 
                                //onClick={()=>navigateToOverViewPage(item.projectCategoryId)}
                                onClick={item.linkedWorkItem ? () => navigateToOverViewPage(item.projectCategoryId) : null}

                                >
                                  {/* <span  style={ {color:"blue",textDecoration:'underline',cursor:'pointer'}}>{item.linkedWorkItem ? item.linkedWorkItem : 0}</span> */}
                                  <span style={item.linkedWorkItem ? { color: "blue", textDecoration: 'underline', cursor: 'pointer' } : {}}>
  {item.linkedWorkItem ? item.linkedWorkItem : 0}
</span>
                                   </div>  
                                </td>
                                <td
                                  style={{
                                    borderBottom: "1px solid #000",
                                    padding: "5px",
                                    textAlign: "right",
                                    paddingRight: "15px",
                                  }}
                                >
                                  {
                                    // index > 0 && index < items.length - 1 && item.name !== "In Progress" && item.name !== "Testing" && 
                                    (
                                      <button
                                        onClick={() =>
                                          handleDeleteCategory(
                                            index,
                                            item.projectCategoryId,
                                            //projectId
                                          )
                                        }
                                        title="Delete"
                                      >
                                        <i
                                          className="fa fa-2x fa-trash"
                                          aria-hidden="true"
                                          style={{ color: "#e6072c" }}
                                          title="Delete"
                                        />
                                      </button>
                                    )}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>

                    <ErrorField
                      show={error.newItem}
                      message={CommonErrorMessages.DuplicateSwminName}
                    />

                    <div className="showNewInputField">
                      <button
                        className="showNewInputField"
                        onClick={handleNewCategoryInputs}
                      >
                        <img
                          src="Assets/images/AddIcons.png"
                          style={{
                            width: "20px",
                            height: "20px",
                            marginRight: "4px",
                          }}
                        />{" "}
                        Add New Category
                      </button>
                    </div>
                  </div>
                </div>
              </section>
            </main>
          </>)
        : (<ErrorPage />)}
    </>
  );
};
const ReleaseStatusCell = ({ active, releaseId, projectId, loading }) => {
  const [status, setStatus] = useState(active);
  const userID = GetLoggedInUserID();
  const [modal, setModal] = useState(false);
  const [completedSprintCount, setCompletedSprintCount] = useState(0);
  const newStatus = !status;

  const handleUserStatusToggle = async () => {
    if (!active) {
      fetchCompletedSprints(releaseId, projectId);
      setModal(true);
    } else {
      await ProjectService.UpdateReleasesStatus(releaseId, newStatus, userID);
      setStatus(newStatus);
      loading();
    }
  };

  const fetchCompletedSprints = async (releaseId, projectId) => {
    try {
      let count = 0;
      const response = await SprintOverviewService.GetCompletedSprints(
        projectId
      );
      if (response.status === HTTPResponse.OK) {
        response.data.forEach((item) => {
          if (item.releaseId === releaseId) {
            count += item.actionItems.length;
          }
        });

        setCompletedSprintCount(count);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const releaseToggle = async () => {
    try {
      const resp = await ProjectService.UpdateReleasesStatus(
        releaseId,
        newStatus,
        userID
      );

      if (resp.status === HTTPResponse.OK) {
        setStatus(newStatus);
        setModal(false);
        loading();
      }
    } catch { }
  };
  return (
    <td className="toggle-col" style={{ borderBottom: "0px" }}>
      {modal && (
        <LogOutModal
          showDeleteModal={modal}
          onYesClick={releaseToggle}
          onNoClick={() => setModal(false)}
          completedSprintCounts={completedSprintCount}
        ></LogOutModal>
      )}

      <div
        className="col-4 col-md-4 col-lg-4 toggle-col"
        style={{ padding: "0px 0px", backgroundColor: "inherit" }}
      >
        <label className="toggle">
          <input
            className="toggle-input"
            type="checkbox"
            checked={status}
            onChange={handleUserStatusToggle}
          />

          <span
            className={
              status ? "toggle-release-label checked" : "toggle-release-label"
            }
            data-off="Unreleased"
            data-on="Release"
          />

          <span
            className={
              status ? "toggle-release-handle checked" : "toggle-release-handle"
            }
          />
        </label>
      </div>
    </td>
  );
};

export default AddProject;
