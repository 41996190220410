import React, { useContext, useCallback, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext, useUIContext } from "../Contexts/AppContextProvider";
import {
  GetLocalStorageData,
  AddLocalStorageData,
  GetLoggedInUserID,
  RemoveLocalStorageData,
  changePageTitle,
  formatCorrectHours,
} from "../Helpers/Utilities";
import { ReportService } from "../WebApiServices/Report.service";
import {
  DefaultAGGridProps,
  HTTPResponse,
  PriorityListTitle,
  ROLE_TYPE,
  StatusList,
  TASK_PRIO,
  ToastMessageType,
} from "../Helpers/Enums";
import Select, { components } from "react-select";
import DatePicker from "react-datepicker";

import AGGrid from "../Components/AGGrid";
import { ToastMessages } from "../Helpers/HelperText";
import { useRef } from "react";
import BreadcrumbComponent from "../Components/BreadcrumbComponent";
import { UserRolesPermission } from "../WebApiServices/RolesPermission.service";
import ContextMenuComponent from "../Components/ContextMenuComponent";
import DeleteModal from "../Components/DeleteModal";
import ProjectSelectionModal from "../Components/ProjectSelectionModal";
import { ActionItemService } from "../WebApiServices/ActionItem.service";
/* <summary>
date: 15-04-2024
Name: Mustakim
description: myTasks page for admin to show all the tasks and assign it on to a particular date using drag and drop functionality,
<summary>*/
const MyTasks = () => {
  const { ShowToastMessage, ToggleLoader } = useContext(AppContext);
  const { LogOut } = useUIContext();
  const userRoleId = GetLocalStorageData("userRole");
  const [isActive, setIsActive] = useState({
    past: false,
    today: false,
    tomorrow: false,
    later: false,
  });
  const navigate = useNavigate();
  const handleClick = (day) => {
    setIsActive((prevState) => ({
      ...prevState,
      [day]: !prevState[day],
    }));
  };
  const loggedInUser = GetLocalStorageData("userId");
  const loggedInName = GetLocalStorageData("userName");
  const roleId = Number(GetLocalStorageData("userRole"));
  const employeeId = GetLoggedInUserID();
  const [selectedEmployee, setSelectedEmployee] = useState(
    loggedInUser ? [{ value: loggedInUser, label: loggedInName }] : []
  );
  const [selectedProject, setSelectedProject] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [projects, setProjects] = useState([]);
  const [filteredData, setFilteredData] = useState("");
  const [taskData, setTaskData] = useState({
    past: [],
    today: [],
    tomorrow: [],
    later: [],
  });
  const [filterTaskData, setFilterTaskData] = useState({
    past: [],
    today: [],
    tomorrow: [],
    later: [],
  });
  let projectarray = [];
  let emparray = [];
  useEffect(() => {
    fetchTasks();
    fetchDropDownData();
    GetAllRolesPermission();
  }, [selectedProject, selectedEmployee, roleId]);
  /* <summary>
 date: 10-06-2024
 Name: Mustakim
 description: To remove retained data when clicked on Add another item in EditActionItem page.
 <summary>*/
  useEffect(() => {
    RemoveLocalStorageData("assigneeDrpDwn");
    RemoveLocalStorageData("typeDrpDwn");
    RemoveLocalStorageData("priorityDrpDwn");
    RemoveLocalStorageData("statusDrpDwn");
    RemoveLocalStorageData("selectedLinkedAI");
    RemoveLocalStorageData("sprintId");
    changePageTitle("My Tasks");
  }, []);
  //SYJ - User Roles Permission
  const [adminPermission, setAdminPermission] = useState({
    ManageEmp: {
      add: 0,
      edit: 0,
      view: 0,
      delete: 0,
      addOther: 0,
      editOther: 0,
      viewOther: 0,
      deleteOther: 0
    },
    ManageRolesPermission: {
      add: 0,
      edit: 0,
      view: 0,
      delete: 0,
      addOther: 0,
      editOther: 0,
      viewOther: 0,
      deleteOther: 0
    },
    ManageProject: {
      add: 0,
      edit: 0,
      view: 0,
      delete: 0,
      addOther: 0,
      editOther: 0,
      viewOther: 0,
      deleteOther: 0
    },
    TimeLog: {
      add: 0,
      edit: 0,
      view: 0,
      delete: 0,
      addOther: 0,
      editOther: 0,
      viewOther: 0,
      deleteOther: 0
    },
    MyTask: {
      add: 0,
      edit: 0,
      view: 0,
      delete: 0,
      addOther: 0,
      editOther: 0,
      viewOther: 0,
      deleteOther: 0
    }
  });

  const [projectLeadPermission, setProjectLeadPermission] = useState({
    ManageEmp: {
      add: 0,
      edit: 0,
      view: 0,
      delete: 0,
      addOther: 0,
      editOther: 0,
      viewOther: 0,
      deleteOther: 0
    },
    ManageRolesPermission: {
      add: 0,
      edit: 0,
      view: 0,
      delete: 0,
      addOther: 0,
      editOther: 0,
      viewOther: 0,
      deleteOther: 0
    },
    ManageProject: {
      add: 0,
      edit: 0,
      view: 0,
      delete: 0,
      addOther: 0,
      editOther: 0,
      viewOther: 0,
      deleteOther: 0
    },
    TimeLog: {
      add: 0,
      edit: 0,
      view: 0,
      delete: 0,
      addOther: 0,
      editOther: 0,
      viewOther: 0,
      deleteOther: 0
    },
    MyTask: {
      add: 0,
      edit: 0,
      view: 0,
      delete: 0,
      addOther: 0,
      editOther: 0,
      viewOther: 0,
      deleteOther: 0
    }
  });

  const [employeePermission, setEmployeePermission] = useState({
    ManageEmp: {
      add: 0,
      edit: 0,
      view: 0,
      delete: 0,
      addOther: 0,
      editOther: 0,
      viewOther: 0,
      deleteOther: 0
    },
    ManageRolesPermission: {
      add: 0,
      edit: 0,
      view: 0,
      delete: 0,
      addOther: 0,
      editOther: 0,
      viewOther: 0,
      deleteOther: 0
    },
    ManageProject: {
      add: 0,
      edit: 0,
      view: 0,
      delete: 0,
      addOther: 0,
      editOther: 0,
      viewOther: 0,
      deleteOther: 0
    },
    TimeLog: {
      add: 0,
      edit: 0,
      view: 0,
      delete: 0,
      addOther: 0,
      editOther: 0,
      viewOther: 0,
      deleteOther: 0
    },
    MyTask: {
      add: 0,
      edit: 0,
      view: 0,
      delete: 0,
      addOther: 0,
      editOther: 0,
      viewOther: 0,
      deleteOther: 0
    }
  });


  const [selectedRowId, setSelectedRowId] = useState(null);
  const [contextMenuVisibleToday, setContextMenuVisibleToday] = useState(false);
  const [contextMenuVisibleTomorrow, setContextMenuVisibleTomorrow] =
    useState(false);
  const [contextMenuVisibleLater, setContextMenuVisibleLater] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState({
    x: 0,
    y: 0,
  });
  const [showDeleteModalPopUp, setShowDeleteModal] = useState(false);
  const [showProjectsModal, setShowProjectsModal] = useState(false);

  // useEffect(async() => {
  //   debugger
  //   let response = await ReportService.GetWorklogDropDownData(employeeId, roleId);
  //     const data = response.data;
  //     if (response.status === HTTPResponse.OK) {
  //       setEmployees(data.employees);

  //   // const storedEmployees = JSON.parse(localStorage.getItem('employees')) || [];
  //   // setEmployees(storedEmployees);

  //   const userId = GetLocalStorageData("userId");
  //   debugger
  //   if (userId) {
  //     const loggedInUser = data.employees.find(emp => emp.value === userId);
  //     if (loggedInUser) {
  //       //setSelectedEmployee(loggedInUser);
  //     }
  //   }
  // }
  //    // const userId = GetLocalStorageData("userId");
  //       // if (userId) {
  //       //   const loggedInUser = data.employees.find(emp => emp.value === userId);
  //       //   if (loggedInUser) {
  //       //     setSelectedEmployee(loggedInUser);
  //       //   }
  //       // }
  // }, []);

  const fetchDropDownData = async () => {
    try {
      ToggleLoader(true);
      let response = await ReportService.GetWorklogDropDownData(
        employeeId,
        roleId,
        true, false
      );
      const data = response.data;
      if (response.status === HTTPResponse.OK) {
        setEmployees(data.employees);
        setProjects(data.projects);
        projectarray = data.projects;
        emparray = data.employees;

        ToggleLoader(false);
      } else {
        if (response.status === HTTPResponse.Unauthorized) {
          LogOut();
        }
        ToggleLoader(false);
      }
    } catch (error) {
      ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
      ToggleLoader(false);
    }
  };
  // const handleSearchChange = (e) => {
  //   const value = e.target.value;
  //   setFilteredData(value);

  // }
  const handleSearchChange = (e) => {
    setFilteredData(e.target.value);
  };
  const handleClearInput = () => {
    setFilteredData("");
  };

  // useEffect(() => {
  //   const filterTasks = {
  //     today: taskData.today.filter((item) => item.title.toLowerCase().includes(filteredData.toLowerCase()) || item.key.toLowerCase().includes(filteredData.toLowerCase())),
  //     tomorrow: taskData.tomorrow.filter((item) => item.title.toLowerCase().includes(filteredData.toLowerCase()) || item.key.toLowerCase().includes(filteredData.toLowerCase())),
  //     later: taskData.later.filter((item) => item.title.toLowerCase().includes(filteredData.toLowerCase()) || item.key.toLowerCase().includes(filteredData.toLowerCase())), // Fix the key here
  //   }
  //   setFilterTaskData(filterTasks);
  // }, [filteredData]);

  // const fetchTasks = async () => {
  //   try {

  //     ToggleLoader(true);
  //     const response = await ReportService.GetMyTasksForAdmin(roleId);

  //     // Extracting values from projects array
  //     const projectIds = projectarray.map(project => project.value);
  //     const empIds = emparray.map(emp => emp.value);

  //     let sortedData = roleId === ROLE_TYPE.LEAD ? response.data.filter(item => projectIds.includes(item.projectId)
  //       && empIds.includes(item.assigneeId)) :
  //       roleId === ROLE_TYPE.EMPLOYEE ? response.data.filter(item => item.assigneeId === employeeId) : response.data;

  //     if (selectedEmployee.length > 0 && selectedProject.length > 0) {
  //       sortedData = sortedData.filter((item) =>
  //         selectedEmployee.some(option => item.assigneeId === Number(option.value)) &&
  //         selectedProject.some(option => item.projectId === Number(option.value))
  //       );
  //     } else if (selectedEmployee.length > 0) {
  //       sortedData = sortedData.filter((item) =>
  //         selectedEmployee.some(option => item.assigneeId === Number(option.value))
  //       );
  //     } else if (selectedProject.length > 0) {
  //       sortedData = sortedData.filter((item) =>
  //         selectedProject.some(option => item.projectId === Number(option.value))
  //       );
  //     }

  //     const today = new Date().toDateString();
  //     const tomorrow = new Date();
  //     tomorrow.setDate(tomorrow.getDate() + 1);
  //     const tomorrowDateString = tomorrow.toDateString();

  //     const dayAfterTom = new Date();
  //     dayAfterTom.setDate(dayAfterTom.getDate() + 2);
  //     const dayAfterTomDateString = dayAfterTom.toDateString();

  //     const updatedTaskData = {
  //       today: sortedData.filter((item) => {
  //         const itemDate = new Date(item.createdOn).toDateString();
  //         return itemDate === today;
  //       }),
  //       tomorrow: sortedData.filter((item) => {
  //         const itemDate = new Date(item.createdOn).toDateString();
  //         return itemDate === tomorrowDateString;
  //       }),
  //       later: sortedData.filter((item) => {
  //         //const itemDate = new Date(item.createdOn).toDateString();
  //         return item.createdOn == null || "";
  //       }),
  //     };

  //     setTaskData(updatedTaskData);
  //     setFilterTaskData(updatedTaskData)
  //       ;.
  //     ToggleLoader(false);

  //   } catch (error) {
  //     ToggleLoader(false);
  //     ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
  //   }
  // };
  const [totalData, setTotalData] = useState();
  const fetchTotalData = async () => {
    const response = await ReportService.GetMyTasksForAdmin(roleId);
    if (response.status === HTTPResponse.OK) {
      setTotalData(response.data);
    }
  }
  const fetchTasks = async () => {
    try {
      ToggleLoader(true);
      const response = await ReportService.GetMyTasksForAdmin(roleId);

      // Extracting values from projects array
      const projectIds = projectarray.map((project) => project.value);
      const empIds = emparray.map((emp) => emp.value);

      let sortedData =
        roleId === ROLE_TYPE.LEAD
          ? response.data.filter(
            (item) =>
              projectIds.includes(item.projectId) &&
              empIds.includes(item.assigneeId)
          )
          : roleId === ROLE_TYPE.EMPLOYEE
            ? response.data.filter((item) => item.assigneeId === employeeId)
            : response.data;

      if (selectedEmployee.length > 0 && selectedProject.length > 0) {
        sortedData = sortedData.filter(
          (item) =>
            selectedEmployee.some(
              (option) => item.assigneeId === Number(option.value)
            ) &&
            selectedProject.some(
              (option) => item.projectId === Number(option.value)
            )
        );
      } else if (selectedEmployee.length > 0) {
        sortedData = sortedData.filter((item) =>
          selectedEmployee.some(
            (option) => item.assigneeId === Number(option.value)
          )
        );
      } else if (selectedProject.length > 0) {
        sortedData = sortedData.filter((item) =>
          selectedProject.some(
            (option) => item.projectId === Number(option.value)
          )
        );
      }

      //SYJ Cbanges -13/06/2024- for => https://edotpulse.com/EditActionItem/PULSE-412
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      const tomorrow = new Date(today);
      tomorrow.setDate(today.getDate() + 1);

      const endOfWeek = new Date(today);
      endOfWeek.setDate(today.getDate() + (7 - today.getDay()));
      endOfWeek.setHours(0, 0, 0, 0);

      const startOfNextWeek = new Date(endOfWeek);
      startOfNextWeek.setDate(endOfWeek.getDate() + 1);
      startOfNextWeek.setHours(0, 0, 0, 0);

      const updatedTaskData = {
        past: sortedData.filter((item) => {
          const itemDate = new Date(item.createdOn);
          itemDate.setHours(0, 0, 0, 0);
          return itemDate.getTime() < today.getTime();
        }),
        today: sortedData.filter((item) => {
          const itemDate = new Date(item.createdOn);
          itemDate.setHours(0, 0, 0, 0);
          return itemDate.getTime() === today.getTime();
        }),
        tomorrow: sortedData.filter((item) => {
          const itemDate = new Date(item.createdOn);
          itemDate.setHours(0, 0, 0, 0);
          return itemDate >= tomorrow && itemDate <= endOfWeek;
        }),
        later: sortedData.filter((item) => {
          const itemDate = new Date(item.createdOn);
          itemDate.setHours(0, 0, 0, 0);
          return itemDate >= startOfNextWeek;
        }),
      };

      setTaskData(updatedTaskData);
      setFilterTaskData(updatedTaskData);
      ToggleLoader(false);
    } catch (error) {
      ToggleLoader(false);
      ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
    }
  };

  useEffect(() => {
    const filterTasks = {
      past: taskData.past.filter(
        (item) =>
          (item.title != undefined &&
            item.title != null &&
            item.title
              .toLowerCase()
              .includes(
                filteredData != undefined &&
                filteredData != null &&
                filteredData.toLowerCase()
              )) ||
          item.key
            .toLowerCase()
            .includes(
              filteredData != undefined &&
              filteredData != null &&
              filteredData.toLowerCase()
            )
      ),

      today: taskData.today.filter(
        (item) =>
          (item.title != undefined &&
            item.title != null &&
            item.title
              .toLowerCase()
              .includes(
                filteredData != undefined &&
                filteredData != null &&
                filteredData.toLowerCase()
              )) ||
          item.key
            .toLowerCase()
            .includes(
              filteredData != undefined &&
              filteredData != null &&
              filteredData.toLowerCase()
            )
      ),
      tomorrow: taskData.tomorrow.filter(
        (item) =>
          (item.title != undefined &&
            item.title != null &&
            item.title
              .toLowerCase()
              .includes(
                filteredData != undefined &&
                filteredData != null &&
                filteredData.toLowerCase()
              )) ||
          item.key
            .toLowerCase()
            .includes(
              filteredData != undefined &&
              filteredData != null &&
              filteredData.toLowerCase()
            )
      ),
      later: taskData.later.filter(
        (item) =>
          (item.title != undefined &&
            item.title != null &&
            item.title
              .toLowerCase()
              .includes(
                filteredData != undefined &&
                filteredData != null &&
                filteredData.toLowerCase()
              )) ||
          item.key
            .toLowerCase()
            .includes(
              filteredData != undefined &&
              filteredData != null &&
              filteredData.toLowerCase()
            )
      ), // Fix the key here
    };
    setFilterTaskData(filterTasks);
  }, [filteredData, taskData]);

  // useEffect(() => {
  //   if (employees.length > 0) {
  //     const loggedInUser = employees.find(emp => emp.value === employeeId);
  //     if (loggedInUser) {
  //       setSelectedEmployee([loggedInUser]);
  //     }
  //   }
  // }, [employees]);

  const TaskIdLinkRenderer = ({ value, taskTitle }) => {
    return value ? <a href={"/EditActionItem/" + taskTitle}>{value}</a> : null;
  };
  //   const columnDefs1 = [
  //     {
  //       headerName: "Task ID",
  //       field: "key",
  //       width :150,
  //       filter: false,
  //       sortable: true,
  //       tooltipField: "key",
  //       cellRenderer: (params) => (
  //         <div>
  //           <TaskIdLinkRenderer value={params.data.key} projectID={params.data.projectId} taskTitle={params.data.key}/>
  //         </div>

  //       ),
  //        rowDrag: true,
  //       headerClass: "header",
  //     },

  //     {
  //       headerName: "Task Name",
  //       field: "title",
  //       width :380,
  //       filter: false,
  //       sortable: true,
  //       cellStyle: {
  //         textOverflow: "ellipsis",
  //         whiteSpace: "pre",
  //         overflow: "hidden",
  //       }, tooltipField: "title",
  //       headerClass: "header",
  //     },
  //     {
  //       headerName: "Hours",
  //       field: "estimates",
  //       width:100,
  //       filter: false,
  //       sortable: true,
  //       cellStyle: {
  //         textOverflow: "ellipsis",
  //         whiteSpace: "pre",
  //         overflow: "hidden",
  //       }, tooltipField: "estimates",
  //       headerClass: "header",
  //     },
  //     {
  //       headerName: "Priority",
  //       field: "priorityId",
  //       width:120,

  //       filter: false,
  //       sortable: true,
  //       cellStyle: {
  //         textOverflow: "ellipsis",
  //         whiteSpace: "pre",
  //         overflow: "hidden",
  //       },
  //       cellRenderer: (params) => {

  //         const imageUrl = TASK_PRIO[params.value] || "";
  //         return <img src={imageUrl} alt={`Priority ${params.value}`} />;
  //       },
  //       headerClass: "header",
  //     },
  //     {
  //       headerName: "Assigned To",
  //       field: "assigneeName",
  //       width: 185,
  //       filter: false,
  //       sortable: true,
  //     //   cellStyle: {
  //     //     textOverflow: "ellipsis",
  //     //     whiteSpace: "pre",
  //     //     overflow: "hidden",
  //     //   },
  //       tooltipField: "assigneeName",
  //       headerClass: "header",
  //     },

  //     {
  //       headerName:" Assigned By",
  //       field: "reporterName",
  //       flex:1,
  //       filter: false,

  //       sortable: true,
  //       tooltipField: "reporterName",
  //       headerClass: "header",
  //     },
  //     {
  //       headerName: "Status",
  //       field: "actionItemStatus",
  //       flex:1,
  //       filter: false,
  // tooltipField:"actionItemStatus",
  //       sortable: true,
  //    cellRenderer:(params)=>{
  //     return (
  //                             <span className="pending-status">{params.value}</span>
  //     )
  //    },
  //       headerClass: "header",
  //     },
  //   ];
  const [draggedRowId, setDraggedRowId] = useState(0);

  const [currentLocation, setCurrentLocation] = useState("");
  const handleDroppedActionItem = async (actionItem, string) => {
    //setDraggedRowId(actionItem);
    ToggleLoader(true);
    if (actionItem.actionItemId != 0 && string != currentLocation) {
      const dataToupdate = {
        actionItemId: actionItem.actionItemId,
        day: string,
      };

      const response = await ReportService.UpdateActionItemAssignedOnDate(
        dataToupdate
      );
      if (response.status === HTTPResponse.OK) {
        fetchDropDownData();
        fetchTasks();
      } else {
        ToggleLoader(false);
        ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
      }
    }
  };
  const handleDragStart = (e, item) => {
    e.dataTransfer.setData("item", JSON.stringify(item));
  };
  // const HandleDropdownChange = (e, id, drp) => {
  //   if (drp === "employeeId") {
  //     setSelectedEmployee((prev) => ({
  //       ...prev,
  //       [id]: e,
  //       [drp]: Number(e.value),
  //     }));
  //   }

  //   else {
  //     setSelectedProject((prev) => ({
  //       ...prev,
  //       [id]: e,
  //       [drp]: Number(e.value),
  //     }));
  //   }
  // };
  const HandleDropdownChange = (selectedOptions, id, drp) => {
    if (drp === "employeeId") {
      setSelectedEmployee(selectedOptions);
    } else {
      setSelectedProject(selectedOptions);
    }
  };

  //Set Roles Permission

  const GetAllRolesPermission = async () => {
    try {
      ToggleLoader(true);
      //Default Array llist to filter data
      let adminList = [];
      let adminOtherList = [];
      let leadList = [];
      let leadOtherList = [];
      let employeeList = [];
      let employeeOtherList = [];
      const responseDefaultRP =
        await UserRolesPermission.GetDefaultRolePermissions();
      const responseUserSaveRP =
        await UserRolesPermission.GetUserSavedRolePermissions();

      // Function to get the list of role IDs that exist in the user saved role permissions data
      const existingRoleIds = responseUserSaveRP.data.map(
        (item) => item.roleId
      );

      // Bind data based on the existing role IDs
      if (existingRoleIds.includes(1)) {
        adminList = responseUserSaveRP.data.filter(item => item.roleId === 1 && item.staticRolePermissionValueId === 1);
        adminOtherList = responseUserSaveRP.data.filter(item => item.roleId === 1 && item.staticRolePermissionValueId === 2);
      }
      if (existingRoleIds.includes(2)) {
        employeeList = responseUserSaveRP.data.filter(item => item.roleId === 2 && item.staticRolePermissionValueId === 1);
        employeeOtherList = responseUserSaveRP.data.filter(item => item.roleId === 2 && item.staticRolePermissionValueId === 2);
      }
      if (existingRoleIds.includes(3)) {
        leadList = responseUserSaveRP.data.filter(item => item.roleId === 3 && item.staticRolePermissionValueId === 1);
        leadOtherList = responseUserSaveRP.data.filter(item => item.roleId === 3 && item.staticRolePermissionValueId === 2);
      }

      // If no role IDs 1, 2, or 3 data is present, bind the default list
      if (adminList.length === 0) {
        adminList = responseDefaultRP.data.filter(item => item.roleId === 1 && item.staticRolePermissionValueId === 1);
      }
      if (employeeList.length === 0) {
        employeeList = responseDefaultRP.data.filter(item => item.roleId === 2 && item.staticRolePermissionValueId === 1);
      }
      if (leadList.length === 0) {
        leadList = responseDefaultRP.data.filter(item => item.roleId === 3 && item.staticRolePermissionValueId === 1);
      }

      if (adminList.length > 0) {
        // Update the adminPermission state with the filtered admin list
        setAdminPermission({
          ManageEmp: {
            add: adminList.some((item) => item.meadd === 0) ? 0 : 1,
            edit: adminList.some((item) => item.meedit === 0) ? 0 : 1,
            view: adminList.some((item) => item.meview === 0) ? 0 : 1,
            delete: adminList.some((item) => item.medelete === 0) ? 0 : 1,
          },
          ManageRolesPermission: {
            add: adminList.some((item) => item.mrpadd === 0) ? 0 : 1,
            edit: adminList.some((item) => item.mrpedit === 0) ? 0 : 1,
            view: adminList.some((item) => item.mrpview === 0) ? 0 : 1,
            delete: adminList.some((item) => item.mrpdelete === 0) ? 0 : 1,
          },
          ManageProject: {
            add: adminList.some((item) => item.mpadd === 0) ? 0 : 1,
            edit: adminList.some((item) => item.mpedit === 0) ? 0 : 1,
            view: adminList.some((item) => item.mpview === 0) ? 0 : 1,
            delete: adminList.some((item) => item.mpdelete === 0) ? 0 : 1,
          },
          TimeLog: {
            add: adminList.some((item) => item.tladd === 0) ? 0 : 1,
            edit: adminList.some((item) => item.tledit === 0) ? 0 : 1,
            view: adminList.some((item) => item.tlview === 0) ? 0 : 1,
            delete: adminList.some((item) => item.tldelete === 0) ? 0 : 1,
          },
          MyTask: {
            add: adminList.some((item) => item.mtadd === 0) ? 0 : 1,
            edit: adminList.some((item) => item.mtedit === 0) ? 0 : 1,
            view: adminList.some((item) => item.mtview === 0) ? 0 : 1,
            delete: adminList.some((item) => item.mtdelete === 0) ? 0 : 1,
          },
        });
      }

      if (leadList.length > 0) {
        // Update the adminPermission state with the filtered admin list
        setProjectLeadPermission({
          ManageEmp: {
            add: leadList.some((item) => item.meadd === 0) ? 0 : 1,
            edit: leadList.some((item) => item.meedit === 0) ? 0 : 1,
            view: leadList.some((item) => item.meview === 0) ? 0 : 1,
            delete: leadList.some((item) => item.medelete === 0) ? 0 : 1,
          },
          ManageRolesPermission: {
            add: leadList.some((item) => item.mrpadd === 0) ? 0 : 1,
            edit: leadList.some((item) => item.mrpedit === 0) ? 0 : 1,
            view: leadList.some((item) => item.mrpview === 0) ? 0 : 1,
            delete: leadList.some((item) => item.mrpdelete === 0) ? 0 : 1,
          },
          ManageProject: {
            add: leadList.some((item) => item.mpadd === 0) ? 0 : 1,
            edit: leadList.some((item) => item.mpedit === 0) ? 0 : 1,
            view: leadList.some((item) => item.mpview === 0) ? 0 : 1,
            delete: leadList.some((item) => item.mpdelete === 0) ? 0 : 1,
          },
          TimeLog: {
            add: leadList.some((item) => item.tladd === 0) ? 0 : 1,
            edit: leadList.some((item) => item.tledit === 0) ? 0 : 1,
            view: leadList.some((item) => item.tlview === 0) ? 0 : 1,
            delete: leadList.some((item) => item.tldelete === 0) ? 0 : 1,
          },
          MyTask: {
            add: leadList.some((item) => item.mtadd === 0) ? 0 : 1,
            edit: leadList.some((item) => item.mtedit === 0) ? 0 : 1,
            view: leadList.some((item) => item.mtview === 0) ? 0 : 1,
            delete: leadList.some((item) => item.mtdelete === 0) ? 0 : 1,
          },
        });
      }

      if (employeeList.length > 0) {
        // Update the adminPermission state with the filtered admin list
        setEmployeePermission({
          ManageEmp: {
            add: employeeList.some((item) => item.meadd === 0) ? 0 : 1,
            edit: employeeList.some((item) => item.meedit === 0) ? 0 : 1,
            view: employeeList.some((item) => item.meview === 0) ? 0 : 1,
            delete: employeeList.some((item) => item.medelete === 0) ? 0 : 1,
          },
          ManageRolesPermission: {
            add: employeeList.some((item) => item.mrpadd === 0) ? 0 : 1,
            edit: employeeList.some((item) => item.mrpedit === 0) ? 0 : 1,
            view: employeeList.some((item) => item.mrpview === 0) ? 0 : 1,
            delete: employeeList.some((item) => item.mrpdelete === 0) ? 0 : 1,
          },
          ManageProject: {
            add: employeeList.some((item) => item.mpadd === 0) ? 0 : 1,
            edit: employeeList.some((item) => item.mpedit === 0) ? 0 : 1,
            view: employeeList.some((item) => item.mpview === 0) ? 0 : 1,
            delete: employeeList.some((item) => item.mpdelete === 0) ? 0 : 1,
          },
          TimeLog: {
            add: employeeList.some((item) => item.tladd === 0) ? 0 : 1,
            edit: employeeList.some((item) => item.tledit === 0) ? 0 : 1,
            view: employeeList.some((item) => item.tlview === 0) ? 0 : 1,
            delete: employeeList.some((item) => item.tldelete === 0) ? 0 : 1,
          },
          MyTask: {
            add: employeeList.some((item) => item.mtadd === 0) ? 0 : 1,
            edit: employeeList.some((item) => item.mtedit === 0) ? 0 : 1,
            view: employeeList.some((item) => item.mtview === 0) ? 0 : 1,
            delete: employeeList.some((item) => item.mtdelete === 0) ? 0 : 1,
          },
        });
      }

      if (adminOtherList.length > 0) {
        // Update the adminPermission state with the filtered admin list
        setAdminPermission(prevState => ({
          ...prevState,
          ManageEmp: {
            ...prevState.ManageEmp,
            addOther: adminOtherList.some(item => item.meadd === 0) ? 0 : 1,
            editOther: adminOtherList.some(item => item.meedit === 0) ? 0 : 1,
            viewOther: adminOtherList.some(item => item.meview === 0) ? 0 : 1,
            deleteOther: adminOtherList.some(item => item.medelete === 0) ? 0 : 1,
          },
          ManageRolesPermission: {
            ...prevState.ManageRolesPermission,
            addOther: adminOtherList.some(item => item.mrpadd === 0) ? 0 : 1,
            editOther: adminOtherList.some(item => item.mrpedit === 0) ? 0 : 1,
            viewOther: adminOtherList.some(item => item.mrpview === 0) ? 0 : 1,
            deleteOther: adminOtherList.some(item => item.mrpdelete === 0) ? 0 : 1,
          },
          ManageProject: {
            ...prevState.ManageProject,
            addOther: adminOtherList.some(item => item.mpadd === 0) ? 0 : 1,
            editOther: adminOtherList.some(item => item.mpedit === 0) ? 0 : 1,
            viewOther: adminOtherList.some(item => item.mpview === 0) ? 0 : 1,
            deleteOther: adminOtherList.some(item => item.mpdelete === 0) ? 0 : 1,
          },
          TimeLog: {
            ...prevState.TimeLog,
            addOther: adminOtherList.some(item => item.tladd === 0) ? 0 : 1,
            editOther: adminOtherList.some(item => item.tledit === 0) ? 0 : 1,
            viewOther: adminOtherList.some(item => item.tlview === 0) ? 0 : 1,
            deleteOther: adminOtherList.some(item => item.tldelete === 0) ? 0 : 1,
          },
          MyTask: {
            ...prevState.MyTask,
            addOther: adminOtherList.some(item => item.mtadd === 0) ? 0 : 1,
            editOther: adminOtherList.some(item => item.mtedit === 0) ? 0 : 1,
            viewOther: adminOtherList.some(item => item.mtview === 0) ? 0 : 1,
            deleteOther: adminOtherList.some(item => item.mtdelete === 0) ? 0 : 1,
          }
        }));
      }

      if (leadOtherList.length > 0) {
        // Update the adminPermission state with the filtered admin list
        setProjectLeadPermission(prevState => ({
          ...prevState,
          ManageEmp: {
            ...prevState.ManageEmp,
            addOther: leadOtherList.some(item => item.meadd === 0) ? 0 : 1,
            editOther: leadOtherList.some(item => item.meedit === 0) ? 0 : 1,
            viewOther: leadOtherList.some(item => item.meview === 0) ? 0 : 1,
            deleteOther: leadOtherList.some(item => item.medelete === 0) ? 0 : 1,
          },
          ManageRolesPermission: {
            ...prevState.ManageRolesPermission,
            addOther: leadOtherList.some(item => item.mrpadd === 0) ? 0 : 1,
            editOther: leadOtherList.some(item => item.mrpedit === 0) ? 0 : 1,
            viewOther: leadOtherList.some(item => item.mrpview === 0) ? 0 : 1,
            deleteOther: leadOtherList.some(item => item.mrpdelete === 0) ? 0 : 1,
          },
          ManageProject: {
            ...prevState.ManageProject,
            addOther: leadOtherList.some(item => item.mpadd === 0) ? 0 : 1,
            editOther: leadOtherList.some(item => item.mpedit === 0) ? 0 : 1,
            viewOther: leadOtherList.some(item => item.mpview === 0) ? 0 : 1,
            deleteOther: leadOtherList.some(item => item.mpdelete === 0) ? 0 : 1,
          },
          TimeLog: {
            ...prevState.TimeLog,
            addOther: leadOtherList.some(item => item.tladd === 0) ? 0 : 1,
            editOther: leadOtherList.some(item => item.tledit === 0) ? 0 : 1,
            viewOther: leadOtherList.some(item => item.tlview === 0) ? 0 : 1,
            deleteOther: leadOtherList.some(item => item.tldelete === 0) ? 0 : 1,
          },
          MyTask: {
            ...prevState.MyTask,
            addOther: leadOtherList.some(item => item.mtadd === 0) ? 0 : 1,
            editOther: leadOtherList.some(item => item.mtedit === 0) ? 0 : 1,
            viewOther: leadOtherList.some(item => item.mtview === 0) ? 0 : 1,
            deleteOther: leadOtherList.some(item => item.mtdelete === 0) ? 0 : 1,
          }
        }));
      }

      if (employeeOtherList.length > 0) {
        // Update the adminPermission state with the filtered admin list
        setEmployeePermission(prevState => ({
          ManageEmp: {
            ...prevState.ManageEmp,
            addOther: employeeOtherList.some(item => item.meadd === 0) ? 0 : 1,
            editOther: employeeOtherList.some(item => item.meedit === 0) ? 0 : 1,
            viewOther: employeeOtherList.some(item => item.meview === 0) ? 0 : 1,
            deleteOther: employeeOtherList.some(item => item.medelete === 0) ? 0 : 1,
          },
          ManageRolesPermission: {
            ...prevState.ManageRolesPermission,
            addOther: employeeOtherList.some(item => item.mrpadd === 0) ? 0 : 1,
            editOther: employeeOtherList.some(item => item.mrpedit === 0) ? 0 : 1,
            viewOther: employeeOtherList.some(item => item.mrpview === 0) ? 0 : 1,
            deleteOther: employeeOtherList.some(item => item.mrpdelete === 0) ? 0 : 1,
          },
          ManageProject: {
            ...prevState.ManageProject,
            addOther: employeeOtherList.some(item => item.mpadd === 0) ? 0 : 1,
            editOther: employeeOtherList.some(item => item.mpedit === 0) ? 0 : 1,
            viewOther: employeeOtherList.some(item => item.mpview === 0) ? 0 : 1,
            deleteOther: employeeOtherList.some(item => item.mpdelete === 0) ? 0 : 1,
          },
          TimeLog: {
            ...prevState.TimeLog,
            addOther: employeeOtherList.some(item => item.tladd === 0) ? 0 : 1,
            editOther: employeeOtherList.some(item => item.tledit === 0) ? 0 : 1,
            viewOther: employeeOtherList.some(item => item.tlview === 0) ? 0 : 1,
            deleteOther: employeeOtherList.some(item => item.tldelete === 0) ? 0 : 1,
          },
          MyTask: {
            ...prevState.MyTask,
            addOther: employeeOtherList.some(item => item.mtadd === 0) ? 0 : 1,
            editOther: employeeOtherList.some(item => item.mtedit === 0) ? 0 : 1,
            viewOther: employeeOtherList.some(item => item.mtview === 0) ? 0 : 1,
            deleteOther: employeeOtherList.some(item => item.mtdelete === 0) ? 0 : 1,
          }
        }));
      }

      ToggleLoader(false);
    } catch (error) {
      ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
    }
  };
  const CheckboxOption = (props) => (
    <components.Option {...props}>
      <input type="checkbox" checked={props.isSelected} onChange={() => null} />{" "}
      {props.label}
    </components.Option>
  );
  const defaultDropdownStyles = {
    control: (base, state) => ({
      ...base,
      backgroundColor: state.isDisabled ? "hsl(0,0%,90%)" : "white",
    }),

    placeholder: (defaultStyles, state) => {
      return {
        ...defaultStyles,
        color: "black",
        marginLeft: "6px",
      };
    },
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        whiteSpace: "pre",
        ":hover": {
          backgroundColor: "white",
        },
        color: "black",
        backgroundColor: "white",
        height: "30px",
      };
    },

    valueContainer: (provided, state) => ({
      ...provided,
      padding: "0 6px",
    }),

    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "32px",
    }),

    dropdownIndicator: (base) => ({
      ...base,
      color: "#212529", // Custom colour
    }),
  };

  // Function to handle right-click on a row
  const handleContextMenuToday = (event, id) => {
    event.preventDefault();
    setSelectedRowId(id);
    setContextMenuPosition({ x: event.clientX, y: event.clientY });
    setContextMenuVisibleToday(true);
  };

  const handleContextMenuTomorrow = (event, id) => {
    event.preventDefault();
    setSelectedRowId(id);
    setContextMenuVisibleTomorrow(true);

    setContextMenuPosition({ x: event.clientX, y: event.clientY });
  };

  const handleContextMenuLater = (event, id) => {
    event.preventDefault();
    setSelectedRowId(id);
    setContextMenuVisibleLater(true);

    setContextMenuPosition({ x: event.clientX, y: event.clientY });
  };

  //Function to handle context menu option click
  const handleDeleteToday = () => {
    setContextMenuVisibleToday(false);
    setShowDeleteModal(true); // Hide the context menu
  };
  const handleDeleteTomorrow = () => {
    setContextMenuVisibleTomorrow(false);
    setShowDeleteModal(true); // Hide the context menu
  };
  const handleDeleteLater = () => {
    setContextMenuVisibleLater(false);
    setShowDeleteModal(true); // Hide the context menu
  };

  const OnDeleteModalYesClick = async () => {
    try {
      let idToDelete = selectedRowId;
      if (idToDelete > 0) {
        var deleteRes = await ReportService.DeleteTaskForMyTask(idToDelete);
        if (deleteRes.data) {
          fetchTasks();
          setSelectedRowId(0);
          ShowToastMessage(ToastMessages.TaskDelete, ToastMessageType.Success);
        }
      }
      fetchTasks();
      setShowDeleteModal(false);
    } catch (error) {
      ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
    }
  };

  const OnCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const CloseContextMenu = () => {
    if (contextMenuVisibleToday) setContextMenuVisibleToday(false);
    if (contextMenuVisibleTomorrow) setContextMenuVisibleTomorrow(false);
    if (contextMenuVisibleLater) setContextMenuVisibleLater(false);
  };

  const CloseContextMenuToday = () => {
    if (contextMenuVisibleToday) setContextMenuVisibleToday(false);
  };

  const CloseContextMenuTomorrow = () => {
    if (contextMenuVisibleTomorrow) setContextMenuVisibleTomorrow(false);
  };

  const CloseContextMenuLater = () => {
    if (contextMenuVisibleLater) setContextMenuVisibleLater(false);
  };

  const handleProjectsModal = () => {
    setShowProjectsModal(true);
  };
  const OnCloseProjectsModal = () => {
    setShowProjectsModal(false);
  };
  // const OnYesProjectsModal = (selectedProject) => {

  //   setShowProjectsModal(false);
  //   navigate(`/AddActionItem/${selectedProject}`)
  // };
  const handleProjectSelect = (selectedProject, workItem) => {
    AddLocalStorageData("projectName", selectedProject.label);
    if (!selectedProject.Misc && selectedProject.value > 0) {
      setShowProjectsModal(false);
      navigate(`/AddActionItem/${selectedProject.value}`, {
        state: { workItem: workItem },
      });
    } else if (selectedProject.Misc && selectedProject.value > 0) {
      setShowProjectsModal(false);

      navigate(`/AddActionItem/${selectedProject.value}`, {
        state: { misc: selectedProject.Misc },
      });
      // navigate("/EditProject", {
      //   state: {
      //     projectDetails: item,
      //     existingProjects: projectNames,
      //     Key: projectKey,
      //   },
      // });
    }
  };
  /* <summary>
  date: 23-08-2024
  Name: Mustakim
  description: to update assigned on column in ActionItem table
  <summary>*/
  const handleDueDate = async (task, date) => {

    const formattedDate = date.toISOString().replace('T', ' ').slice(0, -1);
    let formData = new FormData();

    let data = {
      isMyTask: true,
      actionItemId: task.actionItemId,
      assignedOn: date, // Use the formatted date here
      assigneeDrpDwn: {
        value: task.assigneeId,// Assuming `assigneeDrpDwn.value` is what you want to assign
        editedBy: employeeId
      },
      //assignedOn:task.assignedOn
      //assigneeId
    };
    formData.append("actionItemModel", JSON.stringify(data));
    try {
      let response = await ActionItemService.EditActionItem(formData);
      if (response.status === HTTPResponse.OK) {
        fetchTasks();
      }
      else if (response.status === HTTPResponse.Unauthorized) {
        LogOut();
      }
    }
    catch (error) {
      ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
    }
  }

  return (
    <>
      {/* <meta charSet="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <link rel="stylesheet" href="css/style.css" />
      <link rel="stylesheet" href="css/bootstrap.css" />
      <link rel="stylesheet" href="css/bootstrap.min.css" />
      <link
        rel="stylesheet"
        type="text/css"
        href="font-awesome/css/font-awesome.min.css"
      /> */}

      {showDeleteModalPopUp && (
        <DeleteModal
          showDeleteModal={showDeleteModalPopUp}
          onYesClick={OnDeleteModalYesClick}
          onNoClick={OnCloseDeleteModal}
          textToDisplay={"Are you sure you want to delete?"}
        />
      )}
      {showProjectsModal && (
        <ProjectSelectionModal
          showProjectsOptions={showProjectsModal}
          //onYesClick={OnYesProjectsModal}
          onNoClick={OnCloseProjectsModal}
          onProjectSelect={handleProjectSelect}
        />
      )}

      <section className="breadcrumb-block">
        <BreadcrumbComponent /></section>
      <title>My Tasks</title>

      {/* <section className="title-block">
        <div className="container">
          <div className="title-f-box">
            <div className="title-banner">
              <h3 className="page-title">My Tasks</h3>
            </div>
          </div>
        </div>
      </section> */}
      <section className="my-tasks-body">
        <div className="container-fluid">
          <div className="my-tasks" onClick={CloseContextMenu}>
            <div className="search-add-fbox">
              {/* <div className="searchBar mt-2">
                <input
                  className="searchQueryInput-class"
                  id="searchQueryInput"
                  type="text"
                  name="searchQueryInput"
                  placeholder="Search Project"
                  //defaultValue=""
                  value={filteredData}
                  onChange={(e) => handleSearchChange(e)}
                />
                <button
                  id="searchQuerySubmit"
                  type="submit"
                  name="searchQuerySubmit"
                  onClick={handleClearInput}
                >

                  {filteredData ? (
                    <img src="Assets/images/removeIcon.png" width="20px" />
                  ) : (
                    <img
                      src="Assets/images/Icon feather-search.png"
                      style={{ width: "20px", cursor: "pointer" }}
                    />
                  )}
                </button>
              </div> */}
            </div>

            <div
              className="row"
              style={{ justifyContent: "space-between" }}
              onClick={CloseContextMenu}
            >
              <div
                className="col-12 col-md-6 col-lg-3 mt-3"
                style={{ alignSelf: "end" }}
              >
                {/* <label>
                  Search <span className="isRequiredField"></span>
                </label> */}
                {/* <div className="searchBar custom-top-spacing"> */}

                <input
                  className="searchQueryInput-class"
                  id="searchQueryInput"
                  type="text"
                  name="searchQueryInput"
                  placeholder="Search Project,Tasks"
                  //defaultValue=""
                  value={filteredData}
                  onChange={(e) => handleSearchChange(e)}
                />
                <button
                  id="searchQuerySubmit"
                  type="submit"
                  name="searchQuerySubmit"
                  onClick={handleClearInput}
                >
                  {filteredData ? (
                    <img src="Assets/images/removeIcon.png" width="20px" />
                  ) : (
                    <img
                      src="Assets/images/Icon feather-search.png"
                      style={{ width: "20px", cursor: "pointer" }}
                    />
                  )}
                </button>
                {/* </div> */}
              </div>
              {(userRoleId === ROLE_TYPE.ADMIN ||
                userRoleId === ROLE_TYPE.LEAD) && (
                  <div className="col-12 col-md-6 col-lg-3 mt-3">
                    <label className="isRequiredField">Assigned To</label>
                    <Select
                      inputId="employeeDropDown"
                      placeholder="Please Select"
                      value={selectedEmployee}
                      options={employees.map((emp) => ({
                        value: emp.value,
                        label: emp.label,
                      }))}
                      onChange={(selectedOptions) => {
                        setSelectedEmployee(selectedOptions);
                        HandleDropdownChange(
                          selectedOptions,
                          "employees",
                          "employeeId"
                        );
                      }}
                      isMulti // Add this prop for multi-select functionality
                      components={{ Option: CheckboxOption }} // Use custom Option component
                      hideSelectedOptions={false}
                      closeMenuOnSelect={false}
                    // styles={defaultDropdownStyles}
                    />
                  </div>
                )}

              <div className="col-12 col-md-6 col-lg-3 mt-3">
                {/* <div className={(userRoleId === ROLE_TYPE.ADMIN || userRoleId === ROLE_TYPE.LEAD) ? "col-12 col-md-6 col-lg-3 mt-3" : "col-12 col-md-9 col-lg-6 mt-3"}> */}

                <label className="isRequiredField">Project</label>

                {/* <Select
                  name="projectList"
                  placeholder="Please Select"
                  value={selectedProject}
                  options={[
                    { value: 0, label: "All" },
                    ...projects.map((project) => ({
                      value: project.value,
                      label: project.label,
                    }))
                  ]}
                  onChange={(e) => {
                    setSelectedProject(e);
                    HandleDropdownChange(e, "projects", "projectId");
                  }}
                  isMulti
                /> */}
                <Select
                  name="projectList"
                  placeholder="Please Select"
                  value={selectedProject}
                  options={projects.map((project) => ({
                    value: project.value,
                    label: project.label,
                  }))}
                  onChange={(selectedOptions) => {
                    setSelectedProject(selectedOptions);
                    HandleDropdownChange(
                      selectedOptions,
                      "projects",
                      "projectId"
                    );
                  }}
                  isMulti
                  components={{ Option: CheckboxOption }} // Use custom Option component
                  hideSelectedOptions={false}
                  closeMenuOnSelect={false}
                //styles={defaultDropdownStyles}
                />
              </div>
              {((roleId === ROLE_TYPE.ADMIN && adminPermission.MyTask.addOther === 1)
                || (roleId === ROLE_TYPE.LEAD && projectLeadPermission.MyTask.add === 1) ||
                (roleId === ROLE_TYPE.EMPLOYEE && projectLeadPermission.MyTask.addOther === 1) ||
                (roleId === ROLE_TYPE.EMPLOYEE && employeePermission.MyTask.add === 1)) && (
                  <div className="col-12 col-md-6 col-lg-3 mt-3">
                    <div className="add-task-sec custom-top-spacing">
                      <button
                        className="add-task-btn"
                        onClick={handleProjectsModal}
                      >
                        Add Task
                      </button>
                    </div>
                  </div>
                )}
            </div>

            <div className="mt-3" onClick={CloseContextMenuToday}>
              <div
                className=" collapsible-sec-pos"
                onDrop={(e) => {
                  e.preventDefault();

                  const files = e;
                  const item = JSON.parse(e.dataTransfer.getData("item"));
                  handleDroppedActionItem(item, "past");
                }}
                onDragStart={(e) => {
                  setCurrentLocation("past");
                }}
                onDragOver={(e) => {
                  e.preventDefault();
                }}
              >
                <div className="collapsible-sec-header">
                  <div>
                    <span className="collapsible-header-text">Past Due</span>
                  </div>
                </div>

                <button
                  className={`collapsible-btn ${isActive.past ? "active" : ""
                    }`}
                  onClick={() => handleClick("past")}
                >
                  <i className="fa fa-chevron-right" aria-hidden="true" />
                </button>
                <div
                  className="contentt"
                  style={{
                    overflowX: "auto",
                    display: isActive.past ? "block" : "none",
                  }}
                >
                  {((roleId === ROLE_TYPE.ADMIN &&
                    adminPermission.MyTask.viewOther === 1) ||
                    (roleId === ROLE_TYPE.EMPLOYEE &&
                      employeePermission.MyTask.view === 1) ||
                    (roleId === ROLE_TYPE.LEAD &&
                      projectLeadPermission.MyTask.view === 1) ||
                    (roleId === ROLE_TYPE.EMPLOYEE &&
                      projectLeadPermission.MyTask.viewOther === 1)) && (
                      <div className="custom-grid-sec">
                        <table
                          className="table-responsive table-main"
                          style={{ overflow: "auto" }}
                        >
                          <thead>
                            <tr>
                              <th className="td-th-alignment custome-th-width"></th>
                              {((roleId === ROLE_TYPE.ADMIN &&
                                adminPermission.MyTask.editOther === 1) ||
                                (roleId === ROLE_TYPE.EMPLOYEE &&
                                  employeePermission.MyTask.edit === 1) ||
                                (roleId === ROLE_TYPE.LEAD &&
                                  projectLeadPermission.MyTask.edit === 1) ||
                                (roleId === ROLE_TYPE.EMPLOYEE &&
                                  projectLeadPermission.MyTask.editOther === 1)) && (
                                  <th className="custome-TaskId-width">Task ID</th>
                                )}
                             <th className="custome-TaskName-width">Task Name</th>
                              <th className="custome-Estimated-width">Estimated Hours</th>

                              {(userRoleId === ROLE_TYPE.ADMIN ||
                                userRoleId === ROLE_TYPE.LEAD) && (
                                  <th className="custome-AssignedTo-width">Assigned To</th>
                                )}
                              <th className="custome-AssignedBy-width">Assigned By</th>
                              <th className="custome-DueDate-width">Due Date</th>
                              <th className="td-th-alignment custome-Status-width">Status</th>
                              <th className="custome-Delete-width"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {(filteredData.length > 0
                              ? filterTaskData.past
                              : taskData.past
                            ).map((task) => (
                              <tr
                                key={task.id}
                                draggable
                                onDragStart={(e) => handleDragStart(e, task)}
                              >
                                <td className="td-th-alignment" style={{ padding: "10px 5px" }}>
                                  <img
                                    width="20px"
                                    src={TASK_PRIO[task.priorityId]}
                                    alt={PriorityListTitle[task.priorityId]}
                                    title={PriorityListTitle[task.priorityId]}
                                  />
                                </td>
                                {((roleId === ROLE_TYPE.ADMIN &&
                                  adminPermission.MyTask.editOther === 1) ||
                                  (roleId === ROLE_TYPE.EMPLOYEE &&
                                    projectLeadPermission.MyTask.editOther === 1) ||
                                  (roleId === ROLE_TYPE.EMPLOYEE &&
                                    employeePermission.MyTask.edit === 1) ||
                                  (roleId === ROLE_TYPE.LEAD &&
                                    projectLeadPermission.MyTask.edit === 1)) && (
                                    <td>
                                      <a
                                        className={
                                          task.isDeletedProject == true
                                            ? "disabled"
                                            : "task-id"
                                        }
                                        target="_blank"
                                        rel="noreferrer"
                                        href={"/EditActionItem/" + task.key}
                                      >
                                        {task.key}
                                      </a>
                                    </td>
                                  )}
                                <td title={task.title}>
                                  {" "}
                                  {task.title.length > 85
                                    ? `${task.title.substring(0, 85)}...`
                                    : task.title}
                                </td>
                                <td className="td-th-alignment">
                                  {task.estimates
                                    .toFixed(2)
                                    .replace(/\.?0+$/, "")}
                                </td>

                                {(userRoleId === ROLE_TYPE.ADMIN ||
                                  userRoleId === ROLE_TYPE.LEAD) && (
                                    <td>{task.assigneeName}</td>
                                  )}
                                <td>{task.reporterName}</td>
                                <td >

                                  {/* {task.assignedOn} */}
                                  <DatePicker
                                    placeholderText="Select Date"
                                    className="form-control custom-bg-border"
                                    dateFormat="dd-MM-yyyy"
                                    //selected={task.assignedOn}
                                    disabled={task.assignedOn === null || task.assignedOn === undefined}
                                    selected={
                                      task.assignedOn
                                        ? new Date(task.assignedOn)
                                        : null
                                    }
                                    //onChange={(date) => { setFilteredDate(date); filterGridView() }}
                                    onChange={(date) => handleDueDate(task, date)}
                                    showIcon={true}
                                    onKeyDown={(e) => {
                                      e.preventDefault();
                                    }}
                                    //  maxDate={new Date()}
                                    popperClassName="ag-custom-component-popup"
                                    scrollableYearDropdown={true}
                                    showYearDropdown
                                    showMonthDropdown
                                    todayButton="Today"

                                  />
                                </td>
                                <td
                                  className="td-th-alignment"
                                  title={task.actionItemStatus}
                                >
                                  {StatusList.some(
                                    (status) =>
                                      status.label === task.actionItemStatus
                                  ) ? (
                                    StatusList.map((status) =>
                                      status.label === task.actionItemStatus ? (
                                        <img
                                          key={status.label}
                                          src={status.imagePath}
                                          alt={status.label}
                                          style={{
                                            width: "20px",
                                            height: "20px",
                                          }} // Set the desired image size
                                        />
                                      ) : null
                                    )
                                  ) : (
                                    <span>{task.actionItemStatus}</span>
                                  )}
                                </td>
                                <td style={{ position: "relative" }}>
                                  {roleId === ROLE_TYPE.ADMIN && adminPermission.MyTask.deleteOther === 1 ||
                                    (
                                      ((roleId === ROLE_TYPE.EMPLOYEE &&
                                        projectLeadPermission.MyTask.deleteOther === 1) ||
                                        (roleId === ROLE_TYPE.LEAD &&
                                          projectLeadPermission.MyTask.delete === 1) ||

                                        (loggedInUser === task.reporterId && (roleId === ROLE_TYPE.EMPLOYEE &&
                                          employeePermission.MyTask.delete === 1)))) ? (<div
                                            key={task.id}
                                            style={{
                                              marginBottom: 10,
                                              cursor: "pointer",
                                              fontWeight: 800,
                                            }}
                                            onClick={(event) =>
                                              handleContextMenuToday(
                                                event,
                                                task.actionItemId
                                              )
                                            }
                                          >
                                            <span
                                              style={{
                                                fontSize: "22px",
                                                color: "#767676",
                                              }}
                                            >
                                              ...
                                            </span>
                                          </div>) : ""}{" "}
                                  {contextMenuVisibleToday &&
                                    selectedRowId === task.actionItemId && (
                                      <ContextMenuComponent
                                        contextMenuPositionX={
                                          contextMenuPosition.x
                                        }
                                        contextMenuPositionY={
                                          contextMenuPosition.y
                                        }
                                        handleDelete={handleDeleteToday}
                                      />
                                    )}
                                </td>
                              </tr>
                            ))}
                          </tbody>

                          {(filteredData.length > 0 ? filterTaskData.past : taskData.past).length > 0 && (
                            <tfoot>
                              <tr

                              >
                                <td
                                  colSpan={roleId === ROLE_TYPE.ADMIN || roleId === ROLE_TYPE.LEAD ? "3" : "3"}
                                  style={{ textAlign: "right", fontWeight: "bold", padding: "10px 0px" }}
                                >
                                  Total:
                                </td>

                                <td className="td-th-alignment" style={{ fontWeight: "bold" }}>
                                  {formatCorrectHours(
                                    (filteredData.length > 0 ? filterTaskData.past : taskData.past)
                                      .reduce((total, task) => total + parseFloat(task.estimates || 0), 0)
                                      .toFixed(2)
                                      .replace(/\.?0+$/, "")
                                  )}
                                </td>
                                <td colSpan={roleId === ROLE_TYPE.ADMIN || roleId === ROLE_TYPE.LEAD ? "5" : "4"}></td>
                              </tr>
                            </tfoot>
                          )}

                        </table>
                      </div>
                    )}
                </div>
              </div>
            </div>

            <div className="mt-3" onClick={CloseContextMenuToday}>
              <div
                className=" collapsible-sec-pos"
                onDrop={(e) => {
                  e.preventDefault();

                  const files = e;
                  const item = JSON.parse(e.dataTransfer.getData("item"));
                  handleDroppedActionItem(item, "today");
                }}
                onDragStart={(e) => {
                  setCurrentLocation("today");
                }}
                onDragOver={(e) => {
                  e.preventDefault();
                }}
              >
                <div className="collapsible-sec-header">
                  <div>
                    <span className="collapsible-header-text">Today</span>
                  </div>
                </div>

                <button
                  className={`collapsible-btn ${isActive.today ? "active" : ""
                    }`}
                  onClick={() => handleClick("today")}
                >
                  <i className="fa fa-chevron-right" aria-hidden="true" />
                </button>
                <div
                  className="contentt"
                  style={{
                    overflowX: "auto",
                    display: isActive.today ? "block" : "none",
                  }}
                >
                  {((roleId === ROLE_TYPE.ADMIN &&
                    adminPermission.MyTask.viewOther === 1) ||
                    (roleId === ROLE_TYPE.EMPLOYEE &&
                      employeePermission.MyTask.view === 1) ||
                    (roleId === ROLE_TYPE.LEAD &&
                      projectLeadPermission.MyTask.view === 1) ||
                    (roleId === ROLE_TYPE.EMPLOYEE &&
                      projectLeadPermission.MyTask.viewOther === 1)) && (
                      <div className="custom-grid-sec">
                        <table
                          className="table-responsive table-main"
                          style={{ overflow: "auto" }}
                        >
                          <thead>
                            <tr>
                              <th className="td-th-alignment custome-th-width" ></th>
                              {((roleId === ROLE_TYPE.ADMIN &&
                                adminPermission.MyTask.editOther === 1) ||
                                (roleId === ROLE_TYPE.EMPLOYEE &&
                                  projectLeadPermission.MyTask.editOther === 1) ||
                                (roleId === ROLE_TYPE.EMPLOYEE &&
                                  employeePermission.MyTask.edit === 1) ||
                                (roleId === ROLE_TYPE.LEAD &&
                                  projectLeadPermission.MyTask.edit === 1)) && (
                                  <th className="custome-TaskId-width">Task ID</th>
                                )}
                             <th className="custome-TaskName-width">Task Name</th>
                              <th className="custome-Estimated-width">Estimated Hours</th>

                              {(userRoleId === ROLE_TYPE.ADMIN ||
                                userRoleId === ROLE_TYPE.LEAD) && (
                                  <th className="custome-AssignedTo-width">Assigned To</th>
                                )}
                              <th className="custome-AssignedBy-width">Assigned By</th>
                              <th className="custome-DueDate-width">Due Date</th>
                              <th className="td-th-alignment custome-Status-width">Status</th>
                              <th className="custome-Delete-width"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {(filteredData.length > 0
                              ? filterTaskData.today
                              : taskData.today
                            ).map((task) => (
                              <tr
                                key={task.id}
                                draggable
                                onDragStart={(e) => handleDragStart(e, task)}
                              >
                                <td className="td-th-alignment" style={{ padding: "10px 5px" }}>
                                  <img
                                    width="20px"
                                    src={TASK_PRIO[task.priorityId]}
                                    alt={PriorityListTitle[task.priorityId]}
                                    title={PriorityListTitle[task.priorityId]}
                                  />
                                </td>
                                {((roleId === ROLE_TYPE.ADMIN &&
                                  adminPermission.MyTask.editOther === 1) ||
                                  (roleId === ROLE_TYPE.EMPLOYEE &&
                                    employeePermission.MyTask.edit === 1) ||
                                  (roleId === ROLE_TYPE.LEAD &&
                                    projectLeadPermission.MyTask.edit === 1) ||
                                  (roleId === ROLE_TYPE.EMPLOYEE &&
                                    projectLeadPermission.MyTask.editOther === 1)) && (
                                    <td>
                                      <a
                                        className={
                                          task.isDeletedProject == true
                                            ? "disabled"
                                            : "task-id"
                                        }
                                        target="_blank"
                                        rel="noreferrer"
                                        href={"/EditActionItem/" + task.key}
                                      >
                                        {task.key}
                                      </a>
                                    </td>
                                  )}
                                <td title={task.title}>
                                  {" "}
                                  {task.title.length > 85
                                    ? `${task.title.substring(0, 85)}...`
                                    : task.title}
                                </td>
                                <td className="td-th-alignment">
                                  {task.estimates
                                    .toFixed(2)
                                    .replace(/\.?0+$/, "")}
                                </td>

                                {(userRoleId === ROLE_TYPE.ADMIN ||
                                  userRoleId === ROLE_TYPE.LEAD) && (
                                    <td>{task.assigneeName}</td>
                                  )}
                                <td>{task.reporterName}</td>

                                <td >

                                  {/* {task.assignedOn} */}
                                  <DatePicker
                                    placeholderText="Select Date"
                                    className="form-control custom-bg-border"
                                    dateFormat="dd-MM-yyyy"
                                    //selected={task.assignedOn}
                                    selected={
                                      task.assignedOn
                                        ? new Date(task.assignedOn)
                                        : null
                                    }
                                    //onChange={(date) => { setFilteredDate(date); filterGridView() }}
                                    onChange={(date) => handleDueDate(task, date)}
                                    showIcon={true}
                                    onKeyDown={(e) => {
                                      e.preventDefault();
                                    }}
                                    //  maxDate={new Date()}
                                    popperClassName="ag-custom-component-popup"
                                    scrollableYearDropdown={true}
                                    showYearDropdown
                                    showMonthDropdown
                                    todayButton="Today"

                                  />
                                </td>
                                <td
                                  className="td-th-alignment"
                                  title={task.actionItemStatus}
                                >
                                  {StatusList.some(
                                    (status) =>
                                      status.label === task.actionItemStatus
                                  ) ? (
                                    StatusList.map((status) =>
                                      status.label === task.actionItemStatus ? (
                                        <img
                                          key={status.label}
                                          src={status.imagePath}
                                          alt={status.label}
                                          style={{
                                            width: "20px",
                                            height: "20px",
                                          }} // Set the desired image size
                                        />
                                      ) : null
                                    )
                                  ) : (
                                    <span>{task.actionItemStatus}</span>
                                  )}
                                </td>
                                <td style={{ position: "relative" }}>
                                  {roleId === ROLE_TYPE.ADMIN && adminPermission.MyTask.deleteOther === 1 ||
                                    (
                                      ((roleId === ROLE_TYPE.EMPLOYEE &&
                                        projectLeadPermission.MyTask.deleteOther === 1) ||
                                        (roleId === ROLE_TYPE.LEAD &&
                                          projectLeadPermission.MyTask.delete === 1) ||

                                        (loggedInUser === task.reporterId && (roleId === ROLE_TYPE.EMPLOYEE &&
                                          employeePermission.MyTask.delete === 1)))) ? (<div
                                            key={task.id}
                                            style={{
                                              marginBottom: 10,
                                              cursor: "pointer",
                                              fontWeight: 800,
                                            }}
                                            onClick={(event) =>
                                              handleContextMenuToday(
                                                event,
                                                task.actionItemId
                                              )
                                            }
                                          >
                                            <span
                                              style={{
                                                fontSize: "22px",
                                                color: "#767676",
                                              }}
                                            >
                                              ...
                                            </span>
                                          </div>) : ""}{" "}
                                  {contextMenuVisibleToday &&
                                    selectedRowId === task.actionItemId && (
                                      <ContextMenuComponent
                                        contextMenuPositionX={
                                          contextMenuPosition.x
                                        }
                                        contextMenuPositionY={
                                          contextMenuPosition.y
                                        }
                                        handleDelete={handleDeleteToday}
                                      />
                                    )}
                                </td>
                              </tr>
                            ))}
                          </tbody>

                          {/* <tfoot  style={{
      border: '2px solid', // Make border thicker and bolder
      borderLeft: '2px solid !important',
      borderRight: '2px solid !important',
      borderBottom: '2px solid !important',
      borderTop: '2px solid !important',
    }}>
          <tr >
            <td colSpan={roleId === ROLE_TYPE.ADMIN || roleId === ROLE_TYPE.LEAD ? "3" : "2"} style={{ textAlign: "right", fontWeight: "bold" }}>
              Total:
            </td>
            <td className="td-th-alignment" style={{ fontWeight: "bold" }}>
              {(
                filteredData.length > 0
                  ? filterTaskData.today
                  : taskData.today
              ).reduce(
                (total, task) => total + parseFloat(task.estimates || 0),
                0
              ).toFixed(2).replace(/\.?0+$/, "")}
            </td>
            <td colSpan={roleId === ROLE_TYPE.ADMIN || roleId === ROLE_TYPE.LEAD ? "4" : "3"}></td>
          </tr>
        </tfoot> */}
                          {(filteredData.length > 0 ? filterTaskData.today : taskData.today).length > 0 && (
                            <tfoot>
                              <tr

                              >
                                <td
                                  colSpan={roleId === ROLE_TYPE.ADMIN || roleId === ROLE_TYPE.LEAD ? "3" : "3"}
                                  style={{ textAlign: "right", fontWeight: "bold", padding: "10px 0px" }}
                                >
                                  Total:
                                </td>

                                <td className="td-th-alignment" style={{ fontWeight: "bold" }}>
                                  {formatCorrectHours(
                                    (filteredData.length > 0 ? filterTaskData.today : taskData.today)
                                      .reduce((total, task) => total + parseFloat(task.estimates || 0), 0)
                                      .toFixed(2)
                                      .replace(/\.?0+$/, "")
                                  )}
                                </td>
                                <td colSpan={roleId === ROLE_TYPE.ADMIN || roleId === ROLE_TYPE.LEAD ? "5" : "4"}></td>
                              </tr>
                            </tfoot>
                          )}

                        </table>
                      </div>
                    )}
                </div>
              </div>
            </div>
            <div className="mt-3" onClick={CloseContextMenuTomorrow}>
              <div
                className="collapsible-sec-pos"
                onDrop={(e) => {
                  e.preventDefault();

                  const files = e;
                  const item = JSON.parse(e.dataTransfer.getData("item"));

                  handleDroppedActionItem(item, "tomorrow");
                }}
                onDragStart={(e) => {
                  setCurrentLocation("tomorrow");
                }}
                onDragOver={(e) => {
                  e.preventDefault();
                }}
              >
                <div className="collapsible-sec-header">
                  <div>
                    <span className="collapsible-header-text">This Week</span>
                  </div>
                </div>
                <button
                  className={`collapsible-btn ${isActive.tomorrow ? "active" : ""
                    }`}
                  onClick={() => handleClick("tomorrow")}
                >
                  <i className="fa fa-chevron-right" aria-hidden="true" />
                </button>
                <div
                  className="contentt"
                  style={{
                    overflowX: "auto",
                    display: isActive.tomorrow ? "block" : "none",
                  }}
                >
                  {((roleId === ROLE_TYPE.ADMIN &&
                    adminPermission.MyTask.viewOther === 1) ||
                    (roleId === ROLE_TYPE.EMPLOYEE &&
                      employeePermission.MyTask.view === 1) ||
                    (roleId === ROLE_TYPE.LEAD &&
                      projectLeadPermission.MyTask.view === 1) ||
                      (roleId === ROLE_TYPE.EMPLOYEE && 
                        projectLeadPermission.MyTask.viewOther === 1)) && (
                      <div className="custom-grid-sec">
                        {/* <AGGrid
                      id="grid2"
    rowData={tomorrowsData}
    gridOptions={gridOptions2}
    onGridReady={onGridReady2}
    pagination="10"
    isMyTasks={true}

    onRowDragStart={handleRowDragStart}
    onRowDragEnter={handleRowDragEnter}
    onRowDragEnd={handleRowDragEnd}
    onSortChanged={(e) => onSortChanged(e)}
  /> */}
                        <table className="table-responsive table-main">
                          <thead>
                            <tr>
                              <th className="td-th-alignment custome-th-width"></th>
                              {((roleId === ROLE_TYPE.ADMIN &&
                                adminPermission.MyTask.editOther === 1) ||
                                (roleId === ROLE_TYPE.EMPLOYEE &&
                                  projectLeadPermission.MyTask.editOther === 1) ||
                                (roleId === ROLE_TYPE.EMPLOYEE &&
                                  employeePermission.MyTask.edit === 1) ||
                                (roleId === ROLE_TYPE.LEAD &&
                                  projectLeadPermission.MyTask.edit === 1)) && (
                                  <th className="custome-TaskId-width">Task ID</th>
                                )}
                             <th className="custome-TaskName-width">Task Name</th>
                              <th className="custome-Estimated-width">Estimated Hours</th>

                              {(userRoleId === ROLE_TYPE.ADMIN ||
                                userRoleId === ROLE_TYPE.LEAD) && (
                                  <th className="custome-AssignedTo-width">Assigned To</th>
                                )}
                              <th className="custome-AssignedBy-width">Assigned By</th>
                              <th className="custome-DueDate-width">Due Date</th>
                              <th className="td-th-alignment custome-Status-width">Status</th>
                              <th className="custome-Delete-width"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {(filteredData.length > 0
                              ? filterTaskData.tomorrow
                              : taskData.tomorrow
                            ).map((task) => (
                              <tr
                                key={task.id}
                                draggable
                                onDragStart={(e) => handleDragStart(e, task)}
                              >
                                <td className="td-th-alignment" style={{ padding: "10px 5px" }}>
                                  <img
                                    width="20px"
                                    src={TASK_PRIO[task.priorityId]}
                                    alt={PriorityListTitle[task.priorityId]}
                                    title={PriorityListTitle[task.priorityId]}
                                  />
                                </td>
                                {((roleId === ROLE_TYPE.ADMIN &&
                                  adminPermission.MyTask.editOther === 1) ||
                                  (roleId === ROLE_TYPE.EMPLOYEE &&
                                    employeePermission.MyTask.edit === 1) ||
                                  (roleId === ROLE_TYPE.LEAD &&
                                    projectLeadPermission.MyTask.edit === 1) ||
                                    (roleId === ROLE_TYPE.EMPLOYEE && 
                                      projectLeadPermission.MyTask.editOther === 1)) && (
                                    <td>
                                      <a
                                        className={
                                          task.isDeletedProject == true
                                            ? "disabled"
                                            : "task-id"
                                        }
                                        target="_blank"
                                        rel="noreferrer"
                                        href={"/EditActionItem/" + task.key}
                                      >
                                        {task.key}
                                      </a>
                                    </td>
                                  )}
                                <td title={task.title}>
                                  {" "}
                                  {task.title.length > 85
                                    ? `${task.title.substring(0, 85)}...`
                                    : task.title}
                                </td>
                                <td className="td-th-alignment">
                                  {task.estimates
                                    .toFixed(2)
                                    .replace(/\.?0+$/, "")}
                                </td>

                                {(userRoleId === ROLE_TYPE.ADMIN ||
                                  userRoleId === ROLE_TYPE.LEAD) && (
                                    <td>{task.assigneeName}</td>
                                  )}
                                <td>{task.reporterName}</td>
                                {/* <td className="td-th-alignment">
                                <span className={task.actionItemStatus}>{task.actionItemStatus}</span>
                              </td> */}
                                {/* <td className="td-th-alignment" title={task.actionItemStatus}>
  {StatusList.map((status) =>
    status.label === task.actionItemStatus ? (
      <img
        key={status.label}
        src={status.imagePath}
        alt={status.label}
        style={{ width: "20px", height: "20px" }} // Set the desired image size
      />
    ) : null
  )}
</td> */}
                                <td >
                                  {" "}
                                  {/* {task.assignedOn} */}
                                  <DatePicker
                                    placeholderText="Select Date"
                                    className="form-control custom-bg-border"

                                    dateFormat="dd-MM-yyyy"
                                    //selected={task.assignedOn}
                                    selected={
                                      task.assignedOn
                                        ? new Date(task.assignedOn)
                                        : null
                                    }
                                    //onChange={(date) => { setFilteredDate(date); filterGridView() }}
                                    onChange={(date) => handleDueDate(task, date)}
                                    showIcon={true}
                                    onKeyDown={(e) => {
                                      e.preventDefault();
                                    }}
                                    //  maxDate={new Date()}
                                    popperClassName="ag-custom-component-popup"
                                    scrollableYearDropdown={true}
                                    showYearDropdown
                                    showMonthDropdown
                                    todayButton="Today"

                                  />
                                </td>
                                <td
                                  className="td-th-alignment"
                                  title={task.actionItemStatus}
                                >
                                  {StatusList.some(
                                    (status) =>
                                      status.label === task.actionItemStatus
                                  ) ? (
                                    StatusList.map((status) =>
                                      status.label === task.actionItemStatus ? (
                                        <img
                                          key={status.label}
                                          src={status.imagePath}
                                          alt={status.label}
                                          style={{
                                            width: "20px",
                                            height: "20px",
                                          }} // Set the desired image size
                                        />
                                      ) : null
                                    )
                                  ) : (
                                    <span>{task.actionItemStatus}</span>
                                  )}
                                </td>

                                <td style={{ position: "relative" }}>
                                  {roleId === ROLE_TYPE.ADMIN && adminPermission.MyTask.deleteOther === 1 ||
                                    (
                                      ((roleId === ROLE_TYPE.EMPLOYEE &&
                                        projectLeadPermission.MyTask.deleteOther === 1) ||
                                        (roleId === ROLE_TYPE.LEAD &&
                                          projectLeadPermission.MyTask.delete === 1) ||

                                        (loggedInUser === task.reporterId && (roleId === ROLE_TYPE.EMPLOYEE &&
                                          employeePermission.MyTask.delete === 1)))) ? (<div
                                            key={task.id}
                                            style={{
                                              marginBottom: 10,
                                              cursor: "pointer",
                                              fontWeight: 800,
                                            }}
                                            onClick={(event) =>
                                              handleContextMenuTomorrow(
                                                event,
                                                task.actionItemId
                                              )
                                            }
                                          >
                                            <span
                                              style={{
                                                fontSize: "22px",
                                                color: "#767676",
                                              }}
                                            >
                                              ...
                                            </span>
                                          </div>) : ""}
                                  {contextMenuVisibleTomorrow &&
                                    selectedRowId === task.actionItemId && (
                                      <ContextMenuComponent
                                        contextMenuPositionX={
                                          contextMenuPosition.x
                                        }
                                        contextMenuPositionY={
                                          contextMenuPosition.y
                                        }
                                        handleDelete={handleDeleteTomorrow}
                                      />
                                    )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                          {(filteredData.length > 0 ? filterTaskData.tomorrow : taskData.tomorrow).length > 0 && (
                            <tfoot>
                              <tr
                              // style={{
                              //   border: '2px solid', // Make border thicker and bolder
                              //   borderLeft: '2px solid',
                              //   borderRight: '2px solid',
                              //   borderBottom: '2px solid',
                              //   borderTop: '2px solid',
                              // }}
                              >
                                <td
                                  colSpan={roleId === ROLE_TYPE.ADMIN || roleId === ROLE_TYPE.LEAD ? "3" : "3"}
                                  style={{ textAlign: "right", fontWeight: "bold", padding: "10px 0px" }}
                                >
                                  Total:
                                </td>
                                {/* <td className="td-th-alignment" style={{ fontWeight: "bold" }}>
        {(
          filteredData.length > 0
            ? filterTaskData.tomorrow
            : taskData.tomorrow
        )
          .reduce(
            (total, task) => total + parseFloat(task.estimates || 0),
            0
          )
          .toFixed(2)
          .replace(/\.?0+$/, "")}
      </td> */}
                                <td className="td-th-alignment" style={{ fontWeight: "bold" }}>
                                  {formatCorrectHours(
                                    (filteredData.length > 0 ? filterTaskData.tomorrow : taskData.tomorrow)
                                      .reduce((total, task) => total + parseFloat(task.estimates || 0), 0)
                                      .toFixed(2)
                                      .replace(/\.?0+$/, "")
                                  )}
                                </td>
                                <td colSpan={roleId === ROLE_TYPE.ADMIN || roleId === ROLE_TYPE.LEAD ? "5" : "4"}></td>
                              </tr>
                            </tfoot>
                          )}
                        </table>
                      </div>
                    )}
                </div>
              </div>
            </div>
            <div className="mt-3" onClick={CloseContextMenuLater}>
              <div
                className="collapsible-sec-pos"
                onDrop={(e) => {
                  e.preventDefault();

                  const files = e;
                  const item = JSON.parse(e.dataTransfer.getData("item"));

                  handleDroppedActionItem(item, "later");
                }}
                onDragStart={(e) => {
                  setCurrentLocation("later");
                }}
                onDragOver={(e) => {
                  e.preventDefault();
                }}
              >
                <div className="collapsible-sec-header">
                  <div>
                    <span className="collapsible-header-text">Later</span>
                  </div>
                </div>
                <button
                  className={`collapsible-btn ${isActive.later ? "active" : ""
                    }`}
                  onClick={() => handleClick("later")}
                >
                  <i className="fa fa-chevron-right" aria-hidden="true" />
                </button>
                <div
                  className="contentt"
                  style={{
                    overflowX: "auto",
                    display: isActive.later ? "block" : "none",
                  }}
                >
                  {((roleId === ROLE_TYPE.ADMIN &&
                    adminPermission.MyTask.viewOther === 1) ||
                    (roleId === ROLE_TYPE.EMPLOYEE &&
                      employeePermission.MyTask.view === 1) ||
                    (roleId === ROLE_TYPE.LEAD &&
                      projectLeadPermission.MyTask.view === 1) ||
                      (roleId === ROLE_TYPE.EMPLOYEE && 
                        projectLeadPermission.MyTask.viewOther === 1)) && (
                      <div className="custom-grid-sec">
                        {/* <AGGrid
                                         id="grid3"


    rowData={laterData}
    gridOptions={gridOptions3}
    onGridReady={onGridReady3}
    pagination="10"
                        isMyTasks={true}

                        onRowDragStart={handleRowDragStart}
                        onRowDragEnter={handleRowDragEnter}
                        onRowDragEnd={handleRowDragEnd}
    onSortChanged={(e) => onSortChanged(e)}
  /> */}
                        <table className="table-responsive table-main">
                          <thead>
                            <tr>
                              <th className="td-th-alignment custome-th-width"></th>
                              {((roleId === ROLE_TYPE.ADMIN &&
                                adminPermission.MyTask.editOther === 1) ||
                                (roleId === ROLE_TYPE.EMPLOYEE &&
                                  employeePermission.MyTask.edit === 1) ||
                                (roleId === ROLE_TYPE.LEAD &&
                                  projectLeadPermission.MyTask.edit === 1) ||
                                (roleId === ROLE_TYPE.EMPLOYEE &&
                                  projectLeadPermission.MyTask.editOther === 1)) && (
                                  <th className="custome-TaskId-width">Task ID</th>
                                )}
                             <th className="custome-TaskName-width">Task Name</th>
                              <th className="custome-Estimated-width">Estimated Hours</th>

                              {(userRoleId === ROLE_TYPE.ADMIN ||
                                userRoleId === ROLE_TYPE.LEAD) && (
                                  <th className="custome-AssignedTo-width">Assigned To</th>
                                )}
                              <th className="custome-AssignedBy-width">Assigned By</th>
                              <th className="custome-DueDate-width">Due Date</th>
                              <th className="td-th-alignment custome-Status-width">Status</th>
                              <th className="custome-Delete-width"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {(filteredData.length > 0
                              ? filterTaskData.later
                              : taskData.later
                            ).map((task) => (
                              <tr
                                key={task.id}
                                draggable
                                onDragStart={(e) => handleDragStart(e, task)}
                              >
                                <td className="td-th-alignment" style={{ padding: "10px 5px" }}>
                                  <img
                                    width="20px"
                                    src={TASK_PRIO[task.priorityId]}
                                    alt={PriorityListTitle[task.priorityId]}
                                    title={PriorityListTitle[task.priorityId]}
                                  />
                                </td>

                                {((roleId === ROLE_TYPE.ADMIN &&
                                  adminPermission.MyTask.editOther === 1) ||
                                  (roleId === ROLE_TYPE.EMPLOYEE &&
                                    employeePermission.MyTask.edit === 1) ||
                                  (roleId === ROLE_TYPE.LEAD &&
                                    projectLeadPermission.MyTask.edit === 1) ||
                                    (roleId === ROLE_TYPE.EMPLOYEE && 
                                      projectLeadPermission.MyTask.editOther === 1)) && (
                                    <td>
                                      <a
                                        className={
                                          task.isDeletedProject == true
                                            ? "disabled"
                                            : "task-id"
                                        }
                                        target="_blank"
                                        rel="noreferrer"
                                        href={"/EditActionItem/" + task.key}
                                      >
                                        {task.key}
                                      </a>
                                    </td>
                                  )}
                                {/* <td>{task.title}</td> */}
                                <td title={task.title}>
                                  {" "}
                                  {task.title.length > 85
                                    ? `${task.title.substring(0, 85)}...`
                                    : task.title}
                                </td>

                                <td className="td-th-alignment">
                                  {task.estimates
                                    .toFixed(2)
                                    .replace(/\.?0+$/, "")}
                                </td>

                                {(userRoleId === ROLE_TYPE.ADMIN ||
                                  userRoleId === ROLE_TYPE.LEAD) && (
                                    <td>{task.assigneeName}</td>
                                  )}
                                <td>{task.reporterName}</td>
                                {/* <td className="td-th-alignment">
                                <span className={task.actionItemStatus}>{task.actionItemStatus}</span>
                              </td> */}
                                {/* <td className="td-th-alignment" title={task.actionItemStatus}>
  {StatusList.map((status) =>
    status.label === task.actionItemStatus ? (
      <img
        key={status.label}
        src={status.imagePath}
        alt={status.label}
        style={{ width: "20px", height: "20px" }} // Set the desired image size
      />
    ) : null
  )}
</td> */}
                                <td >
                                  {" "}
                                  <DatePicker
                                    placeholderText="Select Date"
                                    className="form-control custom-bg-border"
                                    dateFormat="dd-MM-yyyy"
                                    //selected={task.assignedOn}
                                    selected={
                                      task.assignedOn
                                        ? new Date(task.assignedOn)
                                        : null
                                    }
                                    //onChange={(date) => { setFilteredDate(date); filterGridView() }}
                                    onChange={(date) => handleDueDate(task, date)}
                                    showIcon={true}
                                    onKeyDown={(e) => {
                                      e.preventDefault();
                                    }}
                                    //  maxDate={new Date()}
                                    popperClassName="ag-custom-component-popup"
                                    scrollableYearDropdown={true}
                                    showYearDropdown
                                    showMonthDropdown
                                    todayButton="Today"

                                  />
                                </td>
                                <td
                                  className="td-th-alignment"
                                  title={task.actionItemStatus}
                                >
                                  {StatusList.some(
                                    (status) =>
                                      status.label === task.actionItemStatus
                                  ) ? (
                                    StatusList.map((status) =>
                                      status.label === task.actionItemStatus ? (
                                        <img
                                          key={status.label}
                                          src={status.imagePath}
                                          alt={status.label}
                                          style={{
                                            width: "20px",
                                            height: "20px",
                                          }} // Set the desired image size
                                        />
                                      ) : null
                                    )
                                  ) : (
                                    <span>{task.actionItemStatus}</span>
                                  )}
                                </td>
                                <td style={{ position: "relative" }}>
                                  {roleId === ROLE_TYPE.ADMIN && adminPermission.MyTask.deleteOther === 1 ||
                                    (
                                      ((roleId === ROLE_TYPE.EMPLOYEE &&
                                        projectLeadPermission.MyTask.deleteOther === 1) ||
                                        (roleId === ROLE_TYPE.LEAD &&
                                          projectLeadPermission.MyTask.delete === 1) ||

                                        (loggedInUser === task.reporterId && (roleId === ROLE_TYPE.EMPLOYEE &&
                                          employeePermission.MyTask.delete === 1)))) ? (<div
                                            key={task.id}
                                            style={{
                                              marginBottom: 10,
                                              cursor: "pointer",
                                              fontWeight: 800,
                                            }}
                                            onClick={(event) =>
                                              handleContextMenuLater(
                                                event,
                                                task.actionItemId
                                              )
                                            }
                                          >
                                            <span
                                              style={{
                                                fontSize: "22px",
                                                color: "#767676",
                                              }}
                                            >
                                              ...
                                            </span>
                                          </div>) : ""}
                                  {contextMenuVisibleLater &&
                                    selectedRowId === task.actionItemId && (
                                      <ContextMenuComponent
                                        contextMenuPositionX={
                                          contextMenuPosition.x
                                        }
                                        contextMenuPositionY={
                                          contextMenuPosition.y
                                        }
                                        handleDelete={handleDeleteLater}
                                      />
                                    )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                          {(filteredData.length > 0 ? filterTaskData.later : taskData.later).length > 0 && (
                            <tfoot>
                              <tr
                              // style={{
                              //   border: '2px solid', // Make border thicker and bolder
                              //   borderLeft: '2px solid',
                              //   borderRight: '2px solid',
                              //   borderBottom: '2px solid',
                              //   borderTop: '2px solid',
                              // }}
                              >
                                <td
                                  colSpan={roleId === ROLE_TYPE.ADMIN || roleId === ROLE_TYPE.LEAD ? "3" : "3"}
                                  style={{ textAlign: "right", fontWeight: "bold", padding: "10px 0px" }}
                                >
                                  Total:
                                </td>
                                <td className="td-th-alignment" style={{ fontWeight: "bold" }}>
                                  {formatCorrectHours(
                                    (filteredData.length > 0 ? filterTaskData.later : taskData.later)
                                      .reduce((total, task) => total + parseFloat(task.estimates || 0), 0)
                                      .toFixed(2)
                                      .replace(/\.?0+$/, "")
                                  )}
                                </td>
                                <td colSpan={roleId === ROLE_TYPE.ADMIN || roleId === ROLE_TYPE.LEAD ? "5" : "4"}></td>
                              </tr>
                            </tfoot>
                          )}
                        </table>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section >
    </>
  );
};
export default MyTasks;
